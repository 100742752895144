import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UseMedia } from '../Funciones';
import { InputText } from 'primereact/inputtext';
import BtnCrearObjetivosEstrategicos from './BtnCrearObjetivosEstrategicos';
import BtnActualizarObjetivosEstrategicos from './BtnActualizarObjetivosEstrategicos';
import Services from "../../service/Services";
import ConfirmBtn from './ConfirmBtn';
import VerAsociaciones from './VerAsociaciones';

const DataTableStrategicObjectives = ({ permissionsProp }) => {
    const getServices = new Services()

    const [servicesStrategicObjectives, setServicesStrategicObjectives] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true)
    const [runRender, setRunRender] = useState(0)
    const [projectsSession, setProjectsSession] = useState([]);

    //Traer los clientes asociados al proyecto y al usuario en seccion
    useEffect(() => {
      const fetchAssociatedClients = async () => {
          try {
              const data = await getServices.getAsociacionProyectoUsuarioVigente();
              if (data) {
                  const associatedClientIds = data.map(project => parseInt(project.cliente.id)); 
                  setProjectsSession(associatedClientIds);
              }
          } catch (error) {
              //console.error("Error al obtener los clientes asociados:", error);
          }
      };
  
      fetchAssociatedClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
      // Obtiene los objetivos estratégicos y filtra solo los asociados a los clientes
      const fetchStrategicObjectives = async () => {
          try {
              setLoading(true);
              const data = await getServices.getStrategicObjectives();
              if (data) {
                  const filteredData = data
                      .filter(item => projectsSession.includes(parseInt(item.cliente.id))) 
                      .map(item => ({
                          ...item,
                          estado: item.estado === 0 ? "No Vigente" : "Vigente",
                      }));
                  setServicesStrategicObjectives(filteredData);
              }
              setLoading(false);
          } catch (error) {
             // console.error("Error al obtener los objetivos estratégicos:", error);
              setLoading(false);
          }
      };
  
      fetchStrategicObjectives();
  }, [projectsSession, runRender]);// eslint-disable-line react-hooks/exhaustive-deps
  
    const dt = useRef(null);
    let small = UseMedia("(max-width: 760px)");

    const handleChange = (e) => {
        e.preventDefault();
    };

    let permissionsStrategicObjectivesCreate = false
    if (permissionsProp[32].permisos[0] === "Crear") {
        permissionsStrategicObjectivesCreate = true
    }

    let permissionsStrategicObjectivesUpdate = false

    if (permissionsProp[32].permisos[1] === "Editar") {
        permissionsStrategicObjectivesUpdate = true
    }

    let permissionsStrategicObjectivesDelete = false

    if (permissionsProp[32].permisos[2] === "Eliminar") {
        permissionsStrategicObjectivesDelete = true
    }

    const header = (
        <div className="headerTable">
            <>
                {
                    permissionsStrategicObjectivesCreate ?
                        <BtnCrearObjetivosEstrategicos
                            runRenderProp={runRender}
                            setRunRenderProp={setRunRender}
                            dataProp={servicesStrategicObjectives}
                        />
                        :
                        ''
                }

            </>
            <>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </>


        </div>
    )

    const Acciones = (rowData) => {

        return (

            <div className='Botones'>
                <div>

                    {permissionsStrategicObjectivesUpdate ?
                        <BtnActualizarObjetivosEstrategicos
                            dataProp={rowData}
                            runRenderProp={runRender}
                            setRunRenderProp={setRunRender}
                            newDataProp={servicesStrategicObjectives}

                        />
                        :
                        ''
                    }
                </div>
                <div>

                    {permissionsStrategicObjectivesDelete ?
                        <ConfirmBtn
                            tittle="Confirmar Eliminación"
                            message="¿Está seguro de eliminar el objetivo estratégico?, Recuerde que una vez se elimine se borrara definitivamente del sistema"
                            action="Delete"
                            label=""
                            icon="pi pi-trash"
                            classProp="p-button-danger"
                            tooltipProp="Eliminar"
                            runRenderProp={runRender}
                            setRunRenderProp={setRunRender}
                            idStrategicObjectiveProp={rowData.id}
                        />
                        :
                        ''
                    }
                </div>
                <div>
                    {permissionsStrategicObjectivesUpdate ?
                        <VerAsociaciones
                            idClienteProp={rowData.cliente.id}
                            dataProp={rowData}
                            runRenderProp={runRender}
                            setRunRenderProp={setRunRender}
                        />
                        :
                        ''
                    }
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="datatable-responsive-demo">
                <div className="card">
                    <DataTable ref={dt} value={servicesStrategicObjectives} dataKey="id" header={header} globalFilter={globalFilter} loading={loading}
                        emptyMessage="Objetivo estrategico no registrado en el sistema" paginator rows={10} responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px">
                        <Column className="columna" field="cliente.razonsocial" sortable header="Cliente" />
                        <Column className="columna" field="descripcion" sortable header="Objetivo Estrategico" />
                        <Column className="columna" field="fechainiciovigencia" sortable header="Fecha Inicio Vigencia" />
                        <Column className="columna" field="fechafinvigencia" sortable header="Fecha Fin Vigencia" />
                        <Column className="columna" field="estado" sortable header="Estado" />
                        <Column header="Acciones" body={Acciones} />
                    </DataTable>

                </div>
            </div>
        </>
    )
}

export default DataTableStrategicObjectives