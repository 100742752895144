import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import BtnAñadirAsociacion from './BtnAñadirAsociacion';
import BtnActualizarAsociacion from './BtnActualizarAsociacion';
import Services from '../../service/Services'

const VerAsociaciones = ({ idClienteProp, dataProp, runRenderProp, setRunRenderProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [servicesObjectivesByClient, setServicesObjectivesByClient] = useState([])
    const [services, setServices] = useState([])

    const getServices = new Services()

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    useEffect(() => {
        if (displayBasic === true) {
            getServices.getObjectivesByClient(idClienteProp).then(data => {
                const associatedObjectives = dataProp.objetivosasociados
                    ? JSON.parse(dataProp.objetivosasociados)
                    : [];
                
                const uniqueAdditionalObjectives = associatedObjectives.filter(
                    associatedObj => !data.some(dataObj => dataObj.id === associatedObj.id)
                );
                
                const combinedObjectives = [...data, ...uniqueAdditionalObjectives];
             
                setServicesObjectivesByClient(combinedObjectives);
                setServices(associatedObjectives)
            });
        }
    }, [displayBasic, idClienteProp, dataProp])// eslint-disable-line react-hooks/exhaustive-deps


    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }



    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            <div>
                <BtnAñadirAsociacion
                    servicesObjectivesByClientProp={servicesObjectivesByClient}
                    runRenderProp={runRenderProp}
                    setRunRenderProp={setRunRenderProp}
                    dataProp={dataProp}
                    newRowDataProp={services}
                />
            </div>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );


    const Acciones = () => {
        
        return (
            <div>
                <BtnActualizarAsociacion
                    servicesObjectivesByClientProp={servicesObjectivesByClient}
                    runRenderProp={runRenderProp}
                    setRunRenderProp={setRunRenderProp}
                    dataProp={dataProp}
                    newRowDataProp={services}
                />
            </div>
        )
    }

    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Objetivos asociados a los objetivos estratégicos" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={services} key="id" className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay objetivos asociados para este objetivo estratégico" header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="descripcion" header="Objetivo" />
                            <Column header="Acciones" body={Acciones} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default VerAsociaciones