import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
/* import { InputNumber } from "primereact/inputnumber"; */
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Message } from 'primereact/message';
import { Row } from 'primereact/row';
import { Slider } from 'primereact/slider';
import Services from '../../service/Services';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Toast } from 'primereact/toast';
import ConfirmBtn from './ConfirmBtn';
import axios from 'axios';
/* import moment from 'moment'; */
import { CambiarFormatoFecha, /* ValidarNovedades, */ ValidarMesCerrado, Validarfechas, ValidarNovedadesCruzadas, SumarDias, GetDates, obtenerHoraMaximaPorFecha } from '../Funciones';

import Cookies from 'universal-cookie'

const cookies = new Cookies()

const BtnActualizarNovedad = ({ idProp, fechaInicioProp, fechaFinProp, idUsuarioProp, idColaboradorProp, usuarioProp, idTipoNovedadProp, tipoNovedadProp, descripcionProp,
    fecCreacionProp, usuarioCreacionProp, isTotalProp, disabledProp, numberDocProp,
    areaNegocioProp, aplicacionProp, marcaParcialProp, isTraslapoProp, isVacacionesProp,


    hourNumberProp, historicoUserSelectedProp, extenderVacacionesProp }) => {

    var idProveedor = cookies.get('idProveedor');
    const [servicioTipoNovedad, setServicioTipoNovedad] = useState([]);
    const [cierres, setCierres] = useState([]);
    const [detalleNovedad, setDetalleNovedad] = useState([])
    const [consultarNovedades, setConsultarNovedades] = useState([]);
    const [servicioAreaNegocio, setServicioAreaNegocio] = useState([]);
    const [servicioAplicacion, setServicioAplicacion] = useState([]);
    const [servicesUsers, setServicesUsers] = useState([]);
    const getServices = new Services();
    const toast = useRef(null);

    const [displayBasic, setDisplayBasic] = useState(false);

    //Habilitar campo de número de horas
    const [fieldShow, setFieldShow] = useState(false)

    //Campo que obtiene las horas máximas
    const [fieldHourMax, setFieldHourMax] = useState(0)

    const historicoHoras = sessionStorage.getItem('historicoHoras')
    const newHistoricoHoras = JSON.parse(historicoHoras)
    useEffect(() => {

        const source = axios.CancelToken.source()
        if (displayBasic === true) {
            getServices.getTipoNovedad().then(data => {
                if (data !== undefined) {
                    setServicioTipoNovedad(data)
                }
            });
            getServices.getCierres().then(data => {
                if (data !== undefined) {
                    setCierres(data)
                }
            });
            getServices.getAreasNegocio().then(data => {
                if (data !== undefined) {
                    setServicioAreaNegocio(data)
                }
            });
            getServices.getAplicaciones().then(data => {
                if (data !== undefined) {
                    setServicioAplicacion(data)
                }
            });
            getServices.getUsuariosWithIdNameAddActivity().then(data => {
                if (data !== undefined) {
                    setServicesUsers(data)
                }
            });
            setIsTotal(isTotalProp)
        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }

    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (displayBasic === true) {
            if (extenderVacacionesProp === null) {
                setExtenderVacaciones(true)
            }
        }

    }, [displayBasic, extenderVacacionesProp])


    const dt = useRef(null);

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [validarCambio, setValidarCambio] = useState(false)

    // Obtener datos del select usuarios

    const [selectedUsuario, setSelectUsuario] = useState({
        usuario: usuarioProp,
        idUsuario: idUsuarioProp,
        idColaborador: idColaboradorProp,
        numberDoc: numberDocProp
    })

    const usuarios = servicesUsers.map(item => {
        const av = { usuario: item.usuario, idUsuario: item.idUsuario, idColaborador: item.idColaborador, numberDoc: item.nrodocumento }

        return av
    })

    const onUsuarioChange = (e) => {
        setSelectUsuario(e.value)
        setValidarCambio(true)
        ValidarFechasCruzadas(dateStart, dateEnd, tipoNovedad, fieldHourNumber, false, e.value.usuario, historicoUserSelectedProp)
    }

    // obtener cliente a partir del usuario seleccionado 

    const [projectsUser, setProjectsUser] = useState([]);

    useEffect(() => {
        if (displayBasic === true) {

            async function getProyectosUser() {

                getServices.getNovedad(idColaboradorProp).then(data => setConsultarNovedades(data));

                const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/projectsUser/' + selectedUsuario.idUsuario
                const response = await axios.post(url, null, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                    .then(res => {
                        var newProjectsUser = []
                        res.data.map(item => {
                            return newProjectsUser.push({
                                cliente: item.cliente,
                                id: item.id,
                                nombre: item.nombre,
                                porcentaje: 0
                            })
                        })
                        setProjectsUser(newProjectsUser)
                    })

                    .catch(function (error) {
                        //console.log(error.response)
                    })

                return response

            }
            if (selectedUsuario.idUsuario !== '') {
                getProyectosUser()
            }
        }
    }, [selectedUsuario, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps


    // Obtener datos del select clientes

    /// Proceso para llenar los clientes y los proyectos asociados al usuario seleccionado

    var clientsUserSession = []

    //// llenar clientes asociados al usuario seleccionado
    projectsUser.forEach(element => {
        clientsUserSession.push({
            id: element.cliente.id,
            razonsocial: element.cliente.nombre
        })
    });
    const tableClients = {}
    const uniqueClients = clientsUserSession.filter(function (object) {
        return tableClients.hasOwnProperty(object.id) ? false : (tableClients[object.id] = true)
    })

    const [selectedClienteComplete, setSelectedClienteComplete] = useState(null);
    const [filteredClientes, setFilteredClientes] = useState(null);

    const searchClientes = (event) => {
        setTimeout(() => {
            let _filteredClientes;
            if (!event.query.trim().length) {
                _filteredClientes = [...uniqueClients];
            }
            else {
                _filteredClientes = uniqueClients.filter((element) => {
                    return element.razonsocial.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredClientes(_filteredClientes);
        }, 250);
    }

    const onClienteChange = (e) => {
        setSelectedClienteComplete(e.value);
        setValidarCambio(true)
    }


    //Obtener datos del select tipo de proyecto

    // Selcionar Proyecto según cliente

    var proyectsUserSession = []

    if (selectedClienteComplete !== null) {
        const result = projectsUser.filter(function (object) {
            var idClient = object.cliente.id.toString()
            return idClient.includes(selectedClienteComplete.id)
        })

        result.forEach(element => {
            proyectsUserSession.push({
                id: element.id,
                nombreproyecto: element.nombre
            })
        });
    }

    const [selectedProyectoComplete, setSelectedProyectoComplete] = useState(null);
    const [filteredProyectos, setFilteredProyectos] = useState(null);

    const searchProyectos = (event) => {
        setTimeout(() => {
            let _filteredProyectos;
            if (!event.query.trim().length) {
                _filteredProyectos = [...proyectsUserSession];
            }
            else {
                _filteredProyectos = proyectsUserSession.filter((element) => {
                    return element.nombreproyecto.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredProyectos(_filteredProyectos);
        }, 250);
    }

    const onProyectoChange = (e) => {
        setSelectedProyectoComplete(e.value);
        setValidarCambio(true)
    }


    // enviar al estado el cliente y el proyecto llenar selects y tabla

    useEffect(() => {
        if (displayBasic === true) {

            var setDetalle
            getServices.getDetalleNovedad(idProp).then(data => {
                if (data.length === 1) {
                    data.forEach(element => {
                        setDetalle = {
                            idCliente: element.proyecto.cliente.id,
                            cliente: element.proyecto.cliente.razonsocial,
                            idProyecto: element.proyecto.id,
                            proyecto: element.proyecto.nombreproyecto
                        }
                    });
                    setSelectedClienteComplete({
                        id: setDetalle.idCliente,
                        razonsocial: setDetalle.cliente
                    })
                    setSelectedProyectoComplete({
                        id: setDetalle.idProyecto,
                        nombreproyecto: setDetalle.proyecto
                    })
                    let newData = data.map(item => {
                        return {
                            idDetalleNovedad: item.id,
                            cliente: item.proyecto.cliente,
                            nombre: item.proyecto.nombreproyecto,
                            idProyecto: item.proyecto.id,
                            porcentaje: item.porcentaje
                        }
                    })
                    setDetalleNovedad(newData)
                } else if (data.length > 1) {
                    let newData = data.map(item => {
                        return {
                            idDetalleNovedad: item.id,
                            cliente: item.proyecto.cliente,
                            nombre: item.proyecto.nombreproyecto,
                            idProyecto: item.proyecto.id,
                            porcentaje: item.porcentaje
                        }
                    })
                    setDetalleNovedad(newData)
                }

            })
        }
    }, [idProp, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps


    //Obtener datos del select tipo de novedades

    const [selectedTipoNovedadComplete, setSelectedTipoNovedadComplete] = useState({
        descripcion: tipoNovedadProp,
        id: idTipoNovedadProp,
        marcaParcial: marcaParcialProp,
        isTraslapo: isTraslapoProp,
        isVacaciones: isVacacionesProp
    });

    const opcionesNovedad = servicioTipoNovedad.map(item => {
        let av = { descripcion: item.descripcion, id: item.id, marcaParcial: item.marcaParcial, isTraslapo: item.isTraslapo, isVacaciones: item.isVacaciones }
        return av
    })

    const onTipoNovedadCompleteChange = (e) => {
        let eOld = { ...selectedTipoNovedadComplete }
        setValidarCambio(true)
        setFieldHourNumber('')
        if (e.value !== null) {
            e.value.marcaParcial ? setFieldShow(true) : setFieldShow(false)
            if (e.value.descripcion !== eOld.descripcion) {
                setSelectedTipoNovedadComplete(e.value);
                ValidarFechasCruzadas(dateStart, dateEnd, e.value, '', e.value.marcaParcial ? true : false, selectedUsuario.usuario, historicoUserSelectedProp)
            } else {
                setSelectedTipoNovedadComplete(e.value);
            }
        }
    }

    //Obtener datos de area de negocio
    const [selectedAreaComplete, setSelectedAreaComplete] = useState(null);

    const optionsArea = servicioAreaNegocio.map(item => {
        const av = { nombreareanegocio: item.nombreareanegocio, id: item.id }
        return av
    })

    const onAreaCompleteChange = (e) => {
        setSelectedAreaComplete(e.value)
        setValidarCambio(true)
    }

    //Obtener datos de area aplicación
    const [selectedSistemaComplete, setSelectedSistemaComplete] = useState(null);

    const optionsApp = servicioAplicacion.map(item => {
        const av = { nombreaplicacion: item.nombreaplicacion, id: item.id }
        return av
    })

    const onAppCompleteChange = (e) => {
        setSelectedSistemaComplete(e.value)
        setValidarCambio(true)
    }

    //Obtener datos del input actividad

    const [inputActividad, setInputActividad] = useState({
        actividad: descripcionProp
    })

    const onActividadChange = (event) => {

        setInputActividad({
            ...inputActividad,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }



    /// calendar de fecha  inicio

    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;

    const [dateStart, setDateStart] = useState(fechaInicioProp);

    useEffect(() => {
        const dateChange = new Date(fechaInicioProp)
        if (gmtHours < 0) {
            setDateStart(SumarDias(dateChange, +1))
        } else {
            setDateStart(dateChange)
        }
    }, [fechaInicioProp, gmtHours])

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidarCambio(true)
        var sendDateEnd = new Date(dateEnd)
        ValidarFechasCruzadas(fecha, sendDateEnd, tipoNovedad, fieldHourNumber, selectedTipoNovedadComplete?.marcaParcial ? true : false, selectedUsuario.usuario, historicoUserSelectedProp)

    }

    /// calendar de fecha fin

    const [dateEnd, setDateEnd] = useState(fechaFinProp);

    useEffect(() => {
        const dateChange = new Date(fechaFinProp)
        if (gmtHours < 0) {
            setDateEnd(SumarDias(dateChange, +1))
        } else {
            setDateEnd(dateChange)
        }
    }, [fechaFinProp, gmtHours])

    const onDateEndChange = (e) => {
        let fecha = e.value

        setDateEnd(fecha)
        setValidarCambio(true)
        var sendDateStart = new Date(dateStart)
        ValidarFechasCruzadas(sendDateStart, fecha, tipoNovedad, fieldHourNumber, selectedTipoNovedadComplete?.marcaParcial ? true : false, selectedUsuario.usuario, historicoUserSelectedProp)

    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const [fieldHourNumber, setFieldHourNumber] = useState('')

    const onChangeFieldHour = (e) => {
        const newValue = e.target.value;
        if ((newValue.match(/\./g) || []).length > 1 || newValue.startsWith('.')) {
            return;
        }
        setFieldHourNumber(e.target.value)
        setValidarCambio(true)
        ValidarFechasCruzadas(dateStart, dateEnd, tipoNovedad, e.target.value, false, selectedUsuario.usuario, historicoUserSelectedProp)
    }
    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    /*  console.log(selectedUsuario) */

    //Almacenamos los valores para enviarlos por props
    const fechaInicio = dateStart
    const fechaFin = dateEnd
    const idUsuario = selectedUsuario.idUsuario
    const idColaborador = selectedUsuario.idColaborador

    var cliente
    if (selectedClienteComplete !== null) {
        cliente = selectedClienteComplete.razonsocial
    }

    var proyecto
    if (selectedProyectoComplete !== null) {
        proyecto = selectedProyectoComplete.nombreproyecto

    }

    var tipoNovedad
    var idTipoNovedad
    if (selectedTipoNovedadComplete !== null) {
        tipoNovedad = selectedTipoNovedadComplete
        idTipoNovedad = selectedTipoNovedadComplete.id
    }

    const actividad = inputActividad.actividad
    /* const idColaborador = cookies.get('idColaborador') */
    const usuario = cookies.get('usuario')

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio

    useEffect(() => {
        setSelectUsuario({
            usuario: usuarioProp,
            idUsuario: idUsuarioProp,
            idColaborador: idColaboradorProp,
            numberDoc: numberDocProp
        })
        setFieldHourNumber(hourNumberProp)
        setSelectedTipoNovedadComplete({
            descripcion: tipoNovedadProp,
            id: idTipoNovedadProp,
            marcaParcial: marcaParcialProp,
            isTraslapo: isTraslapoProp,
            isVacaciones: isVacacionesProp
        })

        setInputActividad({
            actividad: descripcionProp
        })
        setSelectedAreaComplete({
            nombreareanegocio: areaNegocioProp?.nombreareanegocio,
            id: areaNegocioProp?.id
        })
        setSelectedSistemaComplete({
            nombreaplicacion: aplicacionProp?.nombreaplicacion,
            id: aplicacionProp?.id
        })

    }, [usuarioProp, idUsuarioProp, idColaboradorProp, tipoNovedadProp, idTipoNovedadProp,
        descripcionProp, numberDocProp, areaNegocioProp, aplicacionProp, marcaParcialProp,
        isTraslapoProp, isVacacionesProp, hourNumberProp])



    // Modal para el botón regresar
    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            setSelectUsuario({
                usuario: usuarioProp,
                idUsuario: idUsuarioProp,
                idColaborador: idColaboradorProp,
                numberDoc: numberDocProp
            })
            setSelectedTipoNovedadComplete({
                descripcion: tipoNovedadProp,
                id: idTipoNovedadProp,
                marcaParcial: marcaParcialProp,
                isTraslapo: isTraslapoProp,
                isVacaciones: isVacacionesProp
            })
            setSelectedClienteComplete(null)
            setSelectedProyectoComplete(null)
            setInputActividad({
                actividad: descripcionProp
            })
            const dateChangeStart = new Date(fechaInicioProp)
            const dateChangeEnd = new Date(fechaFinProp)
            if (gmtHours < 0) {
                setDateStart(SumarDias(dateChangeStart, +1))
                setDateEnd(SumarDias(dateChangeEnd, +1))
            } else {
                setDateStart(dateChangeStart)
                setDateEnd(dateChangeEnd)

            }
            setFieldHourNumber(hourNumberProp)
            setFechaInicioError(false)
            setMensajeFechaInicio('')
            setErrorDateStartUserSession(false)
            setErrorMessageDateStartUserSession('')
            setValidarCambio(false)
            setNumberHourZero(false)
            setMessageNumberHourZero('')
            setValidarTipoNovedadError(false)
            setFechaFinError(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

            var setDetalle
            getServices.getDetalleNovedad(idProp).then(data => {
                if (data.length === 1) {
                    data.forEach(element => {
                        setDetalle = {
                            idCliente: element.proyecto.cliente.id,
                            cliente: element.proyecto.cliente.razonsocial,
                            idProyecto: element.proyecto.id,
                            proyecto: element.proyecto.nombreproyecto
                        }
                    });
                    setSelectedClienteComplete({
                        id: setDetalle.idCliente,
                        razonsocial: setDetalle.cliente
                    })
                    setSelectedProyectoComplete({
                        id: setDetalle.idProyecto,
                        nombreproyecto: setDetalle.proyecto
                    })
                    let newData = data.map(item => {
                        return {
                            idDetalleNovedad: item.id,
                            cliente: item.proyecto.cliente,
                            nombre: item.proyecto.nombreproyecto,
                            idProyecto: item.proyecto.id,
                            porcentaje: item.porcentaje
                        }
                    })
                    setDetalleNovedad(newData)
                } else if (data.length > 1) {
                    let newData = data.map(item => {
                        return {
                            idDetalleNovedad: item.id,
                            cliente: item.proyecto.cliente,
                            nombre: item.proyecto.nombreproyecto,
                            idProyecto: item.proyecto.id,
                            porcentaje: item.porcentaje
                        }
                    })
                    setDetalleNovedad(newData)
                }

            })

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    // validar si el usuario selecionado tiene más de un cliente o proyecto asociado 
    var moreAssociations = false

    if (projectsUser.length > 1) {
        moreAssociations = true
    }

    // armar json de datos 

    var json
    if (moreAssociations === false) {
        json = {
            idNovedad: idProp,
            usuario: usuario,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            idUsuario: idUsuario,
            idColaborador: idColaborador,
            proyecto: detalleNovedad,
            idTipoNovedad: idTipoNovedad,
            actividad: actividad,
            fecCreacion: fecCreacionProp,
            usuarioCreacion: usuarioCreacionProp,
            aplicacion: selectedSistemaComplete?.id,
            areanegocio: selectedAreaComplete?.id,
            idproveedor: parseInt(idProveedor),
            numerohoras: fieldShow ? fieldHourNumber : fieldHourMax
        }

    } else if (moreAssociations === true) {
        json = {
            idNovedad: idProp,
            usuario: usuario,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            idUsuario: idUsuario,
            idColaborador: idColaborador,
            proyecto: detalleNovedad,
            idTipoNovedad: idTipoNovedad,
            actividad: actividad,
            fecCreacion: fecCreacionProp,
            usuarioCreacion: usuarioCreacionProp,
            aplicacion: selectedSistemaComplete?.id,
            areanegocio: selectedAreaComplete?.id,
            idproveedor: parseInt(idProveedor),
            numerohoras: fieldShow ? fieldHourNumber : fieldHourMax
        }
    }


    const renderFooter = (name) => {

        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }


                {
                    permisoRegistrar ?
                        <>
                            <ConfirmBtn
                                titulo="Actualizar novedad"
                                mensaje="La información ingresada se guardará en su registro de novedades"
                                accion="ActualizarNovedad"
                                label="Actualizar"
                                icono="pi pi-pencil"
                                indicadorProp="2"
                                jsonProp={json}
                                extenderVacacionesProp={extenderVacaciones}
                                isTotalProp={isTotal}
                                horasMaxUsuarioProp={fieldHourMax !== 0 ? fieldHourMax : fieldHourNumber}
                            />
                        </>
                        :
                        ""
                }

            </div>
        );
    }

    //validaciones

    /// Calcular Días laborales 

    /* function calculateWorkdays(startDate, endDate) {
        const totalDays = moment(endDate).diff(moment(startDate), 'days') + 1;
        const dayOfWeek = moment(startDate).isoWeekday();
        let totalWorkdays = 0;

        for (let i = dayOfWeek; i < totalDays + dayOfWeek; i++) {
            if (i % 7 !== 6 && i % 7 !== 0) {
                totalWorkdays++;
            }
        }
        return totalWorkdays;
    } */

    var clienteError = false
    var mensajeCliente = ''
    var disabledCliente = false


    var disabledProyecto = false

    if (typeof (selectedClienteComplete) === 'string') {
        clienteError = true
        mensajeCliente = 'Resultado no encontrado'
        disabledProyecto = true
    }

    var proyectoError = false
    var mensajeProyecto = ''

    if (selectedClienteComplete === null) {
        disabledProyecto = true
    }

    if (typeof (selectedProyectoComplete) === 'string') {
        proyectoError = true
        mensajeProyecto = 'Resultado no encontrado'

    }

    var tipoNovedadError = false
    var mensajeTipoNovedad = ''

    const [tipoValidarNovedadError, setValidarTipoNovedadError] = useState(false)

    if (typeof (selectedTipoNovedadComplete) === 'string') {
        tipoNovedadError = true
        mensajeTipoNovedad = 'Resultado no encontrado'
    }

    if (tipoValidarNovedadError === true) {
        tipoNovedadError = true
        mensajeTipoNovedad = 'No se pueden cruzar novedades con este tipo de novedad'
    } else {
        tipoNovedadError = false
        mensajeTipoNovedad = ''
    }

    /* console.log(tipoValidarNovedadError) */


    var actividadError = false
    var mensajeActividad = ''
    if (actividad.length < 10 && actividad !== '') {
        actividadError = true
        mensajeActividad = 'La descripción es muy corta'
    } else if (actividad.length > 200) {
        actividadError = true
        mensajeActividad = 'La descripción es demasiado larga '
    }

    const [fechaInicioError, setFechaInicioError] = useState(false)
    const [mensajeFechaInicio, setMensajeFechaInicio] = useState('')
    const [fechaFinError, setFechaFinError] = useState(false)
    const [mostrarModalAdvertencia, setMostrarModalAdvertencia] = useState(false)
    const [mostrarExtenderVacaciones, setMostrarExtenderVacaciones] = useState(false)
    const [extenderVacaciones, setExtenderVacaciones] = useState(false)
    const [mensajeAdvertencia, setMensajeAdvertencia] = useState('')
    const [marcaParcial, setMarcaParcial] = useState(false)
    const [tiempoCompleto, setTiempoCompleto] = useState(false)
    const [isTotal, setIsTotal] = useState(isTotalProp)


    const getRangeDate = (newDateStart, newDateEnd) => {
        const datesInRange = [];
        let datesFormat = []
        const currentDate = new Date(newDateStart);
        const endDate = new Date(newDateEnd);
        while (currentDate <= endDate) {
            const dateCopy = new Date(currentDate); // Copia la fecha actual
            dateCopy.setHours(0, 0, 0, 0); // Establece la hora a las 00:00:00
            datesInRange.push(new Date(dateCopy));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        if (gmtHours < 0) {
            datesInRange.forEach(nd => {
                datesFormat.push(SumarDias(new Date(nd), 1))
            })
        } else {
            datesInRange.forEach(nd => {
                datesFormat.push(new Date(nd))
            })
        }
        return datesFormat
    }

    //Estado que se vuelve true cuando se exceda el limite de horas
    const [errorHours, setErrorHours] = useState(false)
    // Validamos los tipos de traslapos
    const [numberHourZero, setNumberHourZero] = useState(false)
    const [messageNumberHourZero, setMessageNumberHourZero] = useState('')

    const ValidarFechasCruzadas = async (dateStart, dateEnd, tipoNovedad, hours, validarCambio2, userEditing, historicoUserSelected) => {

        let datesFormat = getRangeDate(dateStart !== null ? CambiarFormatoFecha(dateStart) : '', dateEnd !== null ? CambiarFormatoFecha(dateEnd) : '')

        let tieneMaximaCero = false;

        if (dateStart !== null && dateEnd !== null) {
            var validacionFechas = Validarfechas(CambiarFormatoFecha(dateStart), CambiarFormatoFecha(dateEnd))

            if (validacionFechas.setFechaError === true) {
                setFechaInicioError(validacionFechas.setFechaError)
                setMensajeFechaInicio(validacionFechas.setMensajeFecha)
            } else {
                setFechaInicioError(validacionFechas.setFechaError)
                setMensajeFechaInicio(validacionFechas.setMensajeFecha)
                var validarMesCerrado = ValidarMesCerrado(dateStart, minFechaCierre, disabledDates)
                if (validarMesCerrado.setFechaError === true) {
                    setFechaInicioError(validarMesCerrado.setFechaError)
                    setMensajeFechaInicio(validarMesCerrado.setMensajeFecha)
                    setMostrarModalAdvertencia(validarMesCerrado.setMostrarAdvertencia)
                    setMensajeAdvertencia('La fecha seleccionada corresponde a un mes cerrado, debe solicitar el permiso al administrador para poder registrar la novedad')
                    setMostrarExtenderVacaciones(false)
                    setFechaFinError(false)
                }

            }

            if (dateStart !== null && dateEnd !== null && tipoNovedad) {
                if (validacionFechas.setFechaError === false) {
                    var validarNovedadesCruzadas = ValidarNovedadesCruzadas(dateStart, dateEnd, tipoNovedad, consultarNovedades, idProp)

                    if (validarNovedadesCruzadas.setMostrarExtenderVacaciones === true) {
                        if (validarCambio2 === true) {
                            setFechaInicioError(validarNovedadesCruzadas.setFechaError)
                            setMensajeFechaInicio(validarNovedadesCruzadas.setMensajeFecha)
                            setFechaFinError(validarNovedadesCruzadas.setFechaError)
                            setValidarTipoNovedadError(validarNovedadesCruzadas.setValidarTipoNovedadError)
                            setMostrarModalAdvertencia(validarNovedadesCruzadas.setMostrarModalAdvertencia)
                            setMensajeAdvertencia(validarNovedadesCruzadas.setMensajeAdvertencia)
                            setMostrarExtenderVacaciones(validarNovedadesCruzadas.setMostrarExtenderVacaciones)
                            setMarcaParcial(false)
                        } else {
                            /*  if (parseFloat(hours) > parseFloat(newHoursMax)) {
                                 setMostrarModalAdvertencia(true)
                                 setMarcaParcial(false)
                                 setMensajeAdvertencia('No es posible registrar la novedad, ya que en el rango de fechas seleccionado superaría las horas maximas permitidas')
                                 setTiempoCompleto(true)
                                 setFechaInicioError(true)
                                 setMostrarExtenderVacaciones(false)
                             } else { */
                            setFechaInicioError(validarNovedadesCruzadas.setFechaError)
                            setMensajeFechaInicio(validarNovedadesCruzadas.setMensajeFecha)
                            setFechaFinError(validarNovedadesCruzadas.setFechaError)
                            setValidarTipoNovedadError(validarNovedadesCruzadas.setValidarTipoNovedadError)
                            /* } */
                        }
                    } else {
                        if (validarNovedadesCruzadas.setMostrarModalAdvertencia === true) {
                            setFechaInicioError(validarNovedadesCruzadas.setFechaError)
                            setMensajeFechaInicio(validarNovedadesCruzadas.setMensajeFecha)
                            setFechaFinError(validarNovedadesCruzadas.setFechaError)
                            setValidarTipoNovedadError(validarNovedadesCruzadas.setValidarTipoNovedadError)
                            setMostrarModalAdvertencia(validarNovedadesCruzadas.setMostrarModalAdvertencia)
                            setMensajeAdvertencia(validarNovedadesCruzadas.setMensajeAdvertencia)
                            setMostrarExtenderVacaciones(validarNovedadesCruzadas.setMostrarExtenderVacaciones)
                            setMarcaParcial(false)
                        } else {
                            setFechaInicioError(validarNovedadesCruzadas.setFechaError)
                            setMensajeFechaInicio(validarNovedadesCruzadas.setMensajeFecha)
                            setFechaFinError(validarNovedadesCruzadas.setFechaError)
                            setValidarTipoNovedadError(validarNovedadesCruzadas.setValidarTipoNovedadError)
                            if (validarCambio2 === true) {
                                setMarcaParcial(true)
                                setMostrarModalAdvertencia(true)
                            } else {
                                setMarcaParcial(false)
                                setMostrarModalAdvertencia(false)
                            }
                        }


                        const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
                        const newDateStart = CambiarFormatoFecha(dateStart)
                        const newDateEnd = CambiarFormatoFecha(dateEnd)

                        const json = JSON.stringify({
                            esadicional: "0",
                            fecFinal: newDateEnd,
                            fecInicial: newDateStart,
                            usuario: userEditing === undefined || userEditing === '' ? usuario : userEditing
                        })
                        // console.log(documentNumber)
                        await axios.post(url, json,
                            {
                                headers:
                                {
                                    'Content-Type': 'application/json',
                                    "Access-Control-Allow-Origin": "*",
                                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                                }
                            })
                            .then(response => {

                                //Validar generalmente cuando el campo sea igual a 0
                                if (parseFloat(hours) === parseFloat(0) && parseFloat(hours) === parseFloat(0.0)) {
                                    setMarcaParcial(false)
                                    setMessageNumberHourZero('Las horas registradas deben ser mayor a 0 \n')
                                    setTiempoCompleto(true)
                                    setMostrarExtenderVacaciones(false)
                                    setNumberHourZero(true)
                                } else {
                                    setMessageNumberHourZero('')
                                    setNumberHourZero(false)
                                }
                                let existeActividadNormal = alMenosUnElementoExiste(response.data, servicioTipoNovedad)
                                let actividades = filterNewsDifferent(response.data, servicioTipoNovedad)

                                let horasPorFecha = {};

                                if (actividades.length !== 0) {

                                    datesFormat.forEach(dates => {
                                        actividades.forEach(element => {

                                            const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(historicoUserSelected !== undefined && historicoUserSelected?.length !== 0 ? historicoUserSelected : newHistoricoHoras, [element.fecha])

                                            if (element.fecha === CambiarFormatoFecha(dates)) {
                                                if (!horasPorFecha.hasOwnProperty(element.fecha)) {
                                                    horasPorFecha[element.fecha] = 0;
                                                }
                                                horasPorFecha[element.fecha] += parseFloat(element.numerohoras);
                                            }

                                            if (parseFloat(horasMaximaPorFecha[CambiarFormatoFecha(dates)]) === 0) {

                                                setFieldShow(true)
                                                tieneMaximaCero = true;
                                                setFieldHourMax(0)

                                            } else {
                                                if (tipoNovedad.marcaParcial === true) {
                                                    setFieldShow(true)
                                                } else {
                                                    setFieldHourMax(parseFloat(horasMaximaPorFecha[CambiarFormatoFecha(dates)]))
                                                    setFieldShow(false)
                                                }
                                            }

                                            if (existeActividadNormal === true && tipoNovedad.marcaParcial === false) {
                                                setMostrarExtenderVacaciones(false)
                                                setMostrarModalAdvertencia(true)
                                                setMensajeAdvertencia('No es posible registrar la novedad, ya que es una novedad de tiempo completo y en el rango de fechas seleccionado se registran horas de actividad laboral')
                                                setMarcaParcial(false)
                                                setTiempoCompleto(true)

                                            } else if ((existeActividadNormal === true && tieneMaximaCero===true && horasPorFecha[element.fecha] + parseFloat(hours) > parseFloat(24)) ||
                                                (existeActividadNormal === true && tieneMaximaCero===false && horasPorFecha[element.fecha] + parseFloat(hours) > parseFloat(horasMaximaPorFecha[element.fecha]))) {
                                                setMostrarModalAdvertencia(true)
                                                setMostrarExtenderVacaciones(false)
                                                setMarcaParcial(false)
                                                setMensajeAdvertencia('No es posible registrar la novedad, ya que en el rango de fechas seleccionado hay una o más actividades que superarían las horas maximas permitidas')
                                                setTiempoCompleto(true)
                                                setErrorHours(true)
                                            } else {
                                                setErrorHours(false)
                                            }
                                        })
                                    })
                                } else {
                                    datesFormat.forEach(dates => {

                                        const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(historicoUserSelected !== undefined && historicoUserSelected?.length !== 0 ? historicoUserSelected : newHistoricoHoras, [CambiarFormatoFecha(dates)])

                                        if (parseFloat(horasMaximaPorFecha[CambiarFormatoFecha(dates)]) === 0) {

                                            setFieldShow(true)
                                            tieneMaximaCero = true;
                                            setFieldHourMax(0)

                                        } else {
                                            if (tipoNovedad.marcaParcial === true) {
                                                setFieldShow(true)
                                            } else {
                                                setFieldHourMax(parseFloat(horasMaximaPorFecha[CambiarFormatoFecha(dates)]))
                                                setFieldShow(false)
                                            }
                                        }

                                        if (tipoNovedad.marcaParcial === false) {
                                            setMarcaParcial(false)
                                            setTiempoCompleto(true)
                                            setMostrarExtenderVacaciones(false)
                                        }
                                        if ((tieneMaximaCero===true && parseFloat(hours) > parseFloat(24)) ||
                                            (tieneMaximaCero===false && parseFloat(hours) > parseFloat(horasMaximaPorFecha[CambiarFormatoFecha(dates)]))) {
                                            setMostrarModalAdvertencia(true)
                                            setMarcaParcial(false)
                                            setMensajeAdvertencia('No es posible registrar la novedad, ya que en el rango de fechas seleccionado superaría las horas maximas permitidas')
                                            setTiempoCompleto(true)
                                            setErrorHours(true)
                                            setMostrarExtenderVacaciones(false)
                                        } else {
                                            setErrorHours(false)
                                        }
                                    })
                                }
                            })
                    }
                }
            }
        }

    }

    //Se valida que si por lo menos existe una actividad normal para no permitir actualizar a tipo de novedad
    //de tiempo completo
    function alMenosUnElementoExiste(actividades, tipoNovedades) {

        let valueReturn = false

        actividades.forEach(i => {
            const filter = tipoNovedades.filter(obj => {
                return obj.tipoActividad.id === i.tipoActividad.id
            })
            if (filter.length === 0) {
                valueReturn = true
            }
        })
        return valueReturn
    }

    //Función que no me cuenta la novedad que estoy editanto

    function filterNewsDifferent(actividades, tipoNovedades) {

        let array = []
        actividades.forEach(actividad => {
            const found = tipoNovedades.some(tipo => tipo.tipoActividad.id === actividad.tipoActividad.id)
            if (!found) {
                array.push(actividad)
            }
        })
        const tableActivities = {}
        const uniqueActivity = array.filter(function (object) {
            return tableActivities.hasOwnProperty(object.id) ? false : (tableActivities[object.id] = true)
        })
        return uniqueActivity
    }


    // validar porcentajes

    const [totalPercentage, setTotalPercentage] = useState(0)

    const formatCurrency = (value) => {
        return value + '%';
    }

    const calcularProcentaje = () => {
        let total = 0;
        for (let detalleNovedades of detalleNovedad) {
            total += detalleNovedades.porcentaje;
        }
        setTotalPercentage(formatCurrency(total))
        return formatCurrency(total);
    }

    var showError = false
    var mesajeError = ''

    if (parseInt(totalPercentage) > 100 || (parseInt(totalPercentage) > 0 && parseInt(totalPercentage) < 100)) {
        showError = true
        mesajeError = 'Los porcentajes no suman el 100%, distribuya nuevamente los porcentajes'
    } else {
        showError = false
    }

    // radiobutton para seleccion de tiempo parcial y timepo completo

    // modal cuando se selecciona una fecha de un mes cerrado

    const ModalAdvertencia = ({ mesCerrado, MensajeAdvertencia, mostrarExtenderVacaciones }) => {

        const [displayConfirmation, setDisplayConfirmation] = useState(mesCerrado);

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onHide = (name, boolean) => {
            dialogFuncMap[`${name}`](false);
            setMostrarModalAdvertencia(false)
            setExtenderVacaciones(boolean)
            setDisplayBasic(false)
            setErrorDateStartUserSession(false)
            setErrorMessageDateStartUserSession('')
            setSelectUsuario({
                usuario: usuarioProp,
                idUsuario: idUsuarioProp,
                idColaborador: idColaboradorProp,
                numberDoc: numberDocProp
            })
            setSelectedClienteComplete(null)
            setSelectedProyectoComplete(null)
            setSelectedTipoNovedadComplete({
                descripcion: tipoNovedadProp,
                id: idTipoNovedadProp
            })
            setInputActividad({
                actividad: descripcionProp
            })

            const dateChangeStart = new Date(fechaInicioProp)
            const dateChangeEnd = new Date(fechaFinProp)
            if (gmtHours < 0) {
                setDateStart(SumarDias(dateChangeStart, +1))
                setDateEnd(SumarDias(dateChangeEnd, +1))
            } else {
                setDateStart(dateChangeStart)
                setDateEnd(dateChangeEnd)

            }

            setFechaInicioError(false)
            setMensajeFechaInicio('')

            setValidarCambio(false)
            setValidarTipoNovedadError(false)

            var setDetalle
            getServices.getDetalleNovedad(idProp).then(data => {
                if (data.length === 1) {
                    data.forEach(element => {
                        setDetalle = {
                            idCliente: element.proyecto.cliente.id,
                            cliente: element.proyecto.cliente.razonsocial,
                            idProyecto: element.proyecto.id,
                            proyecto: element.proyecto.nombreproyecto
                        }
                    });
                    setSelectedClienteComplete({
                        id: setDetalle.idCliente,
                        razonsocial: setDetalle.cliente
                    })
                    setSelectedProyectoComplete({
                        id: setDetalle.idProyecto,
                        nombreproyecto: setDetalle.proyecto
                    })
                    let newData = data.map(item => {
                        return {
                            idDetalleNovedad: item.id,
                            cliente: item.proyecto.cliente,
                            nombre: item.proyecto.nombreproyecto,
                            idProyecto: item.proyecto.id,
                            porcentaje: item.porcentaje
                        }
                    })
                    setDetalleNovedad(newData)
                } else if (data.length > 1) {
                    let newData = data.map(item => {
                        return {
                            idDetalleNovedad: item.id,
                            cliente: item.proyecto.cliente,
                            nombre: item.proyecto.nombreproyecto,
                            idProyecto: item.proyecto.id,
                            porcentaje: item.porcentaje
                        }
                    })
                    setDetalleNovedad(newData)
                }

            })
        }

        const aceptar = (name, boolean) => {
            dialogFuncMap[`${name}`](false);
            setMostrarModalAdvertencia(false)
            setExtenderVacaciones(boolean)
            if (tiempoCompleto === true) {
                if (errorHours === false) {
                    setFechaInicioError(true)
                    setMensajeFechaInicio('Debe modificar alguno de estos campos')
                    /* setNewTipoNovedadError(true) */
                    setFechaFinError(true)
                }

            }

        }

        const renderFooter = (name) => {
            return (
                <>
                    {
                        marcaParcial
                            ?
                            <div>

                            </div>
                            :
                            <div>

                                {
                                    mostrarExtenderVacaciones
                                        ?
                                        <Button label="No" icon="pi pi-times" onClick={mostrarExtenderVacaciones ? () => aceptar(name, false) : () => onHide(name, false)} className="p-button-text" />
                                        :
                                        ""
                                }
                                <Button label={mostrarExtenderVacaciones ? "Si" : "Aceptar"} icon="pi pi-times" onClick={() => aceptar(name, true)} className="p-button-text" />
                            </div>
                    }


                </>
            );
        }

        return (
            <>


                {

                    marcaParcial === false ?
                        <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal breakpoints={{ '960px': '75vw' }} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                            <div className="confirmation-content">


                                <>
                                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                    <span style={{ textAlign: 'center' }}>{MensajeAdvertencia}</span>
                                </>

                            </div>
                        </Dialog>
                        :
                        ''
                }

            </>
        )
    }
    // validar Mes cerrado

    var minFechaCierre
    var disabledDates

    const resultUser = servicesUsers.filter(item => {
        return parseInt(item.idUsuario) === parseInt(idUsuario)
    })

    var mesAbierto = []
    if (resultUser.length !== 0) {
        resultUser.forEach(element => {
            mesAbierto = cierres.filter(function (objeto) {
                var id = element.blnAddActivitivy
                return objeto.id === id
            })
        })
    }
    const mesesCerrados = cierres.filter(function (objeto) {
        return objeto.estado === 'C'
    })
    var fechaCierre = []

    if (mesAbierto.length === 0) {
        cierres.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    } else {
        mesesCerrados.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    }

    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))
    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))

    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })

    var convertirFechaFinal = new Date(getMesAbierto.fechaFinalCierre)
    var newFechaFinalCierre = SumarDias(convertirFechaFinal, +2)

    var convertirFechaInicial = new Date(getMesAbierto.fechaInicialCierre)
    var newFechaInicialCierre = SumarDias(convertirFechaInicial, +1)

    /* console.log(getMesAbierto.fechaInicialCierre)
    console.log(getMesAbierto.fechaFinalCierre) */

    var dateArray = GetDates(newFechaFinalCierre, (fechaUltimoCierre).addDays(2));

    if (dateArray.length !== 0) {
        minFechaCierre = newFechaInicialCierre
        disabledDates = dateArray
    } else {
        minFechaCierre = SumarDias(fechaUltimoCierre, +1)
        disabledDates = []
    }

    // data table responsive 

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, } = e;
        return rowData[field] = newValue;
    }

    const porcentajeEditor = (props) => {
        return <>
            <h5 style={{ marginBottom: '13px' }}> {props.value} %</h5>
            <Slider value={props.value} onChange={(e) => props.editorCallback(e.value)} step={5} />
        </>
    }

    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="Cliente" field="cliente.nombre" rowSpan={3} />

        </Row>
        <Row>
            <Column header="Proyecto" field="nombre" />
            <Column header="Porcentaje" field="porcentaje" />

        </Row>
    </ColumnGroup>;

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Total:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
            <Column footer={calcularProcentaje} />
        </Row>
    </ColumnGroup>;
    // Sé valida que las fechas de registrar actividad no sean menores que la fecha de ingreso del usuario en sessión
    const [errorDateStartUserSession, setErrorDateStartUserSession] = useState(false)
    const [errorMessageDateStartUserSession, setErrorMessageDateStartUserSession] = useState('')

    useEffect(() => {
        const dateStartUserSession = cookies.get('fechaIngreso')
        let arrDate = []
        let newDateStart = new Date(dateStart)
        if (newDateStart !== null) {
            if (CambiarFormatoFecha(newDateStart) < dateStartUserSession) {
                arrDate.push(newDateStart)
            }
            if (arrDate.length !== 0) {
                setErrorDateStartUserSession(true)
                setErrorMessageDateStartUserSession(`No puedes registrar actividades con fecha menor a su fecha de ingreso, la cuál es ${dateStartUserSession}`)
            } else {
                setErrorDateStartUserSession(false)
                setErrorMessageDateStartUserSession('')
            }
        }

    }, [dateStart])// eslint-disable-line react-hooks/exhaustive-deps


    //Validar que solo acepte numeros enteros o decimales
    const [valueValidNumberMax, setValueValidNumberMax] = useState(false)
    const [messageValidNumberMax, setMessageValidNumberMax] = useState('')
    useEffect(() => {
        if (fieldHourNumber !== '') {
            const getValidNumberMax = isValidNumber(fieldHourNumber)
            if (getValidNumberMax === false && numberHourZero === false) {
                setValueValidNumberMax(true)
                setMessageValidNumberMax('Debe ingresar números enteros o decimales')
            } else {
                setValueValidNumberMax(false)
                setMessageValidNumberMax('')
            }
        }
        function isValidNumber(value) {
            // Verifica si el valor es un número válido
            return /^\d+(\.\d+)?$/.test(value);
        }
    }, [fieldHourNumber, numberHourZero])// eslint-disable-line react-hooks/exhaustive-deps
    // permisos para registrar 

    var permisoRegistrar = false

    if (fechaInicioError === false && fechaInicio !== null && fechaFin !== null && clienteError === false && cliente !== '' && proyectoError === false && tipoNovedadError === false && tipoNovedad !== ''
        && selectedTipoNovedadComplete !== null && actividadError === false &&
        actividad !== '' && showError !== true && errorDateStartUserSession === false &&
        valueValidNumberMax === false && numberHourZero === false && errorHours === false) {
        if (moreAssociations === true) {
            if (parseInt(totalPercentage) === 0) {
                permisoRegistrar = false
            } else if (parseInt(totalPercentage) === 100) {
                permisoRegistrar = true
            }
            if (fieldShow === true && (fieldHourNumber === null || fieldHourNumber === '')) {
                permisoRegistrar = false
            }
        } else {
            if (proyecto !== undefined) {
                permisoRegistrar = true
            }
            if (fieldShow === true && (fieldHourNumber === null || fieldHourNumber === '')) {
                permisoRegistrar = false
            }
        }

    }
    return (
        <div className="">
            <Toast ref={toast} />
            <div>
                <Button className="btnEditar p-button-secondary" icon="pi pi-pencil" onClick={() => onClick('displayBasic')}
                    tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} disabled={disabledProp}
                ></Button>

                <Dialog className="modal" header="Actualizar Novedad" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">
                        <ModalAdvertencia
                            mesCerrado={mostrarModalAdvertencia}
                            MensajeAdvertencia={mensajeAdvertencia}
                            mostrarExtenderVacaciones={mostrarExtenderVacaciones}
                        />
                        <form style={{ display: 'contents' }}  >

                            <div className="p-field p-col-12 p-md-3">
                                <label className="labels" >Fecha Inicio<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                    className={fechaInicioError || errorDateStartUserSession ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{errorDateStartUserSession ? errorMessageDateStartUserSession : ""}</p>
                                <p className="mensajeError">{fechaInicioError ? mensajeFechaInicio : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label className="labels" >Fecha Fin<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                    className={fechaFinError ? "p-invalid" : ""}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Funcionario<span className="require">*</span></label>
                                <Dropdown value={selectedUsuario} options={usuarios} onChange={onUsuarioChange} name="usuario" optionLabel="usuario" emptyFilterMessage="No se encuentran resultados"
                                    placeholder="Seleccione un Usuario" filter filterBy="usuario" filterMatchMode="startsWith" disabled
                                />
                            </div>
                            {
                                moreAssociations === false
                                    ?
                                    <>
                                        <div className="p-field p-col-12 p-md-6">
                                            <label className="labels" >Cliente<span className="require">*</span></label>
                                            <AutoComplete value={selectedClienteComplete} suggestions={filteredClientes} completeMethod={searchClientes} field="razonsocial"
                                                onChange={onClienteChange} placeholder="Seleccione un cliente" dropdown forceSelection filterMatchMode="startsWith"
                                                className={clienteError ? "p-invalid auto" : "auto"} disabled={disabledCliente}
                                            />
                                            <p className="mensajeError">{clienteError ? mensajeCliente : ""}</p>
                                        </div>
                                        <div className="p-field p-col-12 p-md-6">
                                            <label className="labels" >Proyecto<span className="require">*</span></label>
                                            <AutoComplete value={selectedProyectoComplete} suggestions={filteredProyectos} completeMethod={searchProyectos} field="nombreproyecto"
                                                onChange={onProyectoChange} placeholder="Seleccione un proyecto" dropdown forceSelection filterMatchMode="startsWith"
                                                disabled={disabledProyecto}
                                                className={proyectoError ? "p-invalid auto" : "auto"}
                                            />
                                            <p className="mensajeError">{proyectoError ? mensajeProyecto : ""}</p>
                                        </div>
                                    </>
                                    :
                                    ""
                            }

                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Tipo Novedad<span className="require">*</span></label>
                                <Dropdown value={selectedTipoNovedadComplete} options={opcionesNovedad} optionLabel="descripcion"
                                    onChange={onTipoNovedadCompleteChange} placeholder="Seleccione un tipo de Novedad" filter resetFilterOnHide
                                    className={tipoNovedadError ? "p-invalid auto" : "auto"}
                                />
                                <p className="mensajeError">{tipoNovedadError ? mensajeTipoNovedad : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Área Negocio<span className="require">*</span></label>
                                <Dropdown value={selectedAreaComplete} options={optionsArea} optionLabel="nombreareanegocio"
                                    onChange={onAreaCompleteChange} placeholder="Seleccione un área de negocio" filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Sistema Aplicación<span className="require">*</span></label>
                                <Dropdown value={selectedSistemaComplete} options={optionsApp} optionLabel="nombreaplicacion"
                                    onChange={onAppCompleteChange} placeholder="Seleccione un Sistema" filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Descripción<span className="require">*</span></label>
                                <InputText id="actividad" type="text" name="actividad" keyfilter={/^[^<>°|'{}]+$/} onChange={onActividadChange} value={actividad}
                                    placeholder="Describa la Actividad que realizó"
                                    className={actividadError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{actividadError ? mensajeActividad : ""}</p>
                            </div>
                            {
                                fieldShow ?
                                    <div className="p-field p-col-12 p-md-4">
                                        <label className="labels" >Número de Horas por día<span className="require">*</span></label>
                                        <InputText className={valueValidNumberMax || numberHourZero || errorHours ? "p-invalid" : ""} value={fieldHourNumber} onChange={onChangeFieldHour} placeholder='Ingrese número de horas' keyfilter={/^[0-9]*\.?[0-9]*$/} />
                                        <p className="mensajeError" >{valueValidNumberMax ? messageValidNumberMax : ""}{numberHourZero ? messageNumberHourZero : ""}</p>
                                    </div>
                                    :
                                    ''
                            }
                        </form>
                    </div>
                    {
                        moreAssociations === false
                            ?
                            ""
                            :
                            <>
                                <div className="datatable-responsive-demo">
                                    <div className="card">
                                        <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>A continuación ingrese los porcentajes correspondientes a cada cliente proyecto</h4>
                                        <DataTable ref={dt} value={detalleNovedad} className="p-datatable-responsive-demo" style={{ overflow: 'hidden' }}
                                            editMode="cell" emptyMessage="No se registran datos" headerColumnGroup={headerGroup} footerColumnGroup={footerGroup}
                                        >
                                            <Column className="columna" headerStyle={{ width: '160px' }} field="cliente.razonsocial" header="Cliente" />
                                            <Column className="columna" headerStyle={{ width: '160px' }} field="nombre" header="Proyecto" />
                                            <Column className="columna" headerStyle={{ width: '160px' }} field="porcentaje" header="Porcentaje"
                                                editor={(props) => porcentajeEditor(props)} onCellEditComplete={onCellEditComplete}
                                            />

                                        </DataTable>
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            {
                                                showError === true ?
                                                    <Message severity="error" text={mesajeError} />
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </Dialog>
            </div>
        </div>
    )

}

export default BtnActualizarNovedad