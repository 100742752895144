import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { Dropdown } from 'primereact/dropdown';
import Services from '../../service/Services';
import { AutoComplete } from 'primereact/autocomplete';
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
// import { InputNumber } from 'primereact/inputnumber';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import ConfirmBtn from "./ConfirmBtn";
import { Toast } from 'primereact/toast';
import { CambiarFormatoFecha, removeAccents } from '../Funciones';
import axios from 'axios';

const BtnCrearEntregable = ({ idProjectProp, projectProp, dateStartValidityProp, dateEndValidityProp, getClientsProp, individualMonthReportProp, runRenderProp,
    setRunRenderProp, reloadObjectiveProp, runRenderBlockProp, setRunRenderBlockProp, deliverablesProp,
    objectivesProp, projectsUserSessionProp, listDeliverableProp, newDeliverablesProp }) => {

    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [objective, setObjective] = useState([]);
    /*  const [assignedProjects, setAssingnedProjects] = useState([]); */
    const [clientsUserSession, setClientsUserSession] = useState([]);
    const [deliverables, setDeliverables] = useState([]);
    const [newResetData, setNewResetData] = useState(false)
    const getServices = new Services();
    const [displayBasic, setDisplayBasic] = useState(false);
    const toast = useRef(null);

    //Obtener datos del autocompletar entregables

    const [autoDeliverables, setAutoDeliverables] = useState(null)
    const [filteredDeliverables, setFilteredDeliverables] = useState(null);

    const onAutoDeliverablesChange = (e) => {
        setAutoDeliverables(e.value)
        setValidateChange(true)
    }

    const searchDeliverable = (event) => {
        setTimeout(() => {
            let _filteredDeliverables;
            if (!event.query.trim().length) {
                _filteredDeliverables = [...deliverables];
            }
            else {
                _filteredDeliverables = deliverables.filter((object) => {
                    return object.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            const deliverablesTable = {}
            const uniqueDeliverable = _filteredDeliverables?.filter(function (object) {
                return deliverablesTable.hasOwnProperty(object.id) ? false : (deliverablesTable[object.id] = true)
            })
            setFilteredDeliverables(uniqueDeliverable);
        }, 250);
    }

    var assingnedNewObjectiveDeliverable = false

    if (typeof autoDeliverables === 'string' || autoDeliverables === null) {
        assingnedNewObjectiveDeliverable = false
    } else if (typeof autoDeliverables === 'object') {
        assingnedNewObjectiveDeliverable = true
    }
    const [servicesDeliverables, setServicesDeliverables] = useState([])
    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true && individualMonthReportProp ===true) {
            getServices.getDeliverables().then(data => {
                if (data !== undefined) {
                    setServicesDeliverables(data)
                }
            });
        }

        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic, individualMonthReportProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true) {

            if (individualMonthReportProp === true) {
                setObjective(objectivesProp)
                var nameClients = []
                projectsUserSessionProp.forEach(element => {
                    var clients = {}
                    clients.id = element.cliente.id
                    clients.name = element.cliente.nombre
                    nameClients.push(clients)
                })
                const clientsTable = {}
                const uniqueClients = nameClients.filter(function (object) {
                    return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                })

                if (autoDeliverables !== null && typeof autoDeliverables !== 'string') {
                    if (typeof autoDeliverables === 'object') {
                        var arrProject = []
                        var arrAssinged = []
                        autoDeliverables.objetivos.forEach(element => {
                            arrAssinged.push({
                                name: element.proyecto.nombreproyecto,
                                id: parseInt(element.proyecto.id),
                            })
                        })
                        /*  setAssingnedProjects(arrAssinged) */
                        projectsUserSessionProp.forEach(element => {
                            arrProject.push(element)
                        })
                    }
                } else {
                    const projectsTable = {}
                    const uniqueProjects = projectsUserSessionProp.filter(function (object) {
                        return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                    })
                    setProjectsUserSession(uniqueProjects)
                    setClientsUserSession(uniqueClients)

                    setSelectedClient({
                        id: getClientsProp.id,
                        name: getClientsProp.client
                    })
                    setSelectedProject({
                        id: parseInt(idProjectProp),
                        name: projectProp,
                        dateStartValidity: dateStartValidityProp,
                        dateEndValidity: dateEndValidityProp
                    })
                }
                setClientsUserSession(uniqueClients)
                /* console.log(deliverablesProp) */
                var arrDeliverables = []
                const result = deliverablesProp.map(item => {
                    return item.objetivos.map(j => {
                        var boolean
                        if (j.proyecto.id === parseInt(idProjectProp)) {
                            boolean = item
                        }
                        return boolean
                    })
                })
                result.forEach(i => {
                    i.forEach(j => {
                        if (j !== undefined) {
                            if (j.length !== 0) {
                                arrDeliverables.push(j)
                            }
                        }
                    })
                })
                var newData = []
                for (var i = 0; i < deliverablesProp.length; i++) {
                    var equal = false;
                    for (var j = 0; j < arrDeliverables.length & !equal; j++) {
                        if (deliverablesProp[i]['id'] === arrDeliverables[j]['id'] &&
                            deliverablesProp[i]['descripcion'] === arrDeliverables[j]['descripcion'])
                            equal = true;
                    }
                    if (!equal) newData.push(deliverablesProp[i]);
                }
                var filterDeliverableForClient = []
                servicesDeliverables.forEach(element => {
                    element.objetivos.forEach(i => {
                        if (i.proyecto.cliente.id === getClientsProp.id) {
                            filterDeliverableForClient.push(element)
                        }
                    })
                })
                setDeliverables(filterDeliverableForClient)
            }
        }

        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [idProjectProp, projectProp, autoDeliverables, reloadObjectiveProp, objectivesProp, servicesDeliverables, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (individualMonthReportProp === false && displayBasic ===true) {

            getServices.getObjectives().then(data => {
                if (data !== undefined) {
                    setObjective(data)
                }
            });
            getServices.getProyectosUsuarios().then(data => {
                if (data !== undefined) {
                    var nameClients = []
                    data.forEach(element => {
                        var clients = {}
                        clients.id = element.cliente.id
                        clients.name = element.cliente.nombre
                        nameClients.push(clients)
                    })
                    const clientsTable = {}
                    const uniqueClients = nameClients.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })

                    if (autoDeliverables !== null && typeof autoDeliverables !== 'string') {
                        if (typeof autoDeliverables === 'object') {
                            var arrProject = []
                            var arrAssinged = []
                            autoDeliverables.objetivos.forEach(element => {
                                arrAssinged.push({
                                    name: element.proyecto.nombreproyecto,
                                    id: parseInt(element.proyecto.id),
                                })
                            })
                            /*  setAssingnedProjects(arrAssinged) */
                            data.forEach(element => {
                                arrProject.push(element)
                            })
                        }
                    } else {
                        /*  setAssingnedProjects([]) */
                        setProjectsUserSession(data)
                        setClientsUserSession(uniqueClients)
                    }
                    setClientsUserSession(uniqueClients)
                }
            });
            getServices.getDeliverables().then(data => {
                if (data !== undefined) {
                    setDeliverables(data)
                }
            });

        }


    }, [individualMonthReportProp, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps


    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState({
        name: '',
        id: ''
    })

    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: parseInt(item.id) }
        return av
    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setSelectedObjectives({
            descriptionObjectives: '',
            id: ''
        })
        setValidateChange(true)
    }

    //Obtener datos del select Proyecto

    const [selectedProject, setSelectedProject] = useState({
        id: '',
        name: '',
        dateStartValidity: '',
        dateEndValidity: ''
    })

    var projectClient = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const loadProjects = () => {
        if (selectedClient !== undefined) {
            const result = projectsUserSession.filter(function (object) {
                var idClient = parseInt(object.cliente.id)
                return idClient === selectedClient.id
            })

            result.forEach(element => {
                projectClient.push(element)
            });
            projectClient.sort(function (a, b) {
                var textA = a.name;
                var textB = b.name;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

        }
        const filterDate = projectClient.filter(element => {
            return element.fechafinvigencia >= dateV
        })
        filterDate?.forEach(element => {
            proyectoFechaVigente.push(element)
        })
        return proyectoFechaVigente
    }
    var dateValidError = false
    var messageDateValidError = ''

    if (selectedClient !== null) {
        loadProjects()
        if (proyectoFechaVigente.length === 0) {
            dateValidError = true
            messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
        } else {
            dateValidError = false
            messageDateValidError = ''
        }
    }

    const proyectos = proyectoFechaVigente.map(item => {
        const av = { name: item.nombre, id: parseInt(item.id), dateStartValidity: item.fechainiciovigencia, dateEndValidity: item.fechafinvigencia }
        return av
    })

    const onProjectsChange = (e) => {
        setSelectedProject(e.value);
        setSelectedObjectives({
            descriptionObjectives: '',
            id: ''
        })
        setValidateChange(true)
    }

    //Obtener datos del select objetivo

    const [selectedObjectives, setSelectedObjectives] = useState({
        descriptionObjectives: '',
        id: ''
    })

    var porjectsObjectives = []

    const loadObjectives = () => {
        const result = objective.map(item => {
            return item.proyectos.map(j => {
                var boolean
                if (j.proyecto.id === parseInt(selectedProject.id)) {
                    boolean = item
                }
                return boolean
            })
        })

        result.forEach(i => {
            i.forEach(j => {
                if (j !== undefined) {
                    if (j.length !== 0) {
                        porjectsObjectives.push(j)
                    }
                }
            })
        })
    }

    if (selectedProject.id !== '') {
        loadObjectives()
    }

    const objectives = porjectsObjectives.map(item => {
        const av = { descriptionObjectives: item.descripcion, id: item.id }
        return av
    })

    const onObjectivesChange = (e) => {
        setSelectedObjectives(e.value);
        setValidateChange(true)
    }

    //Obtener datos del input entregable

    const [inputDeliverable, setInputDeliverable] = useState({
        deliverable: ''
    })

    const onDeliverableChange = (event) => {

        setInputDeliverable({
            ...inputDeliverable,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del porcentaje ingresado

    const [percentage, setPercentage] = useState(0);

    const onPercentageChange = (e) => {
        setPercentage(e.value)
        setValidateChange(true)
    }

    useEffect(() => {
        if (newResetData === true) {
            resetData()
        }
    }, [newResetData])// eslint-disable-line react-hooks/exhaustive-deps

    // calendar de fecha cumplimiento

    const [date, setDate] = useState(null);

    const onDateChange = (e) => {
        let fecha = e.value
        setDate(fecha)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }

    //Almacenamos los valores para enviarlos por props

    const idSelectProjects = selectedProject.id
    const idSelectObjective = selectedObjectives.id
    var idDeliverable
    var deliverable
    var creationDate
    var createdBy

    if (assingnedNewObjectiveDeliverable === false) {
        idDeliverable = null
        deliverable = inputDeliverable.deliverable
    } else if (assingnedNewObjectiveDeliverable === true) {
        idDeliverable = autoDeliverables.id
        deliverable = autoDeliverables.descripcion
        creationDate = autoDeliverables.fechacreacion
        createdBy = autoDeliverables.creadopor
    }

    // función para restablecer los datos del formulario

    const resetData = () => {
        if (individualMonthReportProp === false) {
            setSelectedClient(null)
            setSelectedProject({
                id: '',
                name: '',
                dateStartValidity: '',
                dateEndValidity: ''
            })
        }
        setAutoDeliverables(null)
        setSelectedObjectives({
            descriptionObjectives: '',
            id: ''
        })
        setInputDeliverable({
            deliverable: ''
        })
        /* setAssingnedProjects([]) */
        setDate(null)
        setPercentage(0)
        setValidateChange(false)
        setValidateErrorDuplicity(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Back = (name) => {
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
            resetData()
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const [showModal, setShowModal] = useState(false)

    const AddMoreObjetives = () => {
        const [displayBasic2, setDisplayBasic2] = useState(showModal);

        const dialogFuncMap = {
            'displayBasic2': setDisplayBasic2,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setRunRenderProp(runRenderProp + 1)
            if (setRunRenderBlockProp !== undefined) {
                setRunRenderBlockProp(runRenderBlockProp + 1)
            }
            setDisplayBasic(false)
            setDisplayBasic2(false)
            setShowModal(false)
            /*  resetData() */
        }

        const AddMore = (name) => {
            dialogFuncMap[`${name}`](false);
            setRunRenderProp(runRenderProp + 1)
            if (setRunRenderBlockProp !== undefined) {
                setRunRenderBlockProp(runRenderBlockProp + 1)
            }
            setShowModal(false);
            /*  resetData() */
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => AddMore(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>Información registrada satisfactoriamente ¿Desea registrar otro entregable?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    registerPermission ?
                        <ConfirmBtn
                            tittle="Confirmar Registro"
                            menssage={assingnedNewObjectiveDeliverable ? "¿Estás seguro de guardar esta información?" : "¿Está seguro de Crear este entregable?"}
                            action={assingnedNewObjectiveDeliverable ? "Update" : "Create"}
                            label={assingnedNewObjectiveDeliverable ? "Asignar" : "Guardar"}
                            icon="pi pi-plus-circle"
                            idProjectProp={idSelectProjects}
                            idObjectiveProp={idSelectObjective}
                            idDeliverableProp={idDeliverable}
                            deliverableProp={deliverable}
                            percentageProp={percentage}
                            dateProp={date === null ? date : CambiarFormatoFecha(date)}
                            setShowModal={setShowModal}
                            statusProp={1}
                            creationDateProp={creationDate}
                            createdByProp={createdBy}
                            assingnedNewObjectiveDeliverableProp={assingnedNewObjectiveDeliverable}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            deleteDetailProp={false}
                            setDisplayBasicProp={assingnedNewObjectiveDeliverable ? setDisplayBasic : undefined}
                            setNewResetDataProp={setNewResetData}
                        />

                        :
                        ""}

            </div>
        );
    }

    // validaciones

    var deliverableError = false
    var deliverableMessage = ''

    if (deliverable !== '') {
        if (deliverable.length < 10) {
            deliverableError = true
            deliverableMessage = 'La descripción del entregable es demasiado corta'
        } else if (deliverable.length > 110) {
            deliverableError = true
            deliverableMessage = 'La descripción del entregable supera el limite permitido de 110 caracteres'
        }
    }


    /*  const optionDisabledProjects = (e) => {
         var disabled = false
         assignedProjects.forEach(element => {
             if (parseInt(element.id) === e.id) {
                 disabled = true
             }
         })
         return disabled
     } */
    const [validateErrorDuplicity, setValidateErrorDuplicity] = useState(false)
    useEffect(() => {
        try {
            //Entregables en el informe mensual
            if (individualMonthReportProp === true) {
                if (newDeliverablesProp !== undefined && newDeliverablesProp !== null && newDeliverablesProp.length !== 0) {
                    let arrEqualsName = []
                    let arrEqualsProjects = []
                    newDeliverablesProp.forEach(element => {
                        let newNameComplete = removeAccents(element.descripcion)
                        let nameComplete = removeAccents(inputDeliverable.deliverable !== '' ? inputDeliverable.deliverable : autoDeliverables.descripcion)
                        if (nameComplete?.toLowerCase() === newNameComplete.toLowerCase()) {
                            arrEqualsName.push(element)
                        }
                    })
                    if (arrEqualsName.length !== 0) {
                        arrEqualsName.forEach(element => {
                            element.objetivos.forEach(i => {
                                if (i.proyecto.id === idProjectProp) {
                                    arrEqualsProjects.push(i.proyecto)
                                }
                            })
                        })
                    }
                    if (arrEqualsProjects.length !== 0) {
                        setValidateErrorDuplicity(true)
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Entregable pre-existente', life: 3000 });
                    } else {
                        setValidateErrorDuplicity(false)
                    }
                }
            } else {
                if (listDeliverableProp !== null && listDeliverableProp.length !== 0) {
                    let arrEqualsName = []
                    let arrEqualsProjects = []
                    listDeliverableProp.forEach(element => {
                        let newNameComplete = removeAccents(element.descripcion)
                        let nameComplete = removeAccents(inputDeliverable.deliverable !== '' ? inputDeliverable.deliverable : autoDeliverables.descripcion)
                        if (nameComplete.toLowerCase() === newNameComplete.toLowerCase()) {
                            arrEqualsName.push(element)
                        }
                    })
                    if (arrEqualsName.length !== 0) {
                        arrEqualsName.forEach(element => {
                            element.objetivos.forEach(i => {
                                if (i.proyecto.id === parseInt(selectedProject.id)) {
                                    arrEqualsProjects.push(i.proyecto)
                                }
                            })
                        })
                    }
                    if (arrEqualsProjects.length !== 0) {
                        setValidateErrorDuplicity(true)
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Entregable pre-existente', life: 3000 });
                    } else {
                        setValidateErrorDuplicity(false)
                    }
                }
            }
        } catch (error) {
            /* console.log(error); */
        }

    }, [selectedProject, inputDeliverable, autoDeliverables, idProjectProp])// eslint-disable-line react-hooks/exhaustive-deps


    var deliverableAutoCompleteError = false
    var deliverableAutoCompleteMessage = ''

    if (typeof autoDeliverables === 'string' || autoDeliverables !== null) {
        if (autoDeliverables.descripcion?.length > 110) {
            deliverableAutoCompleteError = true
            deliverableAutoCompleteMessage = 'El entregable ingresado supera los 110 caracteres'
        } else {
            deliverableAutoCompleteError = false
            deliverableAutoCompleteMessage = ''
        }
    }
    var dateError = false
    var dateMessage = ''

    // validar fechas dentro del rango del proyecto seleccionado

    const validateDate = () => {
        var newDate = CambiarFormatoFecha(date)
        if (newDate >= selectedProject.dateStartValidity && newDate <= selectedProject.dateEndValidity) {
            dateError = false
            dateMessage = ''
        } else {
            dateError = true
            dateMessage = 'La fecha de cumplimiento esta fuera del rango de fechas del proyecto seleccionado'
        }
    }

    if (date !== null && selectedProject.id !== '') {
        validateDate()
    }


    // validación para registrar

    var registerPermission = false

    if (deliverable !== '' && deliverableError === false && selectedObjectives.id !== '' && dateError === false
        && selectedProject !== null && selectedProject.id !== '' && validateErrorDuplicity === false) {
        registerPermission = true
    }

    return (

        <div className="">
            <Toast ref={toast} />
            <div>
                <button className="btnAdd" onClick={() => {
                    onClick('displayBasic')
                    resetData()
                }}><span className="pi pi-plus Add"></span>Gestionar entregables</button>
                <AddMoreObjetives />
                <Dialog className="modal" header="Gestionar entregables" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >

                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Buscar Entregable para asignar proyecto
                                    <Tooltip target=".custom-target-icon" autoHide={false} />
                                    <i className="custom-target-icon pi pi-question-circle" style={{ marginLeft: '5px' }}
                                        data-pr-tooltip="Antes de registrar un nuevo entregable debe buscar en la siguiente lista si este entregable ya esta creado, si es asi podrá asignar un nuevo proyecto, si no existe el entregable podrá crear uno nuevo"
                                        data-pr-position="right" mouseTrack mouseTrackLeft={10} data-pr-at="right+1 top" data-pr-my="left center+6"
                                        data-pr-classname="tooltip-objective"
                                    ></i>
                                </label>
                                <AutoComplete dropdown value={autoDeliverables} suggestions={filteredDeliverables} filterMatchMode="startsWith"
                                    completeMethod={searchDeliverable} onChange={onAutoDeliverablesChange} field="descripcion" placeholder="Buscar entregable"
                                    className={deliverableAutoCompleteError ? "auto p-invalid" : "auto"}
                                />
                                <p className="mensajeError">{deliverableAutoCompleteError ? deliverableAutoCompleteMessage : ""}</p>
                            </div>
                            {assingnedNewObjectiveDeliverable ?
                                <div className="p-field p-col-12 p-md-12">
                                    <label className="labels" >
                                        {individualMonthReportProp ?
                                            `Se asignará el proyecto: ${selectedProject.name} al entregable: ${autoDeliverables.descripcion}`
                                            :
                                            `Seleccione un nuevo proyecto para asignarle al entregable: ${autoDeliverables.descripcion}`
                                        }
                                    </label>
                                </div>
                                :
                                ""
                            }
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Cliente<span className="require">*</span></label>
                                <Dropdown value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                    className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="name"
                                    disabled={individualMonthReportProp} filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Proyecto<span className="require">*</span></label>
                                <Dropdown value={selectedProject} options={proyectos} onChange={onProjectsChange} name="proyecto" optionLabel="name"
                                    placeholder="Seleccione un proyecto" disabled={individualMonthReportProp} filter
                                    emptyMessage='No hay resultados' className={dateValidError ? "p-invalid" : ""} /* optionDisabled={optionDisabledProjects} */
                                />
                                <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                            </div>
                            <div className={assingnedNewObjectiveDeliverable ? "p-field p-col-12 p-md-12" : "p-field p-col-12 p-md-6"}>
                                <label className="labels" >Objetivo<span className="require">*</span>
                                    {individualMonthReportProp ?
                                        ""
                                        :
                                        <>
                                            <Tooltip target=".custom-target-icon" autoHide={false} />
                                            <i className="custom-target-icon pi pi-question-circle" style={{ marginLeft: '5px' }}
                                                data-pr-tooltip="Los objetivos deshabilitados de la lista ya están asignados al entregable"
                                                data-pr-position="right" mouseTrack mouseTrackLeft={10} data-pr-at="right+1 top" data-pr-my="left center+6"
                                                data-pr-classname="tooltip-objective"
                                            ></i>
                                        </>
                                    }
                                </label>
                                <Dropdown value={selectedObjectives} options={objectives} onChange={onObjectivesChange} name="objetivo" optionLabel="descriptionObjectives"
                                    placeholder="Seleccione un objetivo" emptyMessage='No hay resultados' filter
                                />
                            </div>
                            {assingnedNewObjectiveDeliverable ?
                                ""
                                :
                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" >Entregable<span className="require">*</span></label>
                                    <InputText type='text' value={deliverable} onChange={onDeliverableChange} name="deliverable" placeholder="Ingrese un entregable"
                                        className={deliverableError ? "p-invalid" : ""}
                                    />
                                    <p className="mensajeError">{deliverableError ? deliverableMessage : ""}</p>
                                </div>
                            }
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Fecha Cumplimiento</label>
                                <Calendar placeholder="Seleccione una fecha" value={date} onChange={onDateChange} dateFormat="dd-mm-yy" locale="es"
                                    className={dateError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{dateError ? dateMessage : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Porcentaje cumplimiento</label>
                                <h5 style={{ marginBottom: '13px' }}> {percentage} %</h5>
                                <Slider value={percentage} onChange={onPercentageChange} step={5} />
                            </div>
                        </form>

                    </div>
                </Dialog>
            </div>
        </div>
    )

}
export default BtnCrearEntregable