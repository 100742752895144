import React, { useState, useEffect, useRef } from 'react'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import { Toast } from "primereact/toast";
import '../assets/css/Sidebar.css'
import Block1 from '../componets/InformeMensualIndividual/Block1'
import Block2 from '../componets/InformeMensualIndividual/Block2'
import Block3 from '../componets/InformeMensualIndividual/Block3'
import Block4 from '../componets/InformeMensualIndividual/Block4'
import Block5 from '../componets/InformeMensualIndividual/Block5'
import Block6 from '../componets/InformeMensualIndividual/Block6'
import Block7 from '../componets/InformeMensualIndividual/Block7'
import Block8 from '../componets/InformeMensualIndividual/Block8';
import Block9 from '../componets/InformeMensualIndividual/Block9';
import Services from '../service/Services'
import DataTableService from '../componets/Servicios/DataTable'
import ConfirmBtn from '../componets/InformeMensualIndividual/ConfirmBtn'
import { CambiarFormatoFecha } from '../componets/Funciones'
import Mensaje from '../componets/ConsultarActividades/Mensaje';
import axios from 'axios';
import { CalculateHoursPeriod, getData, getHolidays } from '../componets/InformeMensualIndividual/CalculateHoursPeriod';
import { SumarDias, ultimoDiaHabil } from '../componets/Funciones';
import Cookies from 'universal-cookie';
import { Message } from 'primereact/message';
import Observaciones from '../componets/Observaciones/Observaciones';
import BlockActivity from '../componets/InformeMensualIndividual/BlockActivity';
const cookies = new Cookies();

const InformeMensualIndividual = () => {
    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

    const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol
    if (permisos[15].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Informe mensual individual</span>
    )

    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);
    const [getSelectedClient, setGetSelectedClient] = useState(null)
    const [period, setPeriod] = useState(null);
    const [dateStartDB, setDateStartDB] = useState(null);
    const [dateEndDB, setDateEndDB] = useState(null);
    const [projectAssignments, setProjectAssignments] = useState([]);
    const [observations, setObservations] = useState('');
    const [objectMonthlyReportData, setObjectMonthlyReportData] = useState();
    const [newObjectMonthlyReportData, setNewObjectMonthlyReportData] = useState(null);
    const [errorObjectMonthlyReportData, setErrorObjectMonthlyReportData] = useState(false);
    const [clearData, setClearData] = useState(0)
    const [monthlyReport, setMonthlyReport] = useState('ownMonthlyReport')
    const [userSelected, setUserSelected] = useState({
        id: '',
        name: ''
    })
    const [projectsOfficial, setProjectsOfficial] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const [selectedObjective, setSelectedObjective] = useState([]);
    const [selectedInterestedPerson, setSelectedInterestedPerson] = useState([]);
    const [selectedAchievement, setSelectedAchievement] = useState([]);
    const [selectedDeliverable, setSelectedDeliverable] = useState([]);
    const [chartData, setChartData] = useState([])
    const [dataOfficial, setDataOfficial] = useState([])
    const [dataUser, setDataUser] = useState([])
    const [validateObjectiveNotCurrent, setValidateObjectiveNotCurrent] = useState(false)
    const getService = new Services();
    const idUsuario = cookies.get('idUsuario')
    const toast = useRef(null);

    const [runRender, setRunRender] = useState(0)
    const [clientChange, setClientChange] = useState(0)
    const [reloadObjective, setReloadObjective] = useState(0)
    const [runRender2, setRunRender2] = useState(0)
    const [reload, setReload] = useState(0)
    const [sendPDF, setSendPDF] = useState(false);
    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [allData, setAllData] = useState([]);
    const [showAllData, setShowAllData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listAchievement, setListAchievement] = useState([]);
    const [listDeliverable, setListDeliverable] = useState([]);
    const [listInterestedPerson, setListInterestedPerson] = useState([]);
    const [listService, setListService] = useState([]);
    const [monthlyReportDataAllData, setMonthlyReportDataAllData] = useState([]);
    const [servicesObjectives, setServicesObjectives] = useState([]);
    const [servicesWorkTeam, setServicesWorkTeam] = useState([]);
    const [servicesClientsProjectUsers, setServicesClientsProjectUsers] = useState([]);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        getService.getClientesProyectosUsuario().then(data => {
            if (data !== undefined) {
                setServicesClientsProjectUsers(data)
            }
        });
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const getMonthlyReportDataAllData = async (source) => {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/MonthlyReportData/MonthlyReportData/allDataUsers'
        await axios.post(url,
            null,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                cancelToken: source?.token
            })
            .then(response => {
                if (response !== undefined) {
                    setMonthlyReportDataAllData(response.data)
                }
            })
            .catch(function (error) {
                //console.log(error.response)
            })
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (monthlyReport === 'ownMonthlyReport') {
            try {
                if (dateStartDB !== null && dateEndDB !== null) {
                    const dateStartFormat = CambiarFormatoFecha(dateStartDB)
                    const dateEndFormat = CambiarFormatoFecha(dateEndDB)
                    const idClient = getSelectedClient.id
                    getService.getMonthlyReportData(idUsuario, idClient, dateStartFormat, dateEndFormat).then(data => {
                        setObjectMonthlyReportData(data)
                        setLoading(false)
                        if (data !== undefined) {
                            setNewObjectMonthlyReportData(data)
                            setClients({
                                client: data.cliente.razonsocial,
                                id: data.cliente.id
                            })
                        } else {
                            setNewObjectMonthlyReportData(undefined)
                            setClients({
                                client: getSelectedClient.cliente.razonsocial,
                                id: getSelectedClient.cliente.id
                            })
                            setProjects([])
                        }
                    }).catch(err => {
                        /* console.log(err); */
                    })
                }
            } catch (error) {
                /*  console.log(error); */
            }
        }
        getMonthlyReportDataAllData(source)

        return () => {
            if (source) {
                source.cancel()
            }
        }
    }, [runRender, monthlyReport, sendPDF, getSelectedClient, dateStartDB, dateEndDB]) // eslint-disable-line react-hooks/exhaustive-deps

    const [listReportsUsers, setListReportsUser] = useState([]);
    const [reportsUserActive, setReportUserActive] = useState([]);
    const [validateOpenMonth, setValidateOpenMonth] = useState(false)

    useEffect(() => {
        if (monthlyReport === 'ownMonthlyReport') {
            if (dateStartDB !== null && dateEndDB !== null) {
                getService.getAllMonthlyReportData(idUsuario).then(data => {
                    if (data !== undefined) {

                        var newDateStart = CambiarFormatoFecha(dateStartDB)
                        var newDateEnd = CambiarFormatoFecha(dateEndDB)
                        var filterClient = data.filter(obj => obj.cliente.id === clients.id)
                        var dataFilter = filterClient.filter(function (object) {
                            var status = object.estado
                            return parseInt(status) === 3
                        })
                        var newData = dataFilter.filter(function (object) {
                            var date = JSON.parse(object.periodo)
                            return date.dateStart === newDateStart && date.dateEnd === newDateEnd
                        })
                        if (newData.length !== 0) {
                            toast.current.show({ severity: 'success', summary: 'Consulta Satisfactoria', detail: '', life: 6000 });
                            if (newData[0].estado === 1) {
                                /* setObjectMonthlyReportData(newData[0]) */
                            } else {
                                setListReportsUser(newData)
                            }
                        } else {
                            setListReportsUser([])
                            /* if (changes === true) {
                                toast.current.show({ severity: 'error', summary: 'No se encontraron datos', detail: 'para este cliente y periodo seleccionados', life: 6000 });
                                
                            } */
                        }
                    }
                })
            }
        } else {
            if (dateStartDB !== null && dateEndDB !== null) {
                if (userSelected.id !== '' && projectsOfficial.length !== 0) {
                    getService.getAllMonthlyReportData(userSelected.id).then(data => {
                        if (data !== undefined) {
                            var newDateStart = CambiarFormatoFecha(dateStartDB)
                            var newDateEnd = CambiarFormatoFecha(dateEndDB)
                            var newData = data.filter(function (object) {
                                var date = JSON.parse(object.periodo)
                                return date.dateStart === newDateStart && date.dateEnd === newDateEnd
                                    && object.cliente.id === getSelectedClient.id
                            })
                            var reportsActive = []
                            newData.forEach(element => {
                                if (element.estado === 1) {
                                    setValidateOpenMonth(true)
                                } else {
                                    setValidateOpenMonth(false)
                                }
                            });
                            setReportUserActive(reportsActive)
                            setListReportsUser(newData)
                            if (newData.length !== 0) {
                                toast.current.show({ severity: 'success', summary: 'Consulta Satisfactoria', detail: '', life: 6000 });
                            } else {
                                toast.current.show({ severity: 'error', summary: 'No se encontraron datos', detail: 'para este cliente y periodo seleccionados', life: 6000 });
                            }
                        }
                    })
                } else {
                    setListReportsUser([])
                    setReportUserActive([])
                }
            }
        }
    }, [userSelected, dateStartDB, dateEndDB, projectsOfficial, monthlyReport, reload, sendPDF, getSelectedClient]) // eslint-disable-line react-hooks/exhaustive-deps

    const user = cookies.get('usuario')
    const nameUser = cookies.get('nombre')
    const [obj, setObj] = useState({})

    useEffect(() => {

        let filterbjective = []
        let filterInterestedPerson = []
        let filterAchievements = []
        let filterDeliverables = []

        projects.forEach(e => {

            selectedObjective.forEach(item => {
                if (parseInt(e.id) === parseInt(item.proyecto)) {
                    filterbjective.push(item)
                }
            })

            selectedInterestedPerson.forEach(item => {
                if (parseInt(e.id) === parseInt(item.proyecto)) {
                    filterInterestedPerson.push(item)
                }
            })

            selectedDeliverable.forEach(item => {
                if (parseInt(e.id) === parseInt(item.proyecto)) {
                    filterDeliverables.push(item)
                }
            })

            selectedAchievement.forEach(item => {
                if (parseInt(e.id) === parseInt(item.proyecto)) {
                    filterAchievements.push(item)
                }
            })

        })

        setObj({
            id: objectMonthlyReportData !== undefined ? objectMonthlyReportData.id : undefined,
            cliente: clients,
            periodo: JSON.stringify({
                dateStart: dateStartDB !== null ? CambiarFormatoFecha(dateStartDB) : dateStartDB,
                dateEnd: dateEndDB !== null ? CambiarFormatoFecha(dateEndDB) : dateEndDB,
                period: dateEndDB !== null ? CambiarFormatoFecha(dateEndDB) : dateEndDB,
            }),
            asignaciones: /* JSON.stringify( */projectAssignments/* ) */,
            observaciones: observations,
            servicios: JSON.stringify(selectedService),
            objetivos: JSON.stringify(filterbjective?.map(item => {
                return {
                    id: item.id,
                    proyecto: item.proyecto
                }
            })),
            personasinteresadas: JSON.stringify(filterInterestedPerson),
            logros: JSON.stringify(filterAchievements),
            entregables: JSON.stringify(filterDeliverables),
            creadopor: objectMonthlyReportData !== undefined ? objectMonthlyReportData.creadopor : user,
            fechacreacion: objectMonthlyReportData !== undefined ? objectMonthlyReportData.fechacreacion : new Date(),
            usuario: objectMonthlyReportData?.usuario,
            validaciones: objectMonthlyReportData !== undefined ? objectMonthlyReportData.validaciones : null
        })
    }, [objectMonthlyReportData, clients, period, dateStartDB, dateEndDB, projectAssignments, observations, selectedService, selectedObjective, selectedInterestedPerson,
        selectedAchievement, selectedDeliverable, user, monthlyReport, reload, projects])


    const [data, setData] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [pendingHours, setPendingHours] = useState(null);
    const [servicioCalendario, setServicioCalendario] = useState([])
    useEffect(() => {
    let isMounted = true

        if (dateStartDB !== null && dateEndDB !== null) {
            if (objectMonthlyReportData !== undefined) {
                getData(CambiarFormatoFecha(dateStartDB), CambiarFormatoFecha(dateEndDB), user, setData);
                getHolidays(CambiarFormatoFecha(dateStartDB), CambiarFormatoFecha(dateEndDB), setHolidays);
                getService.getCalendarios().then(data => {
                    if(isMounted){
                    let arrayFestivos = []
                    var resultFestivos = data.filter(function (objeto) {
                        return objeto.pais === 'Colombia'
                    })
                    if (resultFestivos) {
                        arrayFestivos = resultFestivos.flatMap(element => element.fechas.split(','));
                    }
                    setServicioCalendario(arrayFestivos)
                } return ()=> {
                    isMounted = false
                }
                })
            }
        }

    }, [dateStartDB, dateEndDB, objectMonthlyReportData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var dateOfAdmission = cookies.get('fechaIngreso')
        
        if (data.length !== 0 && servicioCalendario.length!==0) {
            if (dateStartDB !== null && dateEndDB !== null) {
                if (dateOfAdmission > CambiarFormatoFecha(dateStartDB)) {
                    var date = SumarDias(new Date(dateOfAdmission), 1)
                    
                    setPendingHours(CalculateHoursPeriod(date, dateEndDB, data, servicioCalendario));
                } else {
                    setPendingHours(CalculateHoursPeriod(dateStartDB, dateEndDB, data, servicioCalendario));
                }
            }

        }
    }, [data, holidays, dateStartDB, dateEndDB, servicioCalendario]); // eslint-disable-line react-hooks/exhaustive-deps

    var showFinishBtn = false
    var messageSave = ''
    var noSaved = false
    let missingHours = false
    //Función que le indica al usuario en qué bloque le falta guardar al usuario
    const showMessagesBlock = () => {
        if (objectMonthlyReportData.asignaciones === "[]") {
            messageSave = 'Señor usuario debe guardar las asignaciones'.split(",")
            noSaved = true
        } else if (objectMonthlyReportData.servicios === "[]") {
            messageSave = 'Señor usuario debe guardar los servicios'.split(",")
            noSaved = true
        } else if (objectMonthlyReportData.objetivos === "[]") {
            messageSave = 'Señor usuario debe guardar los objetivos'.split(",")
            noSaved = true
        } else if (objectMonthlyReportData.personasinteresadas === "[]") {
            messageSave = 'Señor usuario debe guardar las personas interesadas'.split(",")
            noSaved = true
        } else if (objectMonthlyReportData.logros === "[]" || objectMonthlyReportData.entregables === "[]") {
            messageSave = 'Señor usuario debe guardar logros o entregables'.split(",")
            noSaved = true
        }
    }

    if (monthlyReport === 'ownMonthlyReport') {

        if (dateEndDB !== null) {
            if (objectMonthlyReportData !== undefined) {
                if (objectMonthlyReportData.validaciones !== null && JSON.parse(objectMonthlyReportData.validaciones).anticiparbtn === true) {
                    if ((objectMonthlyReportData.asignaciones !== "[]" && objectMonthlyReportData.servicios !== "[]"
                        && objectMonthlyReportData.objetivos !== "[]" && objectMonthlyReportData.personasinteresadas !== "[]")
                        && (objectMonthlyReportData.logros !== "[]" || objectMonthlyReportData.entregables !== "[]")) {
                        showFinishBtn = true
                    } else {
                        showMessagesBlock()
                    }
                } else {
                    // Obtener el último día hábil del mes del informe
                    const today = dateEndDB;
                    const ultimoDia = ultimoDiaHabil(today.getFullYear(), today.getMonth(), holidays);
                    /* console.log(pendingHours); */
                    if (CambiarFormatoFecha(new Date()) >= CambiarFormatoFecha(ultimoDia)) {
                        if ((objectMonthlyReportData.asignaciones !== "[]" && objectMonthlyReportData.servicios !== "[]"
                            && objectMonthlyReportData.objetivos !== "[]" && objectMonthlyReportData.personasinteresadas !== "[]") && (objectMonthlyReportData.logros !== "[]" || objectMonthlyReportData.entregables !== "[]")) {
                            if (pendingHours !== null) {
                                if (pendingHours <= 0) {
                                    showFinishBtn = true
                                } else {
                                    missingHours = true
                                    showFinishBtn = false
                                }
                            } else {
                                messageSave = 'Señor usuario aún no ha registrado las actividades de este periodo'
                                noSaved = true
                            }
                        } else {
                            showMessagesBlock()
                        }

                    }
                }
            }
        }
    }


    /*   console.log(objectMonthlyReportData); */
    var readReport = false
    if (listReportsUsers.length !== 0) {
        readReport = true
    } else {
        readReport = false
    }

    var showSections = true
    if (monthlyReport === 'monthlyReportOtherOfficial' || monthlyReport === 'allMonthlyReportOfOfficial') {
        showSections = false
        if (showAllData === true) {
            showSections = true
        } else {
            if (readReport === true) {
                showSections = true
            }
        }
    }

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">
                    <h1 >Informe mensual individual</h1>
                    <span className="subtittle">A continuación, resumimos el estado de las principales actividades desarrolladas y logros significativos que se obtuvieron de manera individual para el rol asignado, y así poder entregar a los clientes un informe de lo contratado.</span>
                </div>
                <Toast ref={toast} />
                {sendPDF ?
                    <Mensaje
                        validarProp={true}
                        enviarMensajeProp="Desea enviar el informe mensual individual generado a Dropbox"
                        exportPDFProp={true}
                        setSendPDFProp={setSendPDF}
                    />
                    :
                    null
                }
                <div className="tabla">
                    <Block1
                        permissionsProp={permisos}
                        setDateStartDBProp={setDateStartDB}
                        setDateEndDBProp={setDateEndDB}
                        setClientsProp={setClients}
                        setClientsProp2={setGetSelectedClient}
                        objectMonthlyReportDataProp={newObjectMonthlyReportData}
                        clientChangeProp={clientChange}
                        setClientChangeProp={setClientChange}
                        setPeriodProp={setPeriod}
                        setClearDataProp={setClearData}
                        clearDataProp={clearData}
                        setProjectsOfficialProp={setProjectsOfficial}
                        setMonthlyReportProp={setMonthlyReport}
                        setUserSelectedProp={setUserSelected}
                        setDataUserProp={setDataUser}
                        setReloadProp={setReload}
                        reloadProp={reload}
                        setAllDataProp={setAllData}
                        setShowAllDataProp={setShowAllData}
                        setLoadingProp={setLoading}
                        permissionProp={permisos[15]}
                        readReportProp={readReport}
                        listReportsUsersProp={listReportsUsers}
                        reportsUserActiveProp={reportsUserActive}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                        validateOpenMonthProp={validateOpenMonth}
                        setServicesWorkTeamProp={setServicesWorkTeam}
                        servicesClientsProjectUsersProp={servicesClientsProjectUsers}
                        getSelectedClientProp={getSelectedClient}
                    /* setChagesProp={setChages} */
                    />
                </div>
                {showSections ?
                    <>
                        {showAllData ?
                            null
                            :
                            <>
                                <div className="tabla">
                                    <Block2
                                        setProjectsProp={setProjects}
                                        getClients={clients}
                                        setProjectAssignmentsProp={setProjectAssignments}
                                        objectMonthlyReportDataProp={objectMonthlyReportData}
                                        clientChangeProp={clientChange}
                                        setClearDataProp={setClearData}
                                        clearDataProp={clearData}
                                        projectsOfficialProp={projectsOfficial}
                                        monthlyReportProp={monthlyReport}
                                        userSelectedProp={userSelected}
                                        readReportProp={readReport}
                                        listReportsUsersProp={listReportsUsers}
                                        dataUserProp={dataUser}
                                        setProjectsUserSessionProp={setProjectsUserSession}
                                        dateStartRangeProp={dateStartDB}
                                        dateEndRangeProp={dateEndDB}
                                        loadingProp={loading}
                                        setShowErrorProp={setShowError}
                                    />
                                    {projects.length !== 0 ?
                                        readReport ?
                                            null
                                            :
                                            loading === false && showError === false &&
                                            <div className="btn-report">
                                                <ConfirmBtn
                                                    tittle="Guardar datos informe mensual"
                                                    menssage="¿Estás seguro de guardar esta información?"
                                                    action={"Update"}
                                                    label="Guardar"
                                                    icon="pi pi-save"
                                                    classProp="btnAdd"
                                                    dataProp={obj}
                                                    statusProp={1}
                                                    setRunRenderProp={setRunRender}
                                                    runRenderProp={runRender}
                                                />
                                            </div>
                                        :
                                        null
                                    }
                                </div>
                                <div className="tabla">
                                    <div >
                                        <h3 className="labels" style={{ marginBottom: '10px' }}>Gestión de servicios</h3>
                                    </div>
                                    <DataTableService
                                        permissionsProp={permisos}
                                        fullTableProp={false}
                                        individualMonthReportProp={true}
                                        clientsProp={clients}
                                        monthlyReportProp={monthlyReport}
                                        permissionReportMonthlyOtherOfficialsProp={true}
                                        setSelectedServiceProp={setSelectedService}
                                        objectMonthlyReportDataProp={objectMonthlyReportData}
                                        readReportProp={readReport}
                                        listReportsUsersProp={listReportsUsers}
                                        monthlyReportDataAllDataProp={monthlyReportDataAllData}
                                        setListServiceProp={setListService}
                                        loadingProp={loading}
                                    />
                                    {selectedService !== null && selectedService.length !== 0 ?
                                        readReport ?
                                            null
                                            :
                                            loading === false &&
                                            <div className="btn-report">
                                                <ConfirmBtn
                                                    tittle="Guardar datos informe mensual"
                                                    menssage="¿Estás seguro de guardar esta información?"
                                                    action={"Update"}
                                                    label="Guardar"
                                                    icon="pi pi-save"
                                                    classProp="btnAdd"
                                                    dataProp={obj}
                                                    statusProp={1}
                                                    setRunRenderProp={setRunRender}
                                                    runRenderProp={runRender}
                                                />
                                            </div>
                                        :
                                        null
                                    }
                                </div>
                                {
                                    monthlyReport === 'ownMonthlyReport' && readReport===false ?
                                        <div className="tabla">
                                            <div >
                                                <h3 className="labels" style={{ marginBottom: '10px' }}>Gestión de Asociar Actividades</h3>
                                            </div>
                                            <BlockActivity
                                                dateStartProp={dateStartDB}
                                                dateEndProp={dateEndDB}
                                                idSelectedClientProp={clients?.id}
                                                loadingProp={loading}
                                                selectedProyectoProp={projects}
                                                setRunRenderProp2={setRunRender2}
                                            />

                                        </div>
                                        :
                                        ''
                                }

                            </>
                        }

                        <div className="tabla">
                            <Block8
                                getProjectsProp={projects}
                                getClientsProp={clients}
                                permissionsProp={permisos}
                                individualMonthReportProp={true}
                                setSelectedObjectiveProp={setSelectedObjective}
                                selectedObjectiveProp={selectedObjective}
                                objectMonthlyReportDataProp={objectMonthlyReportData}
                                readReportProp={readReport}
                                listReportsUsersProp={listReportsUsers}
                                reloadObjectiveProp={reloadObjective}
                                setReloadObjectiveProp={setReloadObjective}
                                runRenderProp={runRender}
                                listAchievementProp={listAchievement}
                                listDeliverableProp={listDeliverable}
                                monthlyReportDataAllDataProp={monthlyReportDataAllData}
                                selectedAchievementProp={selectedAchievement}
                                selectedDeliverableProp={selectedDeliverable}
                                loadingProp={loading}
                                dateStartMonthlyReportProp={dateStartDB}
                                dateEndMonthlyReportProp={dateEndDB}
                                setValidateObjectiveNotCurrentProp={setValidateObjectiveNotCurrent}
                                setServicesObjectivesProp={setServicesObjectives}
                                showAllDataProp={showAllData}
                                servicesWorkTeamProp={servicesWorkTeam}
                                allDataProp={allData}
                                servicesClientsProjectUsersProp={servicesClientsProjectUsers}
                                projectAssignmentsProp={projectAssignments}
                                dateStartPeriodProp={dateStartDB}
                                dateEndPeriodProp={dateEndDB}
                                runRenderProp2={runRender2}
                            />
                            {
                                showAllData ? null
                                    :
                                    (objectMonthlyReportData !== undefined && objectMonthlyReportData.objetivos !== "[]") || (selectedObjective !== null && selectedObjective.length !== 0 && validateObjectiveNotCurrent === false && projectAssignments.length !== 0) ?
                                        readReport ?
                                            null :
                                            loading === false &&
                                            < div className="btn-report">
                                                <ConfirmBtn
                                                    tittle="Guardar datos informe mensual"
                                                    menssage="¿Estás seguro de guardar esta información?"
                                                    action={"Update"}
                                                    label="Guardar"
                                                    icon="pi pi-save"
                                                    classProp="btnAdd"
                                                    dataProp={obj}
                                                    statusProp={1}
                                                    setRunRenderProp={setRunRender}
                                                    runRenderProp={runRender}
                                                />
                                            </div>
                                        :
                                        null
                            }
                        </div>

                        <div className="tabla">
                            <Block3
                                getProjectsProp={projects}
                                getClientsProp={clients}
                                permissionsProp={permisos}
                                setSelectedInterestedPersonProp={setSelectedInterestedPerson}
                                selectedInterestedPersonProp={selectedInterestedPerson}
                                objectMonthlyReportDataProp={objectMonthlyReportData}
                                readReportProp={readReport}
                                listReportsUsersProp={listReportsUsers}
                                runRenderProp={runRender}
                                allDataProp={allData}
                                showAllDataProp={showAllData}
                                monthlyReportProp={monthlyReport}
                                loadingProp={loading}
                                monthlyReportDataAllDataProp={monthlyReportDataAllData}
                                setListInterestedPersonProp={setListInterestedPerson}
                                servicesWorkTeamProp={servicesWorkTeam}
                                periodDateStartProp={dateStartDB}
                                periodDateEndProp={dateEndDB}
                                servicesClientsProjectUsersProp={servicesClientsProjectUsers}
                                projectAssignmentsProp={projectAssignments}
                            />
                            {showAllData ?
                                null
                                :
                                (objectMonthlyReportData !== undefined && objectMonthlyReportData.personasinteresadas !== "[]") || (selectedInterestedPerson !== null && selectedInterestedPerson.length !== 0 && projectAssignments.length !== 0) ?
                                    readReport ?
                                        null
                                        :
                                        loading === false &&
                                        <div className="btn-report">
                                            <ConfirmBtn
                                                tittle="Guardar datos informe mensual"
                                                menssage="¿Estás seguro de guardar esta información?"
                                                action={"Update"}
                                                label="Guardar"
                                                icon="pi pi-save"
                                                classProp="btnAdd"
                                                dataProp={obj}
                                                statusProp={1}
                                                setRunRenderProp={setRunRender}
                                                runRenderProp={runRender}
                                            />
                                        </div>
                                    :
                                    null
                            }

                        </div>
                        <div className="tabla">
                            <Block4
                                getProjectsProp={projects}
                                individualMonthReportProp={true}
                                setSelectedAchievementProp={setSelectedAchievement}
                                selectedAchievementProp={selectedAchievement}
                                objectMonthlyReportDataProp={objectMonthlyReportData}
                                readReportProp={readReport}
                                listReportsUsersProp={listReportsUsers}
                                reloadObjectiveProp={reloadObjective}
                                runRenderProp={runRender}
                                allDataProp={allData}
                                showAllDataProp={showAllData}
                                loadingProp={loading}
                                setListAchievementProp={setListAchievement}
                                monthlyReportDataAllDataProp={monthlyReportDataAllData}
                                selectedObjectiveProp={selectedObjective}
                                dateStartMonthlyReportProp={dateStartDB}
                                dateEndMonthlyReportProp={dateEndDB}
                                servicesObjectivesProp={servicesObjectives}
                                servicesWorkTeamProp={servicesWorkTeam}
                                servicesClientsProjectUsersProp={servicesClientsProjectUsers}
                                projectAssignmentsProp={projectAssignments}
                                getClientsProp={clients}
                            />
                            {showAllData ?
                                null
                                :

                                readReport ?
                                    null :
                                    loading === false && ((objectMonthlyReportData !== undefined && objectMonthlyReportData.logros !== "[]") || (selectedAchievement !== null && selectedAchievement.length !== 0 && projectAssignments.length !== 0)) &&
                                    <div className="btn-report">
                                        <ConfirmBtn
                                            tittle="Guardar datos informe mensual"
                                            menssage="¿Estás seguro de guardar esta información?"
                                            action={"Update"}
                                            label="Guardar"
                                            icon="pi pi-save"
                                            classProp="btnAdd"
                                            dataProp={obj}
                                            statusProp={1}
                                            setRunRenderProp={setRunRender}
                                            runRenderProp={runRender}
                                        />
                                    </div>
                            }
                        </div>
                        <div className="tabla">
                            <Block9
                                getProjectsProp={projects}
                                getClientsProp={clients}
                                permissionsProp={permisos}
                                setSelectedDeliverableProp={setSelectedDeliverable}
                                selectedDeliverableProp={selectedDeliverable}
                                objectMonthlyReportDataProp={objectMonthlyReportData}
                                readReportProp={readReport}
                                listReportsUsersProp={listReportsUsers}
                                reloadObjectiveProp={reloadObjective}
                                runRenderProp={runRender}
                                allDataProp={allData}
                                showAllDataProp={showAllData}
                                loadingProp={loading}
                                setListDeliverableProp={setListDeliverable}
                                monthlyReportDataAllDataProp={monthlyReportDataAllData}
                                selectedObjectiveProp={selectedObjective}
                                servicesObjectivesProp={servicesObjectives}
                                servicesWorkTeamProp={servicesWorkTeam}
                                servicesClientsProjectUsersProp={servicesClientsProjectUsers}
                                projectAssignmentsProp={projectAssignments}
                                dateStartPeriodProp={dateStartDB}
                                dateEndPeriodProp={dateEndDB}
                            />
                            {showAllData ?
                                null
                                :
                                (objectMonthlyReportData !== undefined && objectMonthlyReportData.entregables !== "[]") || (selectedDeliverable !== null && selectedDeliverable.length !== 0 && projectAssignments.length !== 0) ?
                                    readReport ?
                                        null
                                        :
                                        loading === false &&
                                        <div className="btn-report">
                                            <ConfirmBtn
                                                tittle="Guardar datos informe mensual"
                                                menssage="¿Estás seguro de guardar esta información?"
                                                action={"Update"}
                                                label="Guardar"
                                                icon="pi pi-save"
                                                classProp="btnAdd"
                                                dataProp={obj}
                                                statusProp={1}
                                                setRunRenderProp={setRunRender}
                                                runRenderProp={runRender}
                                            />
                                        </div>
                                    :
                                    null
                            }
                        </div>

                        {
                            showAllData ?
                                <div className="tabla">
                                    <Observaciones
                                        showAllDataProp={showAllData}
                                        loadingProp={loading}
                                        servicesWorkTeamProp={servicesWorkTeam}
                                        allDataProp={allData}
                                        dateStartPeriodProp={dateStartDB}
                                        dateEndPeriodProp={dateEndDB}
                                        getClientsProp={clients}
                                    />
                                </div>

                                :
                                ''
                        }

                        {showAllData ?
                            null
                            :
                            <>
                                <div className="tabla">
                                    <Block5
                                        dateStartDBProp={dateStartDB !== null ? CambiarFormatoFecha(dateStartDB) : dateStartDB}
                                        dateEndDBProp={dateEndDB !== null ? CambiarFormatoFecha(dateEndDB) : dateEndDB}
                                        getProjectsProp={projects}
                                        setChartDataProp={setChartData}
                                        setDataOfficialProp={setDataOfficial}
                                        individualMonthReportProp={true}
                                        readReportProp={readReport}
                                        userSelectedProp={userSelected}
                                        setDataUserProp={setDataUser}
                                        monthlyReportProp={monthlyReport}
                                        listReportsUsersProp={listReportsUsers}
                                        projectsUserSessionProp={projectsUserSession}
                                        setClients={clients}
                                        setLoadingProp={setLoading}
                                    />
                                </div>
                                <div className="tabla">
                                    <Block6
                                        clientsProp={clients}
                                        dateStartProp={dateStartDB}
                                        dateEndProp={dateEndDB}
                                        periodProp={period}
                                        setObservationsProp={setObservations}
                                        objectMonthlyReportDataProp={objectMonthlyReportData}
                                        errorObjectMonthlyReportDataProp={errorObjectMonthlyReportData}
                                        setErrorObjectMonthlyReportDataProp={setErrorObjectMonthlyReportData}
                                        clearDataProp={clearData}
                                        setRunRenderProp={setRunRender}
                                        runRenderProp={runRender}
                                        readReportProp={readReport}
                                        listReportsUsersProp={listReportsUsers}
                                    />
                                    {
                                        errorObjectMonthlyReportData === false && loading === false && !readReport ?
                                            ((objectMonthlyReportData !== undefined && objectMonthlyReportData.observaciones !== "") || observations !== '') ?
                                                <div className="btn-report">
                                                    <ConfirmBtn
                                                        tittle="Guardar datos informe mensual"
                                                        menssage="¿Estás seguro de guardar esta información?"
                                                        action={"Update"}
                                                        label="Guardar"
                                                        icon="pi pi-save"
                                                        classProp="btnAdd"
                                                        dataProp={obj}
                                                        statusProp={1}
                                                        setRunRenderProp={setRunRender}
                                                        runRenderProp={runRender}
                                                    />
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                    }
                                    {
                                        missingHours ?
                                            <Message severity="error" text={`Señor usuario tiene ${pendingHours} horas pendientes por registrar en actividades`} />
                                            :
                                            ''
                                    }
                                    <br></br>
                                    {
                                        noSaved === true ?
                                            <Message severity="error" text={messageSave} />
                                            :
                                            ''
                                    }
                                    <br></br>
                                    <div className="btn-report">
                                        {showFinishBtn ?
                                            readReport ?
                                                null
                                                :
                                                loading ?
                                                    <span className="text">Cargando datos de informe...</span>
                                                    :
                                                    <ConfirmBtn
                                                        tittle="Exportar informe mensual"
                                                        menssage="¿Estás seguro de exportar este informe?"
                                                        action={"Update"}
                                                        label="Finalizar"
                                                        icon="pi pi-file-pdf"
                                                        classProp="btnAdd"
                                                        dataProp={obj}
                                                        statusProp={3}
                                                        setRunRenderProp={setRunRender}
                                                        runRenderProp={runRender}
                                                        periodProp={period}
                                                        nameUserProp={nameUser}
                                                        projectsProp={projectAssignments}
                                                        interestedPersonsProp={selectedInterestedPerson}
                                                        achievementsProp={selectedAchievement}
                                                        deliverableProp={selectedDeliverable}
                                                        observationsProp={observations}
                                                        servicesProp={selectedService}
                                                        dataOfficialProp={dataOfficial}
                                                        chartDataProp={chartData}
                                                        exportPDFFile={true}
                                                        setSendPDFProp={setSendPDF}
                                                        listInterestedPersonProp={listInterestedPerson}
                                                        listAchievementProp={listAchievement}
                                                        listDeliverableProp={listDeliverable}
                                                        listServiceProp={listService}
                                                        objectiveProp={selectedObjective}
                                                        getSelectedClientProp={getSelectedClient}
                                                    />
                                            :
                                            ""
                                        }
                                    </div>
                                    <Block7 />



                                </div>
                            </>
                        }
                    </>
                    :
                    null
                }
            </div>
        </div >
    )
}

export default InformeMensualIndividual