import { useState, useEffect } from 'react';
import moment from 'moment';
// función que cambia el formato a una fecha 

// Función para obtener el último día hábil del mes
export function ultimoDiaHabil(year, month, holidays) {
    const lastDay = new Date(year, month + 1, 0); // Último día del mes
    const weekendDays = [0, 6]; // Días del fin de semana (0 para domingo, 6 para sábado)


    while (weekendDays.includes(lastDay.getDay()) || holidays.includes(formatDate(lastDay))) {
        lastDay.setDate(lastDay.getDate() - 1);
    }

    return lastDay;
}
export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export function CambiarFormatoFecha(date) {
    if (typeof date === 'string') {
        date = new Date(date);
    }

    if (!(date instanceof Date) || isNaN(date)) {
   /*      console.error("Error: 'date' no es un objeto Date válido", date); */
        return null; 
    }

    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }

    return `${date.getFullYear()}-${month}-${day}`;
}
// función que cambia al estandar un fecha 

export function EstandarFormatoFecha(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
        month = '0' + month;
    }

    if (day < 10) {
        day = '0' + day;
    }
    return day + '-' + month + '-' + date.getFullYear()
}

// función que valida cuando se selecciona un mes cerrado

export function ValidarMesCerrado(date, minFechaCierre, disabledDates, newDates) {
    var newDate = CambiarFormatoFecha(date)
    var newMinFechaCierre = CambiarFormatoFecha(minFechaCierre)
    var newDisbledDates = []

    disabledDates.forEach(element => {
        newDisbledDates.push(CambiarFormatoFecha(element))
    })

    let borrarMensaje = true
    var setFechaError = false
    var setMensajeFecha = ''
    var setMostrarAdvertencia = false
    var setMostrarAdvertenciaMensaje = ''
    var setValidarMes = false

    if (isNaN(minFechaCierre) === false) {
        if (newDate < newMinFechaCierre) {
            setMostrarAdvertencia = true
            setMostrarAdvertenciaMensaje = 'La fecha seleccionada corresponde a un mes cerrado, debe solicitar el permiso al administrador para poder registrar la actividad o modificarla'
            setValidarMes = true
            setFechaError = true
            setMensajeFecha = 'La fecha ingresada pertenece a un mes cerrado'
        } else {
            newDisbledDates.forEach(element => {
                if (newDate === element) {
                    setMostrarAdvertencia = true
                    setMostrarAdvertenciaMensaje = 'La fecha seleccionada corresponde a un mes cerrado, debe solicitar el permiso al administrador para poder registrar la actividad o modificarla'
                    setValidarMes = true
                    setFechaError = true
                    setMensajeFecha = 'La fecha ingresada pertenece a un mes cerrado'
                    borrarMensaje = false
                } else {
                    if (borrarMensaje === true) {
                        setFechaError = false
                        setMensajeFecha = ''
                    }

                }
            })
        }
    }

    return {
        setFechaError: setFechaError,
        setMensajeFecha: setMensajeFecha,
        setMostrarAdvertencia: setMostrarAdvertencia,
        setMostrarAdvertenciaMensaje: setMostrarAdvertenciaMensaje,
        setValidarMes: setValidarMes,
    }
}

// función que valida cuando se selecciona un mes cerrado

export function NuevoValidarMesCerrado(dates, minFechaCierre, disabledDates) {
    var newMinFechaCierre = CambiarFormatoFecha(minFechaCierre)
    var newDisbledDates = []

    disabledDates.forEach(element => {
        newDisbledDates.push(CambiarFormatoFecha(element))
    })

    let borrarMensaje = true
    var setFechaError = false
    var setMensajeFecha = ''
    var setMostrarAdvertencia = false
    var setMostrarAdvertenciaMensaje = ''
    var setValidarMes = false

    if (isNaN(minFechaCierre) === false) {
        if (dates.length !== 0) {
            dates.forEach(element => {
                if (CambiarFormatoFecha(element) < newMinFechaCierre) {
                    setMostrarAdvertencia = true
                    setMostrarAdvertenciaMensaje = 'La fecha seleccionada corresponde a un mes cerrado, debe solicitar el permiso al administrador para poder registrar la actividad o modificarla'
                    setValidarMes = true
                    setFechaError = true
                    setMensajeFecha = 'La fecha ingresada pertenece a un mes cerrado'
                } else {
                    newDisbledDates.forEach(element => {
                        dates.forEach(i => {
                            if (CambiarFormatoFecha(i) === element) {
                                setMostrarAdvertencia = true
                                setMostrarAdvertenciaMensaje = 'La fecha seleccionada corresponde a un mes cerrado, debe solicitar el permiso al administrador para poder registrar la actividad o modificarla'
                                setValidarMes = true
                                setFechaError = true
                                setMensajeFecha = 'La fecha ingresada pertenece a un mes cerrado'
                                borrarMensaje = false
                            } else {
                                if (borrarMensaje === true) {
                                    setFechaError = false
                                    setMensajeFecha = ''
                                }
                            }
                        })

                    })
                }
            })
        }


    }

    return {
        setFechaError: setFechaError,
        setMensajeFecha: setMensajeFecha,
        setMostrarAdvertencia: setMostrarAdvertencia,
        setMostrarAdvertenciaMensaje: setMostrarAdvertenciaMensaje,
        setValidarMes: setValidarMes,
    }
}

// funcion que suma días a una fecha
export function SumarDias(fecha, dias) {
    if (fecha !== undefined) {
        fecha.setDate(fecha.getDate() + dias);
    }
    return fecha;
}
// funcion que resta días a una fecha
export function RestarDias(fecha, dias) {
    if (fecha !== undefined) {
        fecha.setDate(fecha.getDate() - dias);
    }
    return fecha;
}

// funcion que suma meses a una fecha
export function SumarMeses(fecha, n = 1) {
    return new Date(fecha.setMonth(fecha.getMonth() + n));
}
// funcion que resta meses a una fecha
export function RestarMeses(fecha, n) {
    return new Date(fecha.setMonth(fecha.getMonth() - n));
}

// funcion que calcula el width de la pantalla 

export function UseMedia(query) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);
    useEffect(
        (matches) => {
            let media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            let listener = () => setMatches(media.matches);
            media.addListener(listener);
            return () => media.removeListener(listener);
        },
        [query]
    );

    return matches;
}

// funcion que valida cuando una fecha es mayor que otra 

export function Validarfechas(dateStart, dateEnd) {
    var setFechaError = false
    var setMensajeFecha = ''
    if (dateStart > dateEnd) {
        setFechaError = true
        setMensajeFecha = 'La fecha inicial no puede ser mayor a la fecha final'
    }
    return {
        setFechaError: setFechaError,
        setMensajeFecha: setMensajeFecha
    }
}

//Validar semana posterior habilitas

export function weekNext() {
    let today = new Date();
    let day = today.getDate();
    let nextPrev
    if (today.getDay() === 0) {
        nextPrev = day + 7
    }
    else if (today.getDay() === 1) {
        nextPrev = day + 7
    } else if (today.getDay() === 2) {
        nextPrev = day + 7
    } else if (today.getDay() === 3) {
        nextPrev = day + 7
    } else if (today.getDay() === 4) {
        nextPrev = day + 7
    } else if (today.getDay() === 5) {
        nextPrev = day + 7
    }
    else if (today.getDay() === 6) {
        nextPrev = day + 7
    }
    var maxDate = new Date();
    maxDate.setDate(nextPrev)
    return maxDate
}

// funcion que valida cuando se traslapan novedades 

export function ValidarNovedadesCruzadas(dateStart, dateEnd, tipoNovedad, consultarNovedades, idNovedad) {

    var setFechaError = false
    var setMensajeFecha = ''
    var setValidarTipoNovedadError = false
    var setMostrarModalAdvertencia = false
    var setMensajeAdvertencia = ''
    var setMostrarExtenderVacaciones = false
    var newArrayDates

    if (consultarNovedades !== undefined) {
        if (consultarNovedades.length !== 0) {

            const newFechaInicio = CambiarFormatoFecha(dateStart)

            const newFechaFin = CambiarFormatoFecha(dateEnd)

            newArrayDates = consultarNovedades.filter(function (item) {
                return newFechaInicio <= item.fecFin && item.fecInicio <= newFechaFin
            })
            var editarMismaNovedad = false

            if (newArrayDates.length === 1) {
                if (newArrayDates[0].id !== idNovedad) {
                    editarMismaNovedad = false
                } else {
                    editarMismaNovedad = true
                }
            }

            if (newArrayDates.length !== 0) {
                if (editarMismaNovedad === false) {
                    newArrayDates.forEach(element => {
                        if (tipoNovedad !== undefined && tipoNovedad !== null) {
                            if (tipoNovedad.isTraslapo !== true) {
                                setValidarTipoNovedadError = true
                                setMostrarModalAdvertencia = true
                                setMensajeAdvertencia = 'Las fechas ingresadas se cruzan con novedades ya registradas'
                                setMostrarExtenderVacaciones = false
                                setFechaError = true
                                setMensajeFecha = 'Debe modificar el rango de fechas'

                            } else {
                                var filterArrayDates = newArrayDates.filter(function (object) {
                                    return object.tipoNovedad.isVacaciones === true && tipoNovedad.isTraslapo === true
                                })
                                if (filterArrayDates.length !== 0) {
                                    setValidarTipoNovedadError = false
                                    setMostrarModalAdvertencia = true
                                    setMensajeAdvertencia = 'Señor usuario usted cuenta con una novedad de tipo vacaciones, ¿desea extenderlas?'
                                    setMostrarExtenderVacaciones = true
                                } else {
                                    setMostrarModalAdvertencia = true
                                    setValidarTipoNovedadError = false
                                    setMensajeAdvertencia = 'Las fechas ingresadas se cruzan con novedades ya registradas'
                                    setMostrarExtenderVacaciones = false
                                    setFechaError = true
                                    setMensajeFecha = 'Debe modificar el rango de fechas'
                                }
                            }
                        }
                    })
                }
            } else {
                if (tipoNovedad !== undefined && tipoNovedad !== null) {
                    setValidarTipoNovedadError = false
                    setMostrarExtenderVacaciones = false
                    setMostrarExtenderVacaciones = false
                    setMostrarModalAdvertencia = false
                }
            }
        }
    }

    return {
        setValidarTipoNovedadError: setValidarTipoNovedadError,
        setMostrarModalAdvertencia: setMostrarModalAdvertencia,
        setMensajeAdvertencia: setMensajeAdvertencia,
        setMostrarExtenderVacaciones: setMostrarExtenderVacaciones,
        setFechaError: setFechaError,
        setMensajeFecha: setMensajeFecha
    }
}


// funcion que valida cuando se traslapa una novedad con regitros de actividad

export function ValidarNovedades(dateStart, dateEnd, tipoNovedad, actividades, tiposNovedades) {
    var filtroActividades = actividades.filter(function (object) {
        return object.fecha >= CambiarFormatoFecha(dateStart) && object.fecha <= CambiarFormatoFecha(dateEnd)
    })

    return {
        datos: filtroActividades,
        marcaParcial: true
    }
}


// función que genera un color aleatorio

export function GenerarNuevoColor() {
    var simbolos, color;
    simbolos = "0123456789ABCDEF";
    color = "#";

    for (var i = 0; i < 6; i++) {
        color = color + simbolos[Math.floor(Math.random() * 16)];
    }

    return color
}

// funciones que ajustan los datos para motrarlos en gráficas

export const PrepareDataToChart = (tipoActividades, chartPDF) => {
    let labels = tipoActividades.map((tipoActividad) => /* chartPDF ? tipoActividad.tipoActividad + ': ' + tipoActividad.porcentajeHoras.toFixed(2) + '%' : */ tipoActividad.tipoActividad);
    let data = tipoActividades.map((tipoActividad) => tipoActividad.porcentajeHoras.toFixed(2));
    return { labels, data };
};

export const PrepareDataToChartCategory = (categories, chartPDF) => {
    var result = [];
    categories.forEach(function (a) {
        if (!this[a.categoria.descripcion]) {
            this[a.categoria.descripcion] = { categoria: a.categoria.descripcion, porcentajeHoras: 0 };
            result.push(this[a.categoria.descripcion]);
        }
        this[a.categoria.descripcion].porcentajeHoras += a.porcentajeHoras;
    }, []);
    let labelsCategory = result.map((item) => /* chartPDF ? item.categoria + ': ' + item.porcentajeHoras.toFixed(2) + '%' : */ item.categoria);
    let dataCategory = result.map((item) => item.porcentajeHoras.toFixed(2));

    return { labelsCategory, dataCategory };
};

export const PrepareDataToChartTypeProject = (typeProject, chartPDF) => {
    var result = []
    if (typeProject.length !== 0) {
        typeProject.forEach(function (obj) {

            if (!this[obj.nombreTipoProyecto]) {
                this[obj.nombreTipoProyecto] = { nombreTipoProyecto: obj.nombreTipoProyecto, porcentajeHoras: 0 }
                result.push(this[obj.nombreTipoProyecto])
            }
            this[obj.nombreTipoProyecto].porcentajeHoras += obj.porcentajeHoras;
        }, []);
    }

    let labelsTypeProject = result.map((item) => chartPDF ? item.nombreTipoProyecto + ': ' + item.porcentajeHoras + '%' : item.nombreTipoProyecto)
    let dataTypeProject = result.map((item) => item.porcentajeHoras);

    return { labelsTypeProject, dataTypeProject }

};

// función que optiene el valor de un parametro enviado por url, ejemplo: url?parametro=valor

export function GetParameterByName(name) {
    name = name.replace(/[-]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// fución que optiene todos las fechas dentro de un rango de fechas 

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function GetDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(currentDate)
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

//Función que elimina acentos y tildes
export function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f ]/g, "");
}

//Función que valida las coincidencia entre dos arrays por id
export function isEqual(obj1, obj2) {
    return parseInt(obj1.id) === parseInt(obj2.id);
}

export const isValidDate = (dateEndAsignacion, dateStartPeriod, dateEndPeriod) => {
    if (dateEndAsignacion !== null) {
        if ((dateEndAsignacion >= dateStartPeriod && dateEndAsignacion <= dateEndPeriod) ||
            (dateEndAsignacion >= dateStartPeriod && dateEndAsignacion >= dateEndPeriod)) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
};

export function obtenerHoraMaximaPorFecha(newHistoricoHoras, fechasSeleccionadas) {
    // Objeto para almacenar las horas máximas por fecha
    const horasMaximasPorFecha = {};

    newHistoricoHoras.forEach(historico => {
        const fechaInicio = new Date(historico.fechainicioasignacion);
        const fechaFin = historico.fechafinasignacion ? new Date(historico.fechafinasignacion) : new Date('9999-12-31');
        const horasMaximas = parseFloat(historico.horasMaximas);

        fechasSeleccionadas.forEach(fechaSeleccionada => {
            const fecha = new Date(fechaSeleccionada);
            if (fecha >= fechaInicio && fecha <= fechaFin) {
                if (!horasMaximasPorFecha.hasOwnProperty(fechaSeleccionada)) {
                    horasMaximasPorFecha[fechaSeleccionada] = 0;
                }
                horasMaximasPorFecha[fechaSeleccionada] += horasMaximas;
            }
        });
    })
    return horasMaximasPorFecha;

}
export function obtenerHoraMinimasPorFecha(newHistoricoHoras, fechasSeleccionadas, arrayFestivos) {

    // Utiliza moment.js para manejar fechas si está disponible
    // Si moment no está disponible, deberás adaptar el código para usar Date nativo de JavaScript

    // Convertir festivos a formato ISO para comparación
    const festivos = arrayFestivos.map(festivo => {
        // Convertir mes/día/año a formato ISO (año-mes-día)
        return moment(festivo, "MM/DD/YYYY").format("YYYY-MM-DD");
    });    

    // Convertir fechasSeleccionadas a formato ISO
    const fechasSeleccionadasISO = fechasSeleccionadas.map(fecha => moment(fecha).format("YYYY-MM-DD"));

    // Filtrar fechas seleccionadas para excluir sábados, domingos y festivos
    const fechasFiltradas = fechasSeleccionadasISO.filter(fecha => {
        const diaSemana = moment(fecha).isoWeekday();
        return diaSemana !== 6 && diaSemana !== 7 && !festivos.includes(fecha);
    });

    const horasMinimasPorFecha = {};

    newHistoricoHoras?.forEach(historico => {
        // Asumiendo que fechainicioasignacion y fechafinasignacion vienen en formato año-mes-día
        const fechaInicio = moment(historico.fechainicioasignacion).format("YYYY-MM-DD");
        const fechaFin = historico.fechafinasignacion ? moment(historico.fechafinasignacion).format("YYYY-MM-DD") : "9999-12-31";

        fechasFiltradas.forEach(fechaSeleccionada => {
            if (moment(fechaSeleccionada).isBetween(fechaInicio, fechaFin, null, '[]')) {
                if (!horasMinimasPorFecha.hasOwnProperty(fechaSeleccionada)) {
                    horasMinimasPorFecha[fechaSeleccionada] = 0;
                }
                horasMinimasPorFecha[fechaSeleccionada] += parseFloat(historico.horasMinimas);
            }
        });
    });

    return horasMinimasPorFecha;
}


//funcion para obtener las fechas dentro de un rango
export const getRangeDate = (newDateStart, newDateEnd) => {
    const datesInRange = [];
    let datesFormat = []
    const currentDate = new Date(newDateStart);
    const endDate = new Date(newDateEnd);
    while (currentDate <= endDate) {
        const dateCopy = new Date(currentDate); // Copia la fecha actual
        dateCopy.setHours(0, 0, 0, 0); // Establece la hora a las 00:00:00
        datesInRange.push(new Date(dateCopy));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;
    if (gmtHours < 0) {
        datesInRange.forEach(nd => {
            datesFormat.push(SumarDias(new Date(nd), 1))
        })
    } else {
        datesInRange.forEach(nd => {
            datesFormat.push(new Date(nd))
        })
    }
    return datesFormat
}




