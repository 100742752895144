import { CambiarFormatoFecha, obtenerHoraMinimasPorFecha, getRangeDate } from '../Funciones'
import axios from 'axios';
import Services from '../../service/Services';
import moment from 'moment';


const getServices = new Services();
export function getData(dateStart, dateEnd, user, setData) {
    const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
    const json = JSON.stringify({
        esadicional: "0",
        fecFinal: dateEnd,
        fecInicial: dateStart,
        usuario: user
    });
    return axios.post(url, json,
        {
            headers:
            {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            setData(response.data)
        })
        .catch(function (error) {
            //console.log(error.response)
        })
}

export function getHolidays(dateStart, dateEnd, setData) {
    getServices.getCalendarios().then(data => {
        var arrayHolidays = []
        var resultHolidays = data?.filter(function (obj) {
            return obj.pais === 'Colombia'
        })
        resultHolidays.forEach(element => {
            var array = element.fechas.split(',')
            array.forEach(x => {
                var date = new Date(x);
                arrayHolidays.push(CambiarFormatoFecha(date))
            })
        })
        var filterHolidays = arrayHolidays.filter(obj => obj >= dateStart && obj <= dateEnd);
        function isWeekday(dateStr) {
            const date = new Date(dateStr);
            const dayOfWeek = date.getUTCDay();
            return dayOfWeek !== 0 && dayOfWeek !== 6;
        }
        function removeWeekends(dates) {
            return dates.filter(isWeekday);
        }
        setData(removeWeekends(filterHolidays));
    });
};


export function CalculateHoursPeriod(dateStart, dateEnd, data, servicioCalendario) {
const festivos = servicioCalendario
    let suma = 0;
    let totalHoras = 0;
    if (servicioCalendario && servicioCalendario[0]){
    // Convertir festivos a formato ISO para comparación
      festivos.map(festivo => {
        // Convertir mes/día/año a formato ISO (año-mes-día)
        return moment(festivo, "MM/DD/YYYY").format("YYYY-MM-DD");
    });
    }else {
        //console.error ()
    };
    const todasLasFechasDelRango = getRangeDate(CambiarFormatoFecha(dateStart), CambiarFormatoFecha(dateEnd));

    // Filtrar fechas seleccionadas para excluir sábados, domingos y festivos
    const fechasFiltradas = todasLasFechasDelRango.filter(fecha => {
        const diaSemana = moment(fecha).isoWeekday();
        const fechaISO = moment(fecha).format("YYYY-MM-DD");
        return diaSemana !== 6 && diaSemana !== 7 && !festivos.includes(fechaISO);
    });
    
    const historicoHorasJSON = sessionStorage.getItem("historicoHoras");
    const historicoHoras = JSON.parse(historicoHorasJSON);

    let tieneMinimaCero = false;

    fechasFiltradas.forEach(element => {

        const horasMinimasPorFecha = obtenerHoraMinimasPorFecha(historicoHoras, [CambiarFormatoFecha(element)], servicioCalendario)

        if (parseFloat(horasMinimasPorFecha[CambiarFormatoFecha(element)]) !== isNaN && parseFloat(horasMinimasPorFecha[CambiarFormatoFecha(element)]) === 0) {
            tieneMinimaCero = true;
        }
        const horas = parseFloat(horasMinimasPorFecha[CambiarFormatoFecha(element)]);

        // Primero, verifica si 'horas' es NaN. Si es así, no hagas nada.
        if (isNaN(horas)) {
            // Si 'horas' es NaN, simplemente continúa con el siguiente elemento.
            return; // Continúa con la siguiente iteración del bucle forEach.
        }

        // Si 'horas' es igual a 0, establece 'tieneMinimaCero' como verdadero.
        if (horas === 0) {
            tieneMinimaCero = true;
        }

        // Si 'tieneMinimaCero' es falso, suma 'horas' a 'suma'.
        if (!tieneMinimaCero) {
            suma += horas;
        }

    })

    data.forEach(item => {
        totalHoras += item.numerohoras

    })

    var workDays = suma

    return Math.round((workDays - totalHoras)*100) /100;


};
