import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect';
import ConfirmBtn from './ConfirmBtn';

const BtnAñadirAsociacion = ({ servicesObjectivesByClientProp, dataProp,
    runRenderProp, setRunRenderProp, newRowDataProp }) => {
    const [displayBasic, setDisplayBasic] = useState(false)
    const [validateChanges, setValidateChanges] = useState(false)

    const onClick = () => {
        setDisplayBasic(true);
    }

    const onHide = () => {
        setDisplayBasic(false);
        resetData()
    }

    const resetData = () => {
        setValidateChanges(false);
        setSelectedObjective([]);
    }

    //<--------Seleccionar objetivos asociados al cliente asociado al objetivo estratégico-------->

    const [selectedObjective, setSelectedObjective] = useState([])
    const [obj, setObj] = useState({})
    useEffect(() => {
        if (selectedObjective.length !== 0) {

            const updatedObj = selectedObjective.map(item => ({
                id: item.id,
                descripcion: item.descripcion
            }));

            let arrayConcat = []

            if (newRowDataProp !== null && newRowDataProp.length !== 0) {
                arrayConcat = newRowDataProp.concat(updatedObj)
            } else {
                arrayConcat = updatedObj
            }

            setObj(JSON.stringify(arrayConcat));
        }


    }, [selectedObjective, newRowDataProp])// eslint-disable-line react-hooks/exhaustive-deps

    const tableObjectives = {}
    const uniqueObjectives = servicesObjectivesByClientProp.filter(function (object) {
        return tableObjectives.hasOwnProperty(object.id) ? false : (tableObjectives[object.id] = true)
    })
    const onObjectiveChange = (e) => {
        setSelectedObjective(e.value)
        setValidateChanges(true)
    }
    const renderFooter = () => {

        return (
            <div>
                {validateChanges ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            message={"¿Estás seguro de guardar esta información?"}
                            action={"Update"}
                            label={"Guardar"}
                            icon="pi pi-plus-circle"
                            idClientProp={dataProp.cliente.id}
                            descripcionProp={dataProp.descripcion}
                            fechaInicioVigenciaProp={dataProp.fechainiciovigencia}
                            fechaFinVigenciaProp={dataProp.fechafinvigencia}
                            creadoPorProp={dataProp.creadopor}
                            fechaCreacionProp={dataProp.fechacreacion}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            setValidateChangesProp={setValidateChanges}
                            dataProp={dataProp}
                            optionUpdateProp={false}
                            createAssociationsProp={true}
                            objetivosAsociadosProp={obj}
                            setSelectedObjectiveProp={setSelectedObjective}
                        />
                        :
                        ''
                }


            </div>
        );
    }

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }



        const Regresar = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }


    function disabledSelected(e) {
        let disable = false
        newRowDataProp?.forEach(element => {
            if (e.id === element.id) {
                disable = true
            }
        })
        return disable;
    }

    let permisoRegistrar = false

    if (selectedObjective.length !== 0) {
        permisoRegistrar = true
    }
    return (
        <>
            <button className="btnAdd" onClick={() => onClick()}><span className="pi pi-plus Add"></span>Asociar Objetivos</button>
            <Dialog className="modalConfirm" header="Agregar Objetivos" visible={displayBasic} footer={renderFooter()} onHide={() => onHide()}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >

                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Objetivo</label>
                            <MultiSelect value={selectedObjective} options={uniqueObjectives} onChange={onObjectiveChange} placeholder="Seleccionar Objetivo" optionLabel='descripcion' filter
                                emptyFilterMessage="No se encontraron resultados" optionDisabled={disabledSelected}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}


export default BtnAñadirAsociacion