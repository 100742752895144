import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import Services from '../../service/Services';
import { CambiarFormatoFecha, SumarDias } from '../Funciones';
import ConfirmBtn from '../ConsultarUsuarios/ConfirmBtn';
import Cookies from 'universal-cookie';
import { addLocale } from 'primereact/api';
import ModalContactoCliente from './BtnContactoCliente';
import { Toast } from 'primereact/toast';

const BtnDetalleEquipo = ({ createdByProp, nameClientsProp, idClientsProp, teamWork, coordinatorProp, coordinatorId, coordinatorData, dateStartCoordinatorProp, dateEndCoordinatorProp, dateStartTeam, dateEndTeam, idProp, runRenderProp, setRunRenderProp, dateCreatedProp, leaderCreationDateProp, nameTeamWorkProp, officialProp, dataContactCustomerProp }) => {

    const [validateChange, setValidateChange] = useState(false)

    const [displayBasic, setDisplayBasic] = useState(false)

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const toast = useRef(null)
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const [servicesClients, setServicesClients] = useState([]);
    const [userServices, setUserServices] = useState([]);
    const [servicesUserClients, setUserClients] = useState([]);
    const [idClienteConsulta, setIdClienteConsulta] = useState(idClientsProp);

    const getServices = new Services()

    useEffect(() => {
        if (displayBasic === true) {
            // Obtén todos los clientes
            getServices.getClientes().then(data => {
                const allClients = data.map(element => ({
                    id: element.id,
                    name: element.razonsocial, 
                }));
    
                setServicesClients(allClients); 
            });
    
            getServices.getUsuarios().then(data => {
                var activeCoordinator = data.filter(item => {
                    return item.estado === 1 || item.estado === 0;
                });
                setUserServices(activeCoordinator);
            });
    
            setIdClienteConsulta(idClientsProp);
            getServices.getUsuariosAsociadosCliente(idClienteConsulta).then(data => setUserClients(data));
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [getClients, setGetClients] = useState({
        clients: nameClientsProp,
        id: idClientsProp
    })

    const optionClients = servicesClients.map(item => {
        const av = { clients: item.name, id: parseInt(item.id) }
        return av
    })

    const onClientsChange = (e) => {
        setGetClients(e.value)
        setValidateChange(true)
    }

    const [nameTeamWork, setNameTeamWork] = useState(null)

    const onNameTeamWorkChange = (e) => {

        setNameTeamWork(e.target.value)
        setValidateChange(true)
    }

    var nameTeamRepeatError = false
    var messageNameTeamError = ''

    nameTeamWorkProp.forEach(element => {
        if (nameTeamWork !== null && nameTeamWork !== teamWork) {
            if (nameTeamWork.toLowerCase() === element.nombreequipotrabajo.toLowerCase()) {
                nameTeamRepeatError = true
                messageNameTeamError = 'El nombre ya se encuentra añadido en la tabla. '
            }
        }
    })

    const [nameCoordinator, setNameCoordinator] = useState(coordinatorProp)

    const [dateStartTeamWork, setDateStartTeamWork] = useState(null)

    const dateStartOnChange = (e) => {
        setDateStartTeamWork(e.value)
        setValidateChange(true)
    }

    const [dateEndTeamWork, setDateEndTeamWork] = useState(null)

    const dateEndOnChange = (e) => {
        setDateEndTeamWork(e.value)
        setValidateChange(true)

    }

    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;

    // estado que guarda las props del coordinador 

    const [dataCoordinator, setDataCoordinator] = useState(null)

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio

    useEffect(() => {

        const dateStartChange = new Date(dateStartTeam)

        if (gmtHours < 0) {
            setDateStartTeamWork(SumarDias(dateStartChange, +1))
        }
        else {
            setDateStartTeamWork(dateStartChange)
        }

        if (dateEndTeam !== null) {

            const dateEndChange = new Date(dateEndTeam)

            if (gmtHours < 0) {
                setDateEndTeamWork(SumarDias(dateEndChange, +1))
            }
            else {
                setDateEndTeamWork(dateEndChange)
            }
        }
        else {
            setDateEndTeamWork(null)
        }

        const dateStartCoordinatorChange = new Date(dateStartCoordinatorProp)

        if (gmtHours < 0) {
            setDateStartCoordinadorII(SumarDias(dateStartCoordinatorChange, +1))
        }
        else {
            setDateStartCoordinadorII(dateStartCoordinatorChange)
        }

        if (dateEndCoordinatorProp !== null) {
            const dateEndCoordinatorChange = new Date(dateEndCoordinatorProp)

            if (gmtHours < 0) {
                setDateEndCoordinadorII(SumarDias(dateEndCoordinatorChange, +1))
            }
            else {
                setDateEndCoordinadorII(dateEndCoordinatorChange)
            }
        }
        else {
            setDateEndCoordinadorII(null)
        }

        setGetClients({
            clients: nameClientsProp,
            id: idClientsProp
        })

        setNameTeamWork(teamWork)

        if (coordinatorData.estado === 0 && coordinatorData.colaborador.idusuario.estado === 2 && coordinatorData.colaborador.idusuario.idtipobloqueo.descripcion === 'Retiro') {
            setNameCoordinator('')
            setDataCoordinator({
                name: '',
                id: '',
                dateStart: '',
                dateEnd: '',
                idColaborador: ''
            })
        } else {
            setNameCoordinator(coordinatorProp)
            setDataCoordinator({
                name: coordinatorProp,
                id: coordinatorData.id,
                dateStart: dateStartCoordinatorProp,
                dateEnd: dateEndCoordinatorProp,
                idColaborador: coordinatorId
            })
        }

        if (dataContactCustomerProp !== undefined) {
            setNameContactCustomer(dataContactCustomerProp.personainteresada.nombrecompleto)
            setDateStartContactCustomer(dataContactCustomerProp.fechainicioasignacion)
            setDateEndContactCustomer(dataContactCustomerProp.fechafinasignacion)
            setSelectedContactCustomer({
                name: dataContactCustomerProp.personainteresada.nombrecompleto,
                id: dataContactCustomerProp.personainteresada.id
            })
        }

    }, [nameClientsProp, coordinatorData, idClientsProp, teamWork, coordinatorProp, dateStartTeam,
        dateEndTeam, gmtHours, coordinatorId, dateStartCoordinatorProp, dateEndCoordinatorProp, dataContactCustomerProp]); // eslint-disable-line react-hooks/exhaustive-deps


    const [displayCoordinator, setDisplayCoordinator] = useState(false)

    const resetDataII = () => {
        setNameTeamWork(teamWork)
        setDisplayCoordinator(false)
        setValidateChange(false)

        const dateStartChange = new Date(dateStartTeam)

        if (gmtHours < 0) {
            setDateStartTeamWork(SumarDias(dateStartChange, +1))
        }
        else {
            setDateStartTeamWork(dateStartChange)
        }

        if (dateEndTeam !== null) {

            const dateEndChange = new Date(dateEndTeam)

            if (gmtHours < 0) {
                setDateEndTeamWork(SumarDias(dateEndChange, +1))
            }
            else {
                setDateEndTeamWork(dateEndChange)
            }
        }
        else {
            setDateEndTeamWork(null)
        }
        setGetClients({
            clients: nameClientsProp,
            id: idClientsProp
        })

        if (dataContactCustomerProp !== undefined) {
            setNameContactCustomer(dataContactCustomerProp.personainteresada.nombrecompleto)
            setDateStartContactCustomer(dataContactCustomerProp.fechainicioasignacion)
            setDateEndContactCustomer(dataContactCustomerProp.fechafinasignacion)
            setSelectedContactCustomer({
                name: dataContactCustomerProp.personainteresada.nombrecompleto,
                id: dataContactCustomerProp.personainteresada.id
            })
        }
    }

    const ModalRegresar = ({ displayBasicProp }) => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            dialogFuncMap[`${name}`](false)
            setDisplayConfirmation(false)
            setDisplayBasic(displayBasicProp)
            resetDataII()
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => ('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    //Estado que obtiene todos los valores del coordinador
    const [newArrayGroupCoordinator, setNewArrayGroupCoordinator] = useState([])

    const [dateStartCoordinatorII, setDateStartCoordinadorII] = useState(null)
    const [dateEndCoordinatorII, setDateEndCoordinadorII] = useState(null)

    var dateStartError = false
    var messageDateStart = ''

    //Creando la modal de coordinador

    const ModalCoordinators = ({ dataCoordinatorProp, dateStartCoordinatorIIProp, dateEndCoordinatorIIProp, idProp }) => {

        const [validateChangeCoordinator, setValidateChangeCoordinator] = useState(false)

        const dialogFuncMap = {
            'displayCoordinator': setDisplayCoordinator
        }

        const onClick = (name) => {
            dialogFuncMap[`${name}`](true)
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false)
        }

        //Obtener datos del coordinador

        const [selectedCoordinator, setSelectedCoordinator] = useState({
            name: '',
            id: ''
        })
        var coordinator = []
        servicesUserClients.forEach(e => {
            const result = userServices.filter(item => {
                return e.id === item.idUsuario
            })

            result.forEach(e => {
                coordinator.push(e)
            })
        })
        const getCoordinator = coordinator.map(item => {
            const getObject = { name: item.nombres + ' ' + item.apellidos, id: item.idColaborador }
            return getObject
        })

        getCoordinator.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })
        const onCoordinatorChange = (e) => {
            setSelectedCoordinator(e.value)
            setValidateChangeCoordinator(true)
        }

        const [dateStartCoordinator, setDateStartCoordinador] = useState(null)

        const onDateStartChange = (e) => {
            setDateStartCoordinador(e.value)
            setValidateChangeCoordinator(true)

        }
        const [dateEndCoordinator, setDateEndCoordinador] = useState(null)

        const onDateEndChange = (e) => {
            setDateEndCoordinador(e.value)
            setValidateChangeCoordinator(true)
        }

        useEffect(() => {

            const dateStartCoordinatorChange = new Date(dataCoordinatorProp.dateStart)

            if (gmtHours < 0) {
                setDateStartCoordinador(SumarDias(dateStartCoordinatorChange, +1))
            }
            else {
                setDateStartCoordinador(dateStartCoordinatorChange)
            }

            if (dataCoordinatorProp.dateEnd !== null) {
                const dateEndCoordinatorChange = new Date(dataCoordinatorProp.dateEnd)


                if (gmtHours < 0) {
                    setDateEndCoordinador(SumarDias(dateEndCoordinatorChange, +1))
                }
                else {
                    setDateEndCoordinador(dateEndCoordinatorChange)
                }
            }

            else {
                setDateEndCoordinador(null)
            }

            setSelectedCoordinator({
                name: dataCoordinatorProp.name,
                id: dataCoordinatorProp.idColaborador
            })


        }, [dataCoordinatorProp])// eslint-disable-line react-hooks/exhaustive-deps

        //Validaciones de fechas

        if (dateStartCoordinator !== null && dateEndCoordinator === null) {
            const dateStart = dateStartCoordinator === null ? dateStartCoordinator : CambiarFormatoFecha(dateStartCoordinator)
            // const dateEnd = dateEndCoordinator === null ? dateEndCoordinator : CambiarFormatoFecha(dateEndCoordinator)
            const dateStartTeam = dateStartTeamWork === null ? dateStartTeamWork : CambiarFormatoFecha(dateStartTeamWork)
            const dateEndTeam = dateEndTeamWork === null ? dateEndTeamWork : CambiarFormatoFecha(dateEndTeamWork)

            if (dateStartTeam !== null && dateEndTeam !== null) {

                dateStartError = true
                messageDateStart = 'La fecha fin no puede quedar vacia débido a que el equipo tiene una fecha fin registrada. '
            }
            else if (dateStartTeam !== null && dateEndTeam === null) {
                if (dateStart >= dateStartTeam) {
                    dateStartError = false
                    messageDateStart = ''
                }
                else {
                    dateStartError = true
                    messageDateStart = 'La fecha de inicio del coordinador debe ser mayor a la del equipo de trabajo. '
                }
            }
        }

        function rangeDateEditCoordinador() {

            const dateStartOfCoordinator = dateStartCoordinator === null ? dateStartCoordinator : CambiarFormatoFecha(dateStartCoordinator)
            const dateEndOfCoordinator = dateEndCoordinator === null ? dateEndCoordinator : CambiarFormatoFecha(dateEndCoordinator)

            const dateStartOfTeamWork = dateStartTeamWork === null ? dateStartTeamWork : CambiarFormatoFecha(dateStartTeamWork)
            const dateEndOfTeamWork = dateEndTeamWork === null ? dateEndTeamWork : CambiarFormatoFecha(dateEndTeamWork)

            if (dateStartOfTeamWork !== null && dateEndOfTeamWork !== null) {
                if (dateStartOfCoordinator >= dateStartOfTeamWork && dateStartOfCoordinator <= dateEndOfTeamWork &&
                    dateEndOfCoordinator >= dateStartOfTeamWork && dateEndOfCoordinator <= dateEndOfTeamWork) {
                    dateStartError = false
                    messageDateStart = ""
                }
                else {
                    dateStartError = true
                    messageDateStart = "La fecha inicio y/o la fecha fin no están en el rango de fechas del equipo de trabajo. "
                }
            }
        }

        if (dateStartCoordinator !== null && dateEndCoordinator !== null) {
            if (dateStartCoordinator > dateEndCoordinator) {
                dateStartError = true
                messageDateStart = "La fecha de inicio debe ser menor a la fecha fin. "
            }
            else {
                rangeDateEditCoordinador()
            }
        }
        let datedCrusses = false


        if (displayCoordinator === true) {

            //Validar que no se crucen las fechas del usuario en diferentes equipos de trabajo

            const result = coordinator?.filter(obj => {
                return parseInt(obj.idColaborador) === parseInt(selectedCoordinator?.id)
            })

            function isDateInRange(date, rangeStart, rangeEnd) {
                const targetDate = new Date(date);
                const startDate = new Date(rangeStart);
                const endDate = rangeEnd ? new Date(rangeEnd) : null;

                if (endDate) {
                    return targetDate >= startDate && targetDate <= endDate;
                } else {
                    return targetDate >= startDate;
                }
            }

            function rangesOverlap(start1, end1, start2, end2) {
                const range1Start = new Date(start1);
                const range1End = end1 ? new Date(end1) : null;
                const range2Start = new Date(start2);
                const range2End = end2 ? new Date(end2) : null;

                if (range1End && range2End) {
                    return range1Start <= range2End && range1End >= range2Start;
                } else if (range1End) {
                    return range1Start <= range2Start && range1End >= range2Start;
                } else if (range2End) {
                    return range1Start <= range2End && range2Start >= range1Start;
                } else {
                    return range1Start <= range2Start;
                }
            }

            function validateRange(startDate, endDate, ranges) {
                for (const range of ranges) {
                    const { fechainicioasignacion, fechafinasignacion } = range;
                    if (
                        isDateInRange(startDate, fechainicioasignacion, fechafinasignacion) ||
                        (endDate && isDateInRange(endDate, fechainicioasignacion, fechafinasignacion)) ||
                        (endDate && rangesOverlap(startDate, endDate, fechainicioasignacion, fechafinasignacion))
                    ) {
                        return false;
                    }
                }
                return true;
            }

            // Validar que no se crucen fechas con otros equipos en los que esté asociado el usuario
            if (dateStartCoordinator !== null) {
                result?.forEach(i => {

                    let ranges = i.equipoTrabajoLider?.concat(i.equipoTrabajoUsuario);
                    ranges.forEach(r => {

                        if (r.equipoTrabajo.id !== idProp) {
                            const isValid = validateRange(
                                CambiarFormatoFecha(dateStartCoordinator),
                                dateEndCoordinator ? CambiarFormatoFecha(dateEndCoordinator) : null,
                                ranges
                            );

                            if (!isValid) {
                                datedCrusses = true;
                                toast.current.show({
                                    severity: 'error',
                                    summary: 'Fechas Cruzadas',
                                    detail: `El usuario seleccionado se cruza con fechas ya creadas para un equipo de trabajo`,
                                    life: 4000
                                });
                            }
                        }
                    })
                });
            }
        }

        //Validaciones para darle permiso al boton guardar de aparecer
        var createPermissionSave = false

        if (selectedCoordinator.name !== '' && dateStartCoordinator !== null &&
            dateStartError === false && datedCrusses === false) {

            createPermissionSave = true
        }

        const closeWindowCoordinator = () => {

            setDisplayCoordinator(false)
            setNameCoordinator(selectedCoordinator.name)
            setValidateChange(true)
            var filterCoordinator = []
            const result = userServices.filter(item => {
                return item.idColaborador === selectedCoordinator.id
            })
            result.forEach(e => {
                filterCoordinator.push(e)
            })
            var deleteCoordinator
            var newCoordinator
            if (dataCoordinatorProp.idColaborador === selectedCoordinator.id) {
                filterCoordinator.forEach(i => {
                    setDataCoordinator({
                        name: selectedCoordinator.name,
                        id: dataCoordinatorProp.id,
                        dateStart: dateStartCoordinator === null ? dateStartCoordinator : CambiarFormatoFecha(dateStartCoordinator),
                        dateEnd: dateEndCoordinator === null ? dateEndCoordinator : CambiarFormatoFecha(dateEndCoordinator),
                        idColaborador: i.idColaborador,
                    })
                    newCoordinator = {
                        colaborador: {
                            id: i.idColaborador
                        },
                        estado: 1,
                        fechacreacion: leaderCreationDateProp,
                        fechainicioasignacion: dateStartCoordinator == null ? dateStartCoordinator : CambiarFormatoFecha(dateStartCoordinator),
                        fechafinasignacion: dateEndCoordinator === null ? dateEndCoordinator : CambiarFormatoFecha(dateEndCoordinator),
                        id: dataCoordinatorProp.id,
                    }
                })


                /*  setNewArrayGroupCoordinator(newCoordinator) */
            } else {
                filterCoordinator.forEach(i => {
                    setDataCoordinator({
                        name: selectedCoordinator.name,
                        id: dataCoordinatorProp.id,
                        dateStart: dateStartCoordinator === null ? dateStartCoordinator : CambiarFormatoFecha(dateStartCoordinator),
                        dateEnd: dateEndCoordinator === null ? dateEndCoordinator : CambiarFormatoFecha(dateEndCoordinator),
                        idColaborador: i.idColaborador,
                    })

                    deleteCoordinator = {
                        colaborador: {
                            id: coordinatorId
                        },
                        estado: 3,
                        fechacreacion: leaderCreationDateProp,
                        id: coordinatorData.id,
                        fechainicioasignacion: dateStartCoordinatorProp,
                        fechafinasignacion: dateEndCoordinatorProp,
                    }

                    newCoordinator = {
                        colaborador: {
                            id: i.idColaborador
                        },
                        estado: 1,
                        fechacreacion: leaderCreationDateProp,
                        fechainicioasignacion: dateStartCoordinator === null ? dateStartCoordinator : CambiarFormatoFecha(dateStartCoordinator),
                        fechafinasignacion: dateEndCoordinator === null ? dateEndCoordinator : CambiarFormatoFecha(dateEndCoordinator),
                    }
                })


                /* setNewArrayGroupCoordinator(newArrayCoordinatorData) */
            }

            if (deleteCoordinator === undefined) {
                setNewArrayGroupCoordinator([newCoordinator])
            } else {
                setNewArrayGroupCoordinator([deleteCoordinator, newCoordinator])
            }
            dateStartCoordinatorIIProp(dateStartCoordinator)
            dateEndCoordinatorIIProp(dateEndCoordinator)
        }

        function resetData() {
            setNewArrayGroupCoordinator([])
        }

        const renderFooter = (name) => {
            return (
                <div>
                    {
                        validateChangeCoordinator ?
                            <ModalRegresar displayBasicProp={true} />
                            :
                            <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    }
                    {
                        createPermissionSave ?
                            <Button label="Guardar" icon="pi pi-save" onClick={() => closeWindowCoordinator()} />
                            :
                            ''
                    }

                </div>
            )
        }

        return (
            <>
                <Button icon="pi pi-plus" id="boton-coordinator" onClick={() => {
                    onClick('displayCoordinator')
                    resetData()
                }} className="p-button-success" />
                <Dialog className="modal" header="Lista de coordinadores" visible={displayCoordinator} modal style={{}}
                    footer={renderFooter('displayCoordinator')} onHide={() => onHide('displayCoordinator')}>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className='p-field p-col-6 p-md-6'>
                            <label className="labels">Seleccione un coordinador<span className="require">*</span></label>
                            <Dropdown filter emptyMessage="No hay resultados" value={selectedCoordinator} options={getCoordinator} optionLabel="name" onChange={onCoordinatorChange} placeholder="Selecione un coordinador" />
                        </div>

                        <div className="p-field p-col-6 p-md-6">
                            <label className="labels">Fecha inicio de vigencia del coordinador<span className="require">*</span></label>
                            <Calendar className={dateStartError ? "p-invalid" : ''} value={dateStartCoordinator} onChange={onDateStartChange} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha inicio de vigencia" />
                            <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                        </div>

                        <div className="p-field p-col-6 p-md-6">
                            <label className="labels">Fecha fin de vigencia del coordinador</label>
                            <Calendar value={dateEndCoordinator} onChange={onDateEndChange} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha fin de vigencia" />
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }
    var dateStartErrorTeamWork = false
    var messageDateStartTeamWork = ''
    var dateStartErrorCoordinator = false
    var messageDateStartCoordinator = ''


    //Validar fechas

    const dateSC = dateStartCoordinatorII === null ? dateStartCoordinatorII : CambiarFormatoFecha(dateStartCoordinatorII)
    const dateEC = dateEndCoordinatorII === null ? dateEndCoordinatorII : CambiarFormatoFecha(dateEndCoordinatorII)
    const dateSTeam = dateStartTeamWork === null ? dateStartTeamWork : CambiarFormatoFecha(dateStartTeamWork)
    const dateETeam = dateEndTeamWork === null ? dateEndTeamWork : CambiarFormatoFecha(dateEndTeamWork)

    function validateDateStartCoordinatorAndTeamWork() {
        if (dateSC !== null && dateEC === null) {
            if (dateSTeam > dateSC) {
                dateStartErrorCoordinator = true
                messageDateStartCoordinator = 'La fecha inicial de vigencia del equipo debe ser menor al de Coordinador '
            }
        }
        else if (dateSC !== null && dateEC !== null) {
            if (dateSTeam > dateSC && dateEC) {
                dateStartErrorCoordinator = true
                messageDateStartCoordinator = 'La fecha inicial de vigencia del equipo debe ser menor a las fechas de Coordinador. '
            }
        }
    }

    function validateRangeDateTeamWorkAndCoordinator() {
        if (dateSC !== null && dateEC === null) {

            dateStartErrorCoordinator = true
            messageDateStartCoordinator = 'Verifique que el Coordinador tenga una fecha fin registrada. '
        }
        else if (dateSC !== null && dateEC !== null) {
            if ((dateSTeam > dateSC && dateSTeam > dateEC && dateETeam > dateSC && dateETeam > dateEC) ||
                (dateSTeam < dateSC && dateSTeam < dateEC && dateETeam < dateSC && dateETeam < dateEC)) {
                dateStartErrorCoordinator = true
                messageDateStartCoordinator = 'Las fechas del Coordinador no se encuentran en el rango de fechas del equipo. '
            }
            else if (dateETeam < dateEC || dateSTeam > dateSC) {
                dateStartErrorCoordinator = true
                messageDateStartCoordinator = 'Las fechas del Coordinador no se encuentran en el rango de fechas del equipo. '
            }
        }
    }

    function validateRangeTeamWorkAndOfficial() {

        var arrayOfficial = []
        var arrayOfficialII = []

        officialProp.forEach(element => {

            if (dateSTeam > element.fechafinasignacion || dateSTeam > element.fechainicioasignacion) {
                arrayOfficial.push(element)
            }
            if (dateETeam < element.fechainicioasignacion || dateETeam < element.fechafinasignacion) {
                arrayOfficial.push(element)
            }
            if (element.fechainicioasignacion !== null && element.fechafinasignacion === null) {
                arrayOfficialII.push(element)
            }
        })
        if (arrayOfficial.length !== 0) {
            dateStartErrorTeamWork = true
            messageDateStartTeamWork = 'Las fechas del equipo de trabajo no se pueden salir del rango de las fechas asociadas de los Funcionarios. '
        }
        else if (arrayOfficialII.length !== 0) {
            dateStartErrorTeamWork = true
            messageDateStartTeamWork = 'Verifique que los Funcionarios tengan una fecha fin registrada. '
        }
        else {
            dateStartErrorTeamWork = false
            messageDateStartTeamWork = ''
        }
    }

    if (dateStartTeamWork !== null && dateEndTeamWork !== null) {
        if (dateStartTeamWork > dateEndTeamWork) {
            dateStartErrorTeamWork = true
            messageDateStartTeamWork = "La fecha de inicio del equipo de trabajo debe ser menor a la fecha fin. "
        }
        else {
            validateRangeTeamWorkAndOfficial()
            validateRangeDateTeamWorkAndCoordinator()
        }
    }

    if (dateStartTeamWork !== null && dateEndTeamWork === null) {
        validateDateStartCoordinatorAndTeamWork()
    }
    var nameTeamError = false
    var messageNameTeam = ''

    if (nameTeamWork !== null) {
        if (nameTeamWork.length <= 3) {
            nameTeamError = true
            messageNameTeam = 'Debe ingresar más de 3 carácteres. '
        }
    }

    var createPermission = false
    if (dateStartErrorTeamWork === false && nameTeamError === false && nameTeamRepeatError === false && dateStartErrorCoordinator === false) {
        createPermission = true
    }


    //Almacenamos los valores en una constante para enviarlas por props

    const client = getClients.id
    const dateEnd = dateEndTeamWork === null ? dateEndTeamWork : CambiarFormatoFecha(dateEndTeamWork)
    const dateStart = dateStartTeamWork === null ? dateStartTeamWork : CambiarFormatoFecha(dateStartTeamWork)
    const nameTeam = nameTeamWork
    const cookies = new Cookies()
    const usuarioSesion = cookies.get('usuario')

    //Datos del contacto cliente
    const [nameContactCustomer, setNameContactCustomer] = useState('');
    const [dateStartContactCustomer, setDateStartContactCustomer] = useState(null);
    const [dateEndContactCustomer, setDateEndContactCustomer] = useState(null);
    const [selectedContactCustomer, setSelectedContactCustomer] = useState(null);
    const [dataContactCustomer, setDataContactCustomer] = useState([]);

    var newArrayDataContactClient = []
    var inactiveContactCustomer
    var newContactCustomer

    if (dataContactCustomer !== null && dataContactCustomer.length !== 0 && dataContactCustomerProp !== undefined) {
        if (dataContactCustomer.id === dataContactCustomerProp.personainteresada.id) {
            newContactCustomer = {
                estado: 1,
                id: dataContactCustomerProp.id,
                fechacreacion: dataContactCustomerProp.fechacreacion,
                fechafinasignacion: dataContactCustomer.dateEnd === null ? dataContactCustomer.dateEnd : CambiarFormatoFecha(dataContactCustomer.dateEnd),
                fechainicioasignacion: CambiarFormatoFecha(dataContactCustomer.dateStart),
                personainteresada: {
                    id: dataContactCustomer.id
                }
            }
        } else {
            inactiveContactCustomer = {
                estado: 0,
                id: dataContactCustomerProp.id,
                fechacreacion: dataContactCustomerProp.fechacreacion,
                fechafinasignacion: dataContactCustomerProp.fechafinasignacion,
                fechainicioasignacion: dataContactCustomerProp.fechainicioasignacion,
                personainteresada: {
                    id: dataContactCustomerProp.personainteresada.id
                }
            }
            newContactCustomer = {
                estado: 1,
                fechacreacion: new Date(),
                fechafinasignacion: dataContactCustomer.dateEnd === null ? dataContactCustomer.dateEnd : CambiarFormatoFecha(dataContactCustomer.dateEnd),
                fechainicioasignacion: CambiarFormatoFecha(dataContactCustomer.dateStart),
                personainteresada: {
                    id: dataContactCustomer.id
                }
            }
        }
        if (inactiveContactCustomer === undefined) {
            newArrayDataContactClient.push(newContactCustomer)
        } else {
            newArrayDataContactClient.push(inactiveContactCustomer, newContactCustomer)
        }
    } else if (dataContactCustomerProp === undefined && dataContactCustomer !== null && dataContactCustomer.length !== 0) {
        newContactCustomer = {
            estado: 1,
            fechacreacion: new Date(),
            fechafinasignacion: dataContactCustomer.dateEnd === null ? dataContactCustomer.dateEnd : CambiarFormatoFecha(dataContactCustomer.dateEnd),
            fechainicioasignacion: CambiarFormatoFecha(dataContactCustomer.dateStart),
            personainteresada: {
                id: dataContactCustomer.id
            }
        }
        newArrayDataContactClient.push(newContactCustomer)
    }

    var nameLeaderError = false
    var messageNameLeaderError = ''
    if (nameCoordinator === '') {
        nameLeaderError = true
        messageNameLeaderError = 'Lider Bloquedo por retiro, debe asociar un nuevo lider'
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar displayBasicProp={false} />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }

                {
                    createPermission ?
                        <ConfirmBtn
                            updateDateProp={true}
                            titulo='Equipo de trabajo'
                            mensaje='¿Desea actualizar su equipo de trabajo?'
                            accion='ActualizarEquipo'
                            label='Actualizar'
                            icono='pi pi-pencil'
                            usuarioSesionProp={usuarioSesion}
                            updateClientTeam={client}
                            createByProp={createdByProp}
                            idUpdate={idProp}
                            dateCreateProp={dateCreatedProp}
                            updateDateEndProp={dateEnd}
                            updateDateStartProp={dateStart}
                            updateCoordinatorTeam={newArrayGroupCoordinator}
                            updateNameTeam={nameTeam}
                            updateOfficialTeam={[]}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicOfficialProp={setDisplayBasic}
                            dataContactClienProp={newArrayDataContactClient}
                            editOfficialProp={true}
                            setNewArrayGroupCoordinatorProp={setNewArrayGroupCoordinator}
                        />
                        :
                        ''
                }

            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <Button icon='pi pi-pencil' className=" p-button-secondary" onClick={() => {
                onClick('displayBasic')
                resetDataII()
            }}
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} style={{ marginTop: '5px' }} />

            <Dialog className="modal" header="Editar equipo de trabajo" visible={displayBasic}
                onHide={() => onHide('displayBasic')} style={{ width: '80vw' }} footer={renderFooter('displayBasic')}>
                <div className="p-fluid p-grid p-formgrid">

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels" htmlFor="tipoDoc">Cliente<span className="require">*</span></label>
                        <Dropdown value={getClients} options={optionClients} onChange={onClientsChange} name="clients" filter
                            optionLabel='clients' emptyMessage="No hay resultados" disabled placeholder="Seleccione el Cliente"
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels">Nombre de equipo de trabajo<span className="require">*</span></label>
                        <InputText value={nameTeamWork} onChange={onNameTeamWorkChange} className={nameTeamRepeatError || nameTeamError ? "p-invalid" : ''} placeholder="Escriba el nombre de su equipo de trabajo" />
                        <p className="mensajeError">{nameTeamError ? messageNameTeam : ""}{nameTeamRepeatError ? messageNameTeamError : ''}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-5">
                        <label className="labels">Nombre Coordinador<span className="require">*</span></label>
                        <InputText value={nameCoordinator} placeholder="Agregar coordinador en el botón" disabled
                            className={nameLeaderError ? "p-invalid" : ''}
                        />
                        <p className="mensajeError">{nameLeaderError ? messageNameLeaderError : ""}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-1">
                        <ModalCoordinators
                            dataCoordinatorProp={dataCoordinator}
                            dateStartCoordinatorIIProp={setDateStartCoordinadorII}
                            dateEndCoordinatorIIProp={setDateEndCoordinadorII}
                            idProp={idProp}
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-5">
                        <label className="labels">Nombre Contacto Cliente<span className="require">*</span></label>
                        <InputText value={nameContactCustomer} placeholder="Agregar contacto cliente en el botón" disabled />
                    </div>

                    <div className="p-field p-col-12 p-md-1">
                        <ModalContactoCliente
                            selectedIdClienteProp={getClients.id}
                            dateStartTeamProp={dateStartTeamWork}
                            dateEndTeamProp={dateEndTeamWork}
                            dateStartProp={dateStartContactCustomer}
                            dateEndProp={dateEndContactCustomer}
                            selectedContactCustomerProp={selectedContactCustomer}
                            setNameContactCustomerProp={setNameContactCustomer}
                            setDataContactCustomerProp={setDataContactCustomer}

                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels">Fecha inicio vigencia del equipo<span className="require">*</span></label>
                        <Calendar className={dateStartErrorTeamWork || dateStartErrorCoordinator ? "p-invalid" : ""} value={dateStartTeamWork} onChange={dateStartOnChange} dateFormat="dd-mm-yy" locale="es" placeholder="Seleccione Fecha de inicio"></Calendar>
                        <p className="mensajeError">{dateStartErrorTeamWork ? messageDateStartTeamWork : ""}{dateStartErrorCoordinator ? messageDateStartCoordinator : ''}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels">Fecha fin vigencia del equipo</label>
                        <Calendar value={dateEndTeamWork} onChange={dateEndOnChange} dateFormat="dd-mm-yy" locale='es' placeholder="Seleccione Fecha Fin"></Calendar>
                    </div>

                </div>
            </Dialog>
        </>
    )
}

export default BtnDetalleEquipo