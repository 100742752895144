import '../../assets/css/Forms.css'
import '../../assets/css/Btn.css'
import '../../assets/css/DialogDemo.css'
import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { addLocale } from 'primereact/api';
import Services from "../../service/Services";
import { CambiarFormatoFecha, removeAccents, SumarDias } from "../Funciones";
import ConfirmBtn from './ConfirmBtn';
import { Toast } from 'primereact/toast';

const BtnActualizarObjetivosEstrategicos = ({ dataProp, runRenderProp, setRunRenderProp, newDataProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false)
    const [projectsSession, setProjectsSession] = useState([]);
    const [validateChanges, setValidateChanges] = useState(false);
    const [errorDuplicity, setErrorDuplicity] = useState(false);
    const toast = useRef(null);

    const getServices = new Services()

    useEffect(() => {

        if (displayBasic === true) {

            getServices.getAsociacionProyectoUsuarioVigente().then(data => {
                if (data !== undefined) {
                    setProjectsSession(data)
                }
            });

        }


    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

        if (displayBasic === true) {
            var d = new Date()
            var gmtHours = -d.getTimezoneOffset() / 60;
            setSelectedClient({
                cliente: dataProp.cliente.razonsocial,
                id: dataProp.cliente.id
            })

            setValueStrategicObjective(dataProp.descripcion)

            let dateStartChange, dateEndChange
            if (dataProp.fechainiciovigencia && dataProp.fechafinvigencia) {
                dateStartChange = new Date(dataProp.fechainiciovigencia)
                dateEndChange = new Date(dataProp.fechafinvigencia)
                if (gmtHours < 0) {
                    setDateEnd(SumarDias(dateEndChange, +1))
                    setDateStart(SumarDias(dateStartChange, +1))
                } else {
                    setDateEnd(dateEndChange)
                    setDateStart(dateStartChange)
                }
            } else {
                setDateEnd(null)
            }
        }

    }, [displayBasic, dataProp])

    const onClick = () => {
        setDisplayBasic(true);
    }


    const onHide = () => {
        setDisplayBasic(false);
        resetData()
    }

    const resetData = () => {
        setValidateChanges(false)
        setSelectedClient({
            cliente: dataProp.cliente.razonsocial,
            id: dataProp.cliente.id
        })
        setValueStrategicObjective(dataProp.descripcion)
        setDateStart(dataProp.fechainiciovigencia)
        setDateEnd(dataProp.fechafinvigencia)
    }

    const renderFooter = () => {

        return (
            <div>
                {validateChanges ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            message={"¿Estás seguro de guardar esta información?"}
                            action={"Update"}
                            label={"Actualizar"}
                            icon="pi pi-plus-circle"
                            idClientProp={selectedClient.id}
                            descripcionProp={valueStrategicObjective}
                            fechaInicioVigenciaProp={CambiarFormatoFecha(dateStart)}
                            fechaFinVigenciaProp={CambiarFormatoFecha(dateEnd)}
                            creadoPorProp={dataProp.creadopor}
                            fechaCreacionProp={dataProp.fechacreacion}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            setSelectedClientProp={setSelectedClient}
                            setValidateChangesProp={setValidateChanges}
                            setDateStartProp={setDateStart}
                            setDateEndProp={setDateEnd}
                            setValueStrategicObjectiveProp={setValueStrategicObjective}
                            dataProp={dataProp}
                            optionUpdateProp={true}
                        />
                        :
                        ''
                }

            </div>
        );
    }

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }



        const Regresar = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    //<--------Seleccionar clientes asociados al usuario en sesión-------->
    let clientsUserSession = []

    projectsSession.forEach(element => {
        clientsUserSession.push({
            id: element.cliente.id,
            razonsocial: element.cliente.nombre
        })
    });
    const tableClients = {}
    const uniqueClients = clientsUserSession.filter(function (object) {
        return tableClients.hasOwnProperty(object.id) ? false : (tableClients[object.id] = true)
    })

    const [selectedClient, setSelectedClient] = useState(null)

    const clients = uniqueClients.map(item => {
        const av = { cliente: item.razonsocial, id: parseInt(item.id) }
        return av
    })

    const onChangeClient = (e) => {
        setSelectedClient(e.value)
        setValidateChanges(e.value)
    }

    //<--------Digitar objetivo estratégico y validar que no sea mayor a 500-------->

    const [valueStrategicObjective, setValueStrategicObjective] = useState('');

    const onChangeStrategicObjective = (e) => {
        setValueStrategicObjective(e.target.value)
        setValidateChanges(true)
    }

    let errorValueStrategicObjective = false
    let messageErrorStrategicObjective = ''
    if (valueStrategicObjective.length > 500) {
        errorValueStrategicObjective = true
        messageErrorStrategicObjective = 'El tamaño de los caracteres debe ser menor a 500'
    }

    //<--------Seleccionar fecha para los objetivos estratégicos-------->
    const [dateStart, setDateStart] = useState(null)
    const [dateEnd, setDateEnd] = useState(null)

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    const onDateStartChange = (e) => {
        setDateStart(e.value)
        setValidateChanges(true)
    }

    const onDateEndChange = (e) => {
        setDateEnd(e.value)
        setValidateChanges(true)
    }

    let errorDate = false
    let messageError = ''
    let newDateStart = dateStart !== null ? CambiarFormatoFecha(dateStart) : null
    let newDateEnd = dateEnd !== null ? CambiarFormatoFecha(dateEnd) : null

    if (newDateStart > newDateEnd) {
        errorDate = true
        messageError = 'La fecha de inicio debe ser anterior a la fecha de fin'
    }

    //<--------Validar que el usuario no pueda guardar si no se llenas los campos obligatorios-------->
    let permisoRegistrar = false
    if (selectedClient !== null && valueStrategicObjective !== '' &&
        newDateStart !== null && newDateEnd !== null && newDateStart <= newDateEnd && errorValueStrategicObjective === false
        && errorDuplicity === false) {
        permisoRegistrar = true
    }

    //<--------Validar duplicidad-------->
    useEffect(() => {

        if (displayBasic === true) {
            if (selectedClient !== null && valueStrategicObjective !== '') {
                
                const exist = newDataProp.some(i => {
                    const newValueStrategicObjective = removeAccents(valueStrategicObjective)
                    const dataValueStrategicObjective = removeAccents(i.descripcion)
                    return dataProp.id !== i.id 
                    && selectedClient.id === i.cliente.id &&
                    dataValueStrategicObjective.toUpperCase() === newValueStrategicObjective.toUpperCase()
                })

                if (exist === true) {
                    setErrorDuplicity(true)
                    toast.current.show({ severity: 'error', summary: 'Error duplicidad', detail: 'Ya existe un registro con igual descripción y cliente', life: 8000 });
                } else {
                    setErrorDuplicity(false)
                }

            }
        }

    }, [selectedClient, valueStrategicObjective, newDataProp, displayBasic, dataProp]);

    return (
        <>
            <Toast ref={toast} />

            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick()}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                style={{ marginTop: '5px' }}
            ></Button>
            <Dialog className="modal" header="Actualizar Objetivos Estratégicos" visible={displayBasic} footer={renderFooter()} onHide={() => onHide()}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >

                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <Dropdown placeholder="Seleccionar Cliente" value={selectedClient} options={clients} onChange={onChangeClient} optionLabel='cliente' filter
                                emptyFilterMessage="No se encontraron resultados" disabled
                            />
                        </div>


                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" >Fecha Inicio Vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={errorDate ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{errorDate ? messageError : ""}</p>

                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" >Fecha Fin Vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"

                            />
                        </div>

                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Objetivo<span className="require">*</span></label>
                            <InputText value={valueStrategicObjective} onChange={onChangeStrategicObjective}
                                id="name" type="text" name="objective" placeholder='Digite el objetivo estratégico'
                                className={errorValueStrategicObjective ? "p-invalid" : ""}

                            />
                            <p className="mensajeError">{errorValueStrategicObjective ? messageErrorStrategicObjective : ""}</p>

                        </div>



                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnActualizarObjetivosEstrategicos