import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../assets/css/Forms.css'

const VerPermisos = ({ idRolProp, rolProp, permisosProp, checkedNotificationProp, checkedPlusPowerBiProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del input rol

    const [inputRol, setInputRol] = useState({
        rol: rolProp
    })


    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setInputRol({
            rol: rolProp
        })

    }, [idRolProp, rolProp])


    //Almacenamos los valores para enviarlos por props
    const rol = inputRol.rol


    const mostrar = JSON.parse(permisosProp);
    const permisos = mostrar.rol;

    ////// Usuarios
    var permisoUserRead
    if (permisos[0].consultar === "true") {
        permisoUserRead = true
    } else {
        permisoUserRead = false
    }
    var permisoUserCreate
    if (permisos[0].permisos[0] === "Crear") {
        permisoUserCreate = true
    } else {
        permisoUserCreate = false
    }
    var permisoUserUpdate
    if (permisos[0].permisos[1] === "Editar") {
        permisoUserUpdate = true
    } else {
        permisoUserUpdate = false
    }
    var permisoUserDelete
    if (permisos[0].permisos[2] === "Eliminar") {
        permisoUserDelete = true
    } else {
        permisoUserDelete = false
    }


    const [checkedUserRead] = useState(permisoUserRead);
    const [checkedUserCreate] = useState(permisoUserCreate);
    const [checkedUserUpdate] = useState(permisoUserUpdate);
    const [checkedUserDelete] = useState(permisoUserDelete);




    ////// Actividades
    var permisoActivityRead
    if (permisos[1].consultar === "true") {
        permisoActivityRead = true
    } else {
        permisoActivityRead = false
    }
    var permisoActivityCreate
    if (permisos[1].permisos[0] === "Crear") {
        permisoActivityCreate = true
    } else {
        permisoActivityCreate = false
    }
    var permisoActivityUpdate
    if (permisos[1].permisos[1] === "Editar") {
        permisoActivityUpdate = true
    } else {
        permisoActivityUpdate = false
    }
    var permisoActivityDelete
    if (permisos[1].permisos[2] === "Eliminar") {
        permisoActivityDelete = true
    } else {
        permisoActivityDelete = false
    }



    const [checkedActivityRead] = useState(permisoActivityRead);
    const [checkedActivityCreate] = useState(permisoActivityCreate);
    const [checkedActivityUpdate] = useState(permisoActivityUpdate);
    const [checkedActivityDelete] = useState(permisoActivityDelete);


    ////// exportar datos

    var permisoExportDataRead
    if (permisos[2].consultar === "true") {
        permisoExportDataRead = true
    } else {
        permisoExportDataRead = false
    }
    var permisoExportDataAll
    if (permisos[2].exportarTodos === "true") {
        permisoExportDataAll = true
    } else {
        permisoExportDataAll = false
    }


    const [checkedExportDataRead] = useState(permisoExportDataRead);
    const [checkedExportDataAll] = useState(permisoExportDataAll);


    //////// Clientes
    var permisoClientRead
    if (permisos[3].consultar === "true") {
        permisoClientRead = true
    } else {
        permisoClientRead = false
    }
    var permisoClientCreate
    if (permisos[3].permisos[0] === "Crear") {
        permisoClientCreate = true
    } else {
        permisoClientCreate = false
    }
    var permisoClientUpdate
    if (permisos[3].permisos[1] === "Editar") {
        permisoClientUpdate = true
    } else {
        permisoClientUpdate = false
    }
    var permisoClientDelete
    if (permisos[3].permisos[2] === "Eliminar") {
        permisoClientDelete = true
    } else {
        permisoClientDelete = false
    }


    const [checkedClientRead] = useState(permisoClientRead);
    const [checkedClientCreate] = useState(permisoClientCreate);
    const [checkedClientUpdate] = useState(permisoClientUpdate);
    const [checkedClientDelete] = useState(permisoClientDelete);

    ////// Proyectos
    var permisoProjectRead
    if (permisos[4].consultar === "true") {
        permisoProjectRead = true
    } else {
        permisoProjectRead = false
    }
    var permisoProjectCreate
    if (permisos[4].permisos[0] === "Crear") {
        permisoProjectCreate = true
    } else {
        permisoProjectCreate = false
    }
    var permisoProjectUpdate
    if (permisos[4].permisos[1] === "Editar") {
        permisoProjectUpdate = true
    } else {
        permisoProjectUpdate = false
    }
    var permisoProjectDelete
    if (permisos[4].permisos[2] === "Eliminar") {
        permisoProjectDelete = true
    } else {
        permisoProjectDelete = false
    }


    const [checkedProjectRead] = useState(permisoProjectRead);
    const [checkedProjectCreate] = useState(permisoProjectCreate);
    const [checkedProjectUpdate] = useState(permisoProjectUpdate);
    const [checkedProjectDelete] = useState(permisoProjectDelete);

    ////// Aplicaciones
    var permisoAplicationRead
    if (permisos[5].consultar === "true") {
        permisoAplicationRead = true
    } else {
        permisoAplicationRead = false
    }
    var permisoAplicationCreate
    if (permisos[5].permisos[0] === "Crear") {
        permisoAplicationCreate = true
    } else {
        permisoAplicationCreate = false
    }
    var permisoAplicationUpdate
    if (permisos[5].permisos[1] === "Editar") {
        permisoAplicationUpdate = true
    } else {
        permisoAplicationUpdate = false
    }
    var permisoAplicationDelete
    if (permisos[5].permisos[2] === "Eliminar") {
        permisoAplicationDelete = true
    } else {
        permisoAplicationDelete = false
    }


    const [checkedAplicationRead] = useState(permisoAplicationRead);
    const [checkedAplicationCreate] = useState(permisoAplicationCreate);
    const [checkedAplicationUpdate] = useState(permisoAplicationUpdate);
    const [checkedAplicationDelete] = useState(permisoAplicationDelete);

    /////// Areas de negocio
    var permisoBusinessAreasRead
    if (permisos[6].consultar === "true") {
        permisoBusinessAreasRead = true
    } else {
        permisoBusinessAreasRead = false
    }
    var permisoBusinessAreasCreate
    if (permisos[6].permisos[0] === "Crear") {
        permisoBusinessAreasCreate = true
    } else {
        permisoBusinessAreasCreate = false
    }
    var permisoBusinessAreasUpdate
    if (permisos[6].permisos[1] === "Editar") {
        permisoBusinessAreasUpdate = true
    } else {
        permisoBusinessAreasUpdate = false
    }
    var permisoBusinessAreasDelete
    if (permisos[6].permisos[2] === "Eliminar") {
        permisoBusinessAreasDelete = true
    } else {
        permisoBusinessAreasDelete = false
    }


    const [checkedBusinessAreasRead] = useState(permisoBusinessAreasRead);
    const [checkedBusinessAreasCreate] = useState(permisoBusinessAreasCreate);
    const [checkedBusinessAreasUpdate] = useState(permisoBusinessAreasUpdate);
    const [checkedBusinessAreasDelete] = useState(permisoBusinessAreasDelete);


    ////// tipo de actividad
    var permisoActivityTypeRead
    if (permisos[7].consultar === "true") {
        permisoActivityTypeRead = true
    } else {
        permisoActivityTypeRead = false
    }
    var permisoActivityTypeCreate
    if (permisos[7].permisos[0] === "Crear") {
        permisoActivityTypeCreate = true
    } else {
        permisoActivityTypeCreate = false
    }
    var permisoActivityTypeUpdate
    if (permisos[7].permisos[1] === "Editar") {
        permisoActivityTypeUpdate = true
    } else {
        permisoActivityTypeUpdate = false
    }
    var permisoActivityTypeDelete
    if (permisos[7].permisos[2] === "Eliminar") {
        permisoActivityTypeDelete = true
    } else {
        permisoActivityTypeDelete = false
    }


    const [checkedActivityTypeRead] = useState(permisoActivityTypeRead);
    const [checkedActivityTypeCreate] = useState(permisoActivityTypeCreate);
    const [checkedActivityTypeUpdate] = useState(permisoActivityTypeUpdate);
    const [checkedActivityTypeDelete] = useState(permisoActivityTypeDelete);


    ////// cargos
    var permisoPositionRead
    if (permisos[8].consultar === "true") {
        permisoPositionRead = true
    } else {
        permisoPositionRead = false
    }
    var permisoPositionCreate
    if (permisos[8].permisos[0] === "Crear") {
        permisoPositionCreate = true
    } else {
        permisoPositionCreate = false
    }
    var permisoPositionUpdate
    if (permisos[8].permisos[1] === "Editar") {
        permisoPositionUpdate = true
    } else {
        permisoPositionUpdate = false
    }
    var permisoPositionDelete
    if (permisos[8].permisos[2] === "Eliminar") {
        permisoPositionDelete = true
    } else {
        permisoPositionDelete = false
    }


    const [checkedPositionRead] = useState(permisoPositionRead);
    const [checkedPositionCreate] = useState(permisoPositionCreate);
    const [checkedPositionUpdate] = useState(permisoPositionUpdate);
    const [checkedPositionDelete] = useState(permisoPositionDelete);


    ////// Roles
    var permisoRolRead
    if (permisos[9].consultar === "true") {
        permisoRolRead = true
    } else {
        permisoRolRead = false
    }
    var permisoRolCreate
    if (permisos[9].permisos[0] === "Crear") {
        permisoRolCreate = true
    } else {
        permisoRolCreate = false
    }
    var permisoRolUpdate
    if (permisos[9].permisos[1] === "Editar") {
        permisoRolUpdate = true
    } else {
        permisoRolUpdate = false
    }
    var permisoRolDelete
    if (permisos[9].permisos[2] === "Eliminar") {
        permisoRolDelete = true
    } else {
        permisoRolDelete = false
    }

    const [checkedRolRead] = useState(permisoRolRead);
    const [checkedRolCreate] = useState(permisoRolCreate);
    const [checkedRolUpdate] = useState(permisoRolUpdate);
    const [checkedRolDelete] = useState(permisoRolDelete);

    ////// Calendario
    var permisoCalendarRead
    if (permisos[10].consultarCalendario === "true") {
        permisoCalendarRead = true
    } else {
        permisoCalendarRead = false
    }
    var permisoCalendarManage
    if (permisos[10].permisos[0] === "Parametrizar") {
        permisoCalendarManage = true
    } else {
        permisoCalendarManage = false
    }

    const [checkedCalendarRead] = useState(permisoCalendarRead);
    const [checkedCalendarManage] = useState(permisoCalendarManage);

    ///Descargar Zip
    var permisoDescargarZip
    if (permisos[11].DescargarZip === "true") {
        permisoDescargarZip = true
    } else {
        permisoDescargarZip = false
    }

    const [checkedDownloadZip] = useState(permisoDescargarZip);


    ///Procesos de cierre
    var permisoRunClose
    if (permisos[12].permisos[1] === "CierreDefinitivo") {
        permisoRunClose = true
    } else {
        permisoRunClose = false
    }

    var permisoCloseOpen
    if (permisos[12].permisos[0] === "AbrirMes") {
        permisoCloseOpen = true
    } else {
        permisoCloseOpen = false
    }

    const [checkedRunClose] = useState(permisoRunClose);
    const [checkedNotification] = useState(checkedNotificationProp);
    const [checkedCloseOpen] = useState(permisoCloseOpen);

    ///Historico
    var permisoHistorico
    if (permisos[14].Historico === "true") {
        permisoHistorico = true
    } else {
        permisoHistorico = false
    }
    const [checkedHistorical] = useState(permisoHistorico);

    ///DashBoards
    var permisoDBActivityLogger
    if (permisos[13].DashBoards === "true") {
        permisoDBActivityLogger = true
    } else {
        permisoDBActivityLogger = false
    }
    var permisoDBWorkload
    if (permisos[13].permisos[0] === "DB1") {
        permisoDBWorkload = true
    } else {
        permisoDBWorkload = false
    }
    var permisoDBDetailTypeActivity
    if (permisos[13].permisos[1] === "DB2") {
        permisoDBDetailTypeActivity = true
    } else {
        permisoDBDetailTypeActivity = false
    }
    var permisoDBDetailTypeActivityUser
    if (permisos[13].permisos[2] === "DB3") {
        permisoDBDetailTypeActivityUser = true
    } else {
        permisoDBDetailTypeActivityUser = false
    }
    const [checkedDBActivityLogger] = useState(permisoDBActivityLogger);
    const [checkedDBWorkload] = useState(permisoDBWorkload);
    const [checkedDBDetailTypeActivity] = useState(permisoDBDetailTypeActivity);
    const [checkedDBDetailTypeActivityUser] = useState(permisoDBDetailTypeActivityUser);

    ///DashBoard DBPowerBI
    var permisoDBReadPowerBi
    if (permisos[22].DBPowerBI === "true") {
        permisoDBReadPowerBi = true
    } else {
        permisoDBReadPowerBi = false
    }
    var permisoDBPowerBiClient
    if (permisos[22].permisos[1] === "powerBiClient") {
        permisoDBPowerBiClient = true
    } else {
        permisoDBPowerBiClient = false
    }
    var permisoDBPlusPowerBi
    if (permisos[22].permisos[0] === "plusPowerBi") {
        permisoDBPlusPowerBi = true
    } else {
        permisoDBPlusPowerBi = false
    }
    const [checkedDBReadPowerBi] = useState(permisoDBReadPowerBi);
    const [checkedDBPowerBiClient] = useState(permisoDBPowerBiClient);
    const [checkedDBPlusPowerBi] = useState(permisoDBPlusPowerBi);

    ////// novedades otro funcionario 

    var permisoNoveltyAnotherOfficial
    if (permisos[1].permisos[3] === "NovedadOtroFucionario") {
        permisoNoveltyAnotherOfficial = true
    } else {
        permisoNoveltyAnotherOfficial = false
    }
    const [checkedNoveltyAnotherOfficial] = useState(permisoNoveltyAnotherOfficial);

    ///Informe mensual individual
    var permisoIndividualMonthlyReport
    if (permisos[15].consultar === "true") {
        permisoIndividualMonthlyReport = true
    } else {
        permisoIndividualMonthlyReport = false
    }
    var permisoIndividualMonthlyReportOtherOfficial
    if (permisos[15].permisos[0] === "true") {
        permisoIndividualMonthlyReportOtherOfficial = true
    } else {
        permisoIndividualMonthlyReportOtherOfficial = false
    }
    var permisoIndividualMonthlyReopen
    if (permisos[15].permisos[1] === "true") {
        permisoIndividualMonthlyReopen = true
    } else {
        permisoIndividualMonthlyReopen = false
    }

    const [checkedindividualMonthlyReportRead] = useState(permisoIndividualMonthlyReport);
    const [checkedindividualMonthlyReportReadOtherOfficial] = useState(permisoIndividualMonthlyReportOtherOfficial);
    const [checkedindividualMonthlyReportReopen] = useState(permisoIndividualMonthlyReopen);

    ///Personas interesadas
    var permisoInterestedPersonRead
    if (permisos[16].consultar === "true") {
        permisoInterestedPersonRead = true
    } else {
        permisoInterestedPersonRead = false
    }
    var permisoInterestedPersonCreate
    if (permisos[16].permisos[0] === "Crear") {
        permisoInterestedPersonCreate = true
    } else {
        permisoInterestedPersonCreate = false
    }
    var permisoInterestedPersonUpdate
    if (permisos[16].permisos[1] === "Editar") {
        permisoInterestedPersonUpdate = true
    } else {
        permisoInterestedPersonUpdate = false
    }
    var permisoInterestedPersonDelete
    if (permisos[16].permisos[2] === "Eliminar") {
        permisoInterestedPersonDelete = true
    } else {
        permisoInterestedPersonDelete = false
    }
    const [checkedInterestedPersonRead] = useState(permisoInterestedPersonRead);
    const [checkedInterestedPersonCreate] = useState(permisoInterestedPersonCreate);
    const [checkedInterestedPersonUpdate] = useState(permisoInterestedPersonUpdate);
    const [checkedInterestedPersonDelete] = useState(permisoInterestedPersonDelete);

    ///Objetivos
    var permisoObjectiveRead
    if (permisos[17].consultar === "true") {
        permisoObjectiveRead = true
    } else {
        permisoObjectiveRead = false
    }
    var permisoObjectiveCreate
    if (permisos[17].permisos[0] === "Crear") {
        permisoObjectiveCreate = true
    } else {
        permisoObjectiveCreate = false
    }
    var permisoObjectiveUpdate
    if (permisos[17].permisos[1] === "Editar") {
        permisoObjectiveUpdate = true
    } else {
        permisoObjectiveUpdate = false
    }
    var permisoObjectiveDelete
    if (permisos[17].permisos[2] === "Eliminar") {
        permisoObjectiveDelete = true
    } else {
        permisoObjectiveDelete = false
    }
    const [checkedObjectiveRead] = useState(permisoObjectiveRead);
    const [checkedObjectiveCreate] = useState(permisoObjectiveCreate);
    const [checkedObjectiveUpdate] = useState(permisoObjectiveUpdate);
    const [checkedObjectiveDelete] = useState(permisoObjectiveDelete);

    ///contratos
    var permisoContractRead
    if (permisos[18].consultar === "true") {
        permisoContractRead = true
    } else {
        permisoContractRead = false
    }
    var permisoContractCreate
    if (permisos[18].permisos[0] === "Crear") {
        permisoContractCreate = true
    } else {
        permisoContractCreate = false
    }
    var permisoContractUpdate
    if (permisos[18].permisos[1] === "Editar") {
        permisoContractUpdate = true
    } else {
        permisoContractUpdate = false
    }
    var permisoContractDelete
    if (permisos[18].permisos[2] === "Eliminar") {
        permisoContractDelete = true
    } else {
        permisoContractDelete = false
    }
    const [checkedContractRead] = useState(permisoContractRead);
    const [checkedContractCreate] = useState(permisoContractCreate);
    const [checkedContractUpdate] = useState(permisoContractUpdate);
    const [checkedContractDelete] = useState(permisoContractDelete);

    ///Entregables
    var permisoDeliverableRead
    if (permisos[19].consultar === "true") {
        permisoDeliverableRead = true
    } else {
        permisoDeliverableRead = false
    }
    var permisoDeliverableCreate
    if (permisos[19].permisos[0] === "Crear") {
        permisoDeliverableCreate = true
    } else {
        permisoDeliverableCreate = false
    }
    var permisoDeliverableUpdate
    if (permisos[19].permisos[1] === "Editar") {
        permisoDeliverableUpdate = true
    } else {
        permisoDeliverableUpdate = false
    }
    var permisoDeliverableDelete
    if (permisos[19].permisos[2] === "Eliminar") {
        permisoDeliverableDelete = true
    } else {
        permisoDeliverableDelete = false
    }
    const [checkedDeliverableRead] = useState(permisoDeliverableRead);
    const [checkedDeliverableCreate] = useState(permisoDeliverableCreate);
    const [checkedDeliverableUpdate] = useState(permisoDeliverableUpdate);
    const [checkedDeliverableDelete] = useState(permisoDeliverableDelete);

    ///Servicios
    var permisoServiceRead
    if (permisos[20].consultar === "true") {
        permisoServiceRead = true
    } else {
        permisoServiceRead = false
    }
    var permisoServiceCreate
    if (permisos[20].permisos[0] === "Crear") {
        permisoServiceCreate = true
    } else {
        permisoServiceCreate = false
    }
    var permisoServiceUpdate
    if (permisos[20].permisos[1] === "Editar") {
        permisoServiceUpdate = true
    } else {
        permisoServiceUpdate = false
    }
    var permisoServiceDelete
    if (permisos[20].permisos[2] === "Eliminar") {
        permisoServiceDelete = true
    } else {
        permisoServiceDelete = false
    }
    const [checkedServiceRead] = useState(permisoServiceRead);
    const [checkedServiceCreate] = useState(permisoServiceCreate);
    const [checkedServiceUpdate] = useState(permisoServiceUpdate);
    const [checkedServiceDelete] = useState(permisoServiceDelete);

    //Equipos De Trabajo
    var permisoWorkTeamRead
    if (permisos[21].consultar === "true") {
        permisoWorkTeamRead = true
    } else {
        permisoWorkTeamRead = false
    }
    var permisoWorkTeamCreate
    if (permisos[21].permisos[0] === "Crear") {
        permisoWorkTeamCreate = true
    } else {
        permisoWorkTeamCreate = false
    }
    var permisoWorkTeamUpdate
    if (permisos[21].permisos[1] === "Editar") {
        permisoWorkTeamUpdate = true
    } else {
        permisoWorkTeamUpdate = false
    }
    var permisoWorkTeamDelete
    if (permisos[21].permisos[2] === "Eliminar") {
        permisoWorkTeamDelete = true
    } else {
        permisoWorkTeamDelete = false
    }

    const [checkedWorkTeamRead] = useState(permisoWorkTeamRead);
    const [checkedWorkTeamCreate] = useState(permisoWorkTeamCreate);
    const [checkedWorkTeamUpdate] = useState(permisoWorkTeamUpdate);
    const [checkedWorkTeamDelete] = useState(permisoWorkTeamDelete);

    //Tipos de proyectos
    var permisoTypeProjectRead
    if (permisos[23].consultar === "true") {
        permisoTypeProjectRead = true
    } else {
        permisoTypeProjectRead = false
    }
    var permisoTypeProjectCreate
    if (permisos[23].permisos[0] === "Crear") {
        permisoTypeProjectCreate = true
    } else {
        permisoTypeProjectCreate = false
    }
    var permisoTypeProjectUpdate
    if (permisos[23].permisos[1] === "Editar") {
        permisoTypeProjectUpdate = true
    } else {
        permisoTypeProjectUpdate = false
    }
    var permisoTypeProjectDelete
    if (permisos[23].permisos[2] === "Eliminar") {
        permisoTypeProjectDelete = true
    } else {
        permisoTypeProjectDelete = false
    }

    const [checkedTypeProjectRead] = useState(permisoTypeProjectRead);
    const [checkedTypeProjectCreate] = useState(permisoTypeProjectCreate);
    const [checkedTypeProjectUpdate] = useState(permisoTypeProjectUpdate);
    const [checkedTypeProjectDelete] = useState(permisoTypeProjectDelete);

    //Organigrama
    var permisoOrganizationChart
    if (permisos[24].consultar === "true") {
        permisoOrganizationChart = true
    } else {
        permisoOrganizationChart = false
    }

    const [checkedOrgRead] = useState(permisoOrganizationChart);

    //detalle de pendientes

    var permisoDetailPendingRead
    if (permisos[25].consultar === "true") {
        permisoDetailPendingRead = true
    } else {
        permisoDetailPendingRead = false
    }

    const [checkedDetailPendingRead] = useState(permisoDetailPendingRead);

    //Consultar usuarios de clientes

    var permisoUserClientRead
    if (permisos[26].consultar === "true") {
        permisoUserClientRead = true
    } else {
        permisoUserClientRead = false
    }

    const [checkedUserClientRead] = useState(permisoUserClientRead);

    //Proveedores
    var permisoSupplierRead
    if (permisos[27].consultar === "true") {
        permisoSupplierRead = true
    } else {
        permisoSupplierRead = false
    }
    var permisoSupplierCreate
    if (permisos[27].permisos[0] === "Crear") {
        permisoSupplierCreate = true
    } else {
        permisoSupplierCreate = false
    }
    var permisoSupplierUpdate
    if (permisos[27].permisos[1] === "Editar") {
        permisoSupplierUpdate = true
    } else {
        permisoSupplierUpdate = false
    }
    var permisoSupplierDelete
    if (permisos[27].permisos[2] === "Eliminar") {
        permisoSupplierDelete = true
    } else {
        permisoSupplierDelete = false
    }

    const [checkedSupplierRead] = useState(permisoSupplierRead);
    const [checkedSupplierCreate] = useState(permisoSupplierCreate);
    const [checkedSupplierUpdate] = useState(permisoSupplierUpdate);
    const [checkedSupplierDelete] = useState(permisoSupplierDelete);

    //Filtros Proveedores
    var permisoFilterSupplierNDB1
    if (permisos[28].permisos[1] === "NDB1") {
        permisoFilterSupplierNDB1 = true
    } else {
        permisoFilterSupplierNDB1 = false
    }
    var permisoFilterSupplierNDB2
    if (permisos[28].permisos[2] === "NDB2") {
        permisoFilterSupplierNDB2 = true
    } else {
        permisoFilterSupplierNDB2 = false
    }
    var permisoFilterSupplierExportData
    if (permisos[29].consultar === "true") {
        permisoFilterSupplierExportData = true
    } else {
        permisoFilterSupplierExportData = false
    }

    const [checkedFilterSupplierNDB1] = useState(permisoFilterSupplierNDB1);
    const [checkedFilterSupplierNDB2] = useState(permisoFilterSupplierNDB2);
    const [checkedFilterSupplierExportData] = useState(permisoFilterSupplierExportData);

    // Permisos para powerbi con filtro de proveedores

    var permisoDBReadPowerBiFilterSupplier
    if (permisos[30].DBPowerBISupplier === "true") {
        permisoDBReadPowerBiFilterSupplier = true
    } else {
        permisoDBReadPowerBiFilterSupplier = false
    }
    var permisoDBPlusPowerBiFilterSupplier
    if (permisos[30].permisos[0] === "plusPowerBiSupplier") {
        permisoDBPlusPowerBiFilterSupplier = true
    } else {
        permisoDBPlusPowerBiFilterSupplier = false
    }
    var permisoDBPowerBiFilterSupplierClient
    if (permisos[30].permisos[1] === "powerBiSupplierClient") {
        permisoDBPowerBiFilterSupplierClient = true
    } else {
        permisoDBPowerBiFilterSupplierClient = false
    }

    const [checkedDBReadPowerBiFilterSupplier] = useState(permisoDBReadPowerBiFilterSupplier);
    const [checkedDBPowerBiFilterSupplierClient] = useState(permisoDBPowerBiFilterSupplierClient);
    const [checkedDBPlusPowerBiFilterSupplier] = useState(permisoDBPlusPowerBiFilterSupplier);

    /// Permisos para el menú de cargar imagen corporativa

    let permisoReadUploadImage = false
    if (permisos[31].consultar === "true") {
        permisoReadUploadImage = true
    } else {
        permisoReadUploadImage = false
    }
    const [checkedBDReadUploadImage] = useState(permisoReadUploadImage);

    //Parametrizar objetivos estratégicos

    let permisoStrategicObjectivesRead = false
    if (permisos[32].consultar === "true") {
        permisoStrategicObjectivesRead = true
    }

    let permisoStrategicObjectivesCreate = false
    if (permisos[32].permisos[0] === "Crear") {
        permisoStrategicObjectivesCreate = true
    }

    let permisoStrategicObjectivesUpdate = false
    if (permisos[32].permisos[1] === "Editar") {
        permisoStrategicObjectivesUpdate = true
    }

    let permisoStrategicObjectivesDelete = false
    if (permisos[32].permisos[2] === "Eliminar") {
        permisoStrategicObjectivesDelete = true
    }

    const [checkedStratetigicObjectivesRead] = useState(permisoStrategicObjectivesRead);
    const [checkedStratetigicObjectivesCreate] = useState(permisoStrategicObjectivesCreate);
    const [checkedStratetigicObjectivesUpdate] = useState(permisoStrategicObjectivesUpdate);
    const [checkedStratetigicObjectivesDelete] = useState(permisoStrategicObjectivesDelete);


    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const dt = useRef(null);


    const User = 'Parametrizar usuario'
    const UserRead = <Checkbox checked={checkedUserRead} disabled />
    const UserCreate = <Checkbox checked={checkedUserCreate} disabled />
    const UserUpdate = <Checkbox checked={checkedUserUpdate} disabled />
    const UserDelete = <Checkbox checked={checkedUserDelete} disabled />

    const Activity = 'Parametrizar actividades'
    const ActivityRead = <Checkbox checked={checkedActivityRead} disabled />
    const ActivityCreate = <Checkbox checked={checkedActivityCreate} disabled />
    const ActivityUpdate = <Checkbox checked={checkedActivityUpdate} disabled />
    const ActivityDelete = <Checkbox checked={checkedActivityDelete} disabled />

    const ExportData = 'Exportar datos'
    const ExportDataRead = <Checkbox checked={checkedExportDataRead} disabled />
    const ExportDataAll = <Checkbox checked={checkedExportDataAll} disabled tooltip="Exportar para todos" />

    const Client = 'Parametrizar cliente'
    const ClientRead = <Checkbox checked={checkedClientRead} disabled />
    const ClientCreate = <Checkbox checked={checkedClientCreate} disabled />
    const ClientUpdate = <Checkbox checked={checkedClientUpdate} disabled />
    const ClientDelete = <Checkbox checked={checkedClientDelete} disabled />

    const Project = 'Parametrizar proyecto'
    const ProjectRead = <Checkbox checked={checkedProjectRead} disabled />
    const ProjectCreate = <Checkbox checked={checkedProjectCreate} disabled />
    const ProjectUpdate = <Checkbox checked={checkedProjectUpdate} disabled />
    const ProjectDelete = <Checkbox checked={checkedProjectDelete} disabled />

    const Aplication = 'Parametrizar aplicación'
    const AplicationRead = <Checkbox checked={checkedAplicationRead} disabled />
    const AplicationCreate = <Checkbox checked={checkedAplicationCreate} disabled />
    const AplicationUpdate = <Checkbox checked={checkedAplicationUpdate} disabled />
    const AplicationDelete = <Checkbox checked={checkedAplicationDelete} disabled />

    const BusinessAreas = 'Parametrizar área de negocio'
    const BusinessAreasRead = <Checkbox checked={checkedBusinessAreasRead} disabled />
    const BusinessAreasCreate = <Checkbox checked={checkedBusinessAreasCreate} disabled />
    const BusinessAreasUpdate = <Checkbox checked={checkedBusinessAreasUpdate} disabled />
    const BusinessAreasDelete = <Checkbox checked={checkedBusinessAreasDelete} disabled />

    const ActivityType = 'Parametrizar tipo Actividad'
    const ActivityTypeRead = <Checkbox checked={checkedActivityTypeRead} disabled />
    const ActivityTypeCreate = <Checkbox checked={checkedActivityTypeCreate} disabled />
    const ActivityTypeUpdate = <Checkbox checked={checkedActivityTypeUpdate} disabled />
    const ActivityTypeDelete = <Checkbox checked={checkedActivityTypeDelete} disabled />

    const Position = 'Parametrizar cargo'
    const PositionRead = <Checkbox checked={checkedPositionRead} disabled />
    const PositionCreate = <Checkbox checked={checkedPositionCreate} disabled />
    const PositionUpdate = <Checkbox checked={checkedPositionUpdate} disabled />
    const PositionDelete = <Checkbox checked={checkedPositionDelete} disabled />

    const Rol = 'Parametrizar rol'
    const RolRead = <Checkbox checked={checkedRolRead} disabled />
    const RolCreate = <Checkbox checked={checkedRolCreate} disabled />
    const RolUpdate = <Checkbox checked={checkedRolUpdate} disabled />
    const RolDelete = <Checkbox checked={checkedRolDelete} disabled />

    const Calendar = 'Parametrizar Calendario'
    const CalendarRead = <Checkbox checked={checkedCalendarRead} disabled />
    const CalendarManage = <Checkbox checked={checkedCalendarManage} disabled tooltip="Parametrizar" />

    const DownloadZip = 'Descargar Zip'
    const DownloadZipRead = <Checkbox checked={checkedDownloadZip} disabled tooltip="Descargar Zip" />

    const Closes = 'Procesos de Cierre'
    const RunClose = <Checkbox checked={checkedRunClose} disabled tooltip="Cierre Definitivo" />
    const Notification = <Checkbox checked={checkedNotification} disabled tooltip="Quien lo tenga marcado recibirá mensajes semanales y mensuales" />
    const CloseOpen = <Checkbox checked={checkedCloseOpen} disabled tooltip="Abrir Mes" />

    const Historical = 'Histórico'
    const HistoricalRead = <Checkbox checked={checkedHistorical} disabled />

    const DashBoards = 'DashBoards'
    const DBActivityLogger = <Checkbox checked={checkedDBActivityLogger} disabled tooltip="Registrador de actividades" />
    const DBWorkload = <Checkbox checked={checkedDBWorkload} disabled tooltip="Carga de trabajo y proyectos" />
    const DBDetailTypeActivity = <Checkbox checked={checkedDBDetailTypeActivity} disabled tooltip="Detalle tipo de actividad" />
    const DBDetailTypeActivityUser = <Checkbox checked={checkedDBDetailTypeActivityUser} disabled tooltip="Detalle tipo de actividad funcionario" />

    const DBPowerBi = 'Dashboard Powerbi'
    const DBReadPowerBi = <Checkbox checked={checkedDBReadPowerBi} disabled />
    const DBPowerBiClient = <Checkbox checked={checkedDBPowerBiClient} disabled tooltip="Dashboard de powerbi de cliente" />
    const DBPlusPowerBi = <Checkbox checked={checkedDBPlusPowerBi} disabled tooltip="Dashboard de powerbi con licencia" />

    const NoveltyAnotherOfficial = 'Novedades otro funcionario'
    const NoveltyAnotherOfficialManagement = <Checkbox checked={checkedNoveltyAnotherOfficial} disabled tooltip="Gestionar novedades a otros funcionarios" />

    const IndividualMonthlyReport = 'Informe mensual individual'
    const IndividualMonthlyReportRead = <Checkbox checked={checkedindividualMonthlyReportRead} disabled />
    const IndividualMonthlyReportReadOtherOfficial = <Checkbox checked={checkedindividualMonthlyReportReadOtherOfficial} disabled tooltip="Consultar informe mensual otro funcionario" />
    const IndividualMonthlyReportReopen = <Checkbox checked={checkedindividualMonthlyReportReopen} disabled tooltip="Reabrir informe mensual" />

    const InterestedPerson = 'Parametrizar personas interesadas'
    const InterestedPersonRead = <Checkbox checked={checkedInterestedPersonRead} disabled />
    const InterestedPersonCreate = <Checkbox checked={checkedInterestedPersonCreate} disabled />
    const InterestedPersonUpdate = <Checkbox checked={checkedInterestedPersonUpdate} disabled />
    const InterestedPersonDelete = <Checkbox checked={checkedInterestedPersonDelete} disabled />

    const Objective = 'Parametrizar objetivos'
    const ObjectiveRead = <Checkbox checked={checkedObjectiveRead} disabled />
    const ObjectiveCreate = <Checkbox checked={checkedObjectiveCreate} disabled />
    const ObjectiveUpdate = <Checkbox checked={checkedObjectiveUpdate} disabled />
    const ObjectiveDelete = <Checkbox checked={checkedObjectiveDelete} disabled />

    const Contract = 'Parametrizar contratos'
    const ContractRead = <Checkbox checked={checkedContractRead} disabled />
    const ContractCreate = <Checkbox checked={checkedContractCreate} disabled />
    const ContractUpdate = <Checkbox checked={checkedContractUpdate} disabled />
    const ContractDelete = <Checkbox checked={checkedContractDelete} disabled />

    const Deliverable = 'Parametrizar entregables'
    const DeliverableRead = <Checkbox checked={checkedDeliverableRead} disabled />
    const DeliverableCreate = <Checkbox checked={checkedDeliverableCreate} disabled />
    const DeliverableUpdate = <Checkbox checked={checkedDeliverableUpdate} disabled />
    const DeliverableDelete = <Checkbox checked={checkedDeliverableDelete} disabled />

    const Service = 'Parametrizar servicios'
    const ServiceRead = <Checkbox checked={checkedServiceRead} disabled />
    const ServiceCreate = <Checkbox checked={checkedServiceCreate} disabled />
    const ServiceUpdate = <Checkbox checked={checkedServiceUpdate} disabled />
    const ServiceDelete = <Checkbox checked={checkedServiceDelete} disabled />

    const EquipoTrabajo = 'Parametrizar equipos de trabajo'
    const EquipoRead = <Checkbox checked={checkedWorkTeamRead} disabled />
    const EquipoCreate = <Checkbox checked={checkedWorkTeamCreate} disabled />
    const EquipoUpdate = <Checkbox checked={checkedWorkTeamUpdate} disabled />
    const EquipoDelete = <Checkbox checked={checkedWorkTeamDelete} disabled />

    const TipoProyecto = 'Parametrizar tipos de proyectos'
    const TipoProyectoRead = <Checkbox checked={checkedTypeProjectRead} disabled />
    const TipoProyectoCreate = <Checkbox checked={checkedTypeProjectCreate} disabled />
    const TipoProyectoUpdate = <Checkbox checked={checkedTypeProjectUpdate} disabled />
    const TipoProyectoDelete = <Checkbox checked={checkedTypeProjectDelete} disabled />

    const organizationChart = 'Organigrama'
    const organizationChartRead = <Checkbox checked={checkedOrgRead} disabled />

    const DetailPending = 'Detalle de pendientes'
    const DetailPendingRead = <Checkbox checked={checkedDetailPendingRead} disabled />

    const UserClient = 'Consultar usuarios de clientes'
    const UserClientRead = <Checkbox checked={checkedUserClientRead} disabled />

    const Supplier = 'Parametrizar proveedores'
    const SupplierRead = <Checkbox checked={checkedSupplierRead} disabled />
    const SupplierCreate = <Checkbox checked={checkedSupplierCreate} disabled />
    const SupplierUpdate = <Checkbox checked={checkedSupplierUpdate} disabled />
    const SupplierDelete = <Checkbox checked={checkedSupplierDelete} disabled />

    const FilterSupplier = 'Filtros de proveedor'
    const FilterSupplierNDB1 = <Checkbox checked={checkedFilterSupplierNDB1} disabled tooltip="Dashboard detalle tipos de actividad con filtro de proveedor" />
    const FilterSupplierNDB2 = <Checkbox checked={checkedFilterSupplierNDB2} disabled tooltip="Dashboard tipos de actividad - funcionario con filtro de proveedor" />
    const FilterSupplierExportData = <Checkbox checked={checkedFilterSupplierExportData} disabled tooltip="Exportar datos con filtro de proveedor" />

    const DBPowerBiFilterSupplier = 'Dashboard Powerbi con filtro de proveedor'
    const DBReadPowerBiFilterSupplier = <Checkbox checked={checkedDBReadPowerBiFilterSupplier} disabled />
    const DBPowerBiFilterSupplierClient = <Checkbox checked={checkedDBPowerBiFilterSupplierClient} disabled tooltip="Dashboard de powerbi de cliente" />
    const DBPlusPowerBiFilterSupplier = <Checkbox checked={checkedDBPlusPowerBiFilterSupplier} disabled tooltip="Dashboard de powerbi con licencia" />


    const uploadImage = 'Cargar Imagen Corporativa'
    const uploadImageRead = <Checkbox checked={checkedBDReadUploadImage} disabled tooltip="Menú de Cargar Imagen Corporativa" />

    //Parametrizar objetivos estrátegicos
    const strategicObjectives = 'Objetivos Estratégicos'
    const strategicObjectivesRead = <Checkbox checked={checkedStratetigicObjectivesRead} disabled />
    const strategicObjectivesCreate = <Checkbox checked={checkedStratetigicObjectivesCreate} disabled />
    const strategicObjectivesUpdate = <Checkbox checked={checkedStratetigicObjectivesUpdate} disabled />
    const strategicObjectivesDelete = <Checkbox checked={checkedStratetigicObjectivesDelete} disabled />
   
    const TablaPermisos = [

        { "options": User, "read": UserRead, "create": UserCreate, "update": UserUpdate, "delete": UserDelete },
        { "options": Activity, "read": ActivityRead, "create": ActivityCreate, "update": ActivityUpdate, "delete": ActivityDelete },
        { "options": ExportData, "read": ExportDataRead, "create": ExportDataAll },
        { "options": Client, "read": ClientRead, "create": ClientCreate, "update": ClientUpdate, "delete": ClientDelete },
        { "options": Project, "read": ProjectRead, "create": ProjectCreate, "update": ProjectUpdate, "delete": ProjectDelete },
        { "options": Aplication, "read": AplicationRead, "create": AplicationCreate, "update": AplicationUpdate, "delete": AplicationDelete },
        { "options": BusinessAreas, "read": BusinessAreasRead, "create": BusinessAreasCreate, "update": BusinessAreasUpdate, "delete": BusinessAreasDelete },
        { "options": ActivityType, "read": ActivityTypeRead, "create": ActivityTypeCreate, "update": ActivityTypeUpdate, "delete": ActivityTypeDelete },
        { "options": Position, "read": PositionRead, "create": PositionCreate, "update": PositionUpdate, "delete": PositionDelete },
        { "options": Rol, "read": RolRead, "create": RolCreate, "update": RolUpdate, "delete": RolDelete },
        { "options": Calendar, "read": CalendarRead, "create": CalendarManage },
        { "options": DownloadZip, "read": DownloadZipRead },
        { "options": Closes, "read": RunClose, "update": Notification, "create": CloseOpen },
        { "options": Historical, "read": HistoricalRead },
        { "options": DashBoards, "read": DBActivityLogger, "create": DBWorkload, "update": DBDetailTypeActivity, "delete": DBDetailTypeActivityUser },
        { "options": DBPowerBi, "read": DBReadPowerBi, "create": DBPlusPowerBi, "update": DBPowerBiClient },
        { "options": NoveltyAnotherOfficial, "read": NoveltyAnotherOfficialManagement },
        { "options": IndividualMonthlyReport, "read": IndividualMonthlyReportRead, "create": IndividualMonthlyReportReadOtherOfficial, "update": IndividualMonthlyReportReopen },
        { "options": InterestedPerson, "read": InterestedPersonRead, "create": InterestedPersonCreate, "update": InterestedPersonUpdate, "delete": InterestedPersonDelete },
        { "options": Objective, "read": ObjectiveRead, "create": ObjectiveCreate, "update": ObjectiveUpdate, "delete": ObjectiveDelete },
        { "options": Contract, "read": ContractRead, "create": ContractCreate, "update": ContractUpdate, "delete": ContractDelete },
        { "options": Deliverable, "read": DeliverableRead, "create": DeliverableCreate, "update": DeliverableUpdate, "delete": DeliverableDelete },
        { "options": Service, "read": ServiceRead, "create": ServiceCreate, "update": ServiceUpdate, "delete": ServiceDelete },
        { "options": EquipoTrabajo, "read": EquipoRead, "create": EquipoCreate, "update": EquipoUpdate, "delete": EquipoDelete },
        { "options": TipoProyecto, "read": TipoProyectoRead, "create": TipoProyectoCreate, "update": TipoProyectoUpdate, "delete": TipoProyectoDelete },
        { "options": organizationChart, "read": organizationChartRead },
        { "options": DetailPending, "read": DetailPendingRead },
        { "options": UserClient, "read": UserClientRead },
        { "options": Supplier, "read": SupplierRead, "create": SupplierCreate, "update": SupplierUpdate, "delete": SupplierDelete },
        { "options": FilterSupplier, "read": FilterSupplierNDB1, "create": FilterSupplierNDB2, "update": FilterSupplierExportData },
        { "options": DBPowerBiFilterSupplier, "read": DBReadPowerBiFilterSupplier, "create": DBPlusPowerBiFilterSupplier, "update": DBPowerBiFilterSupplierClient },
        { "options": uploadImage, "read": uploadImageRead },
        { "options": strategicObjectives, "read": strategicObjectivesRead, "create": strategicObjectivesCreate, "update": strategicObjectivesUpdate, "delete": strategicObjectivesDelete },
    ]

    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Permisos" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Permisos asignados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Rol</label>
                            <InputText id="rol" type="text" name="rol" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} value={rol}
                                placeholder="Registre el nombre del rol" disabled
                            />
                        </div>
                    </form>
                </div>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={TablaPermisos} className="p-datatable-responsive-demo" paginator rows={10} >
                            <Column className="p-column" field="options" header="Opciones" />
                            <Column className="p-column" field="read" header="Consultar" />
                            <Column className="p-column" field="create" header="Crear" />
                            <Column className="p-column" field="update" header="Editar" />
                            <Column className="p-column" field="delete" header="Eliminar" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default VerPermisos