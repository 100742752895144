import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';

import Cookies from 'universal-cookie'
import { CambiarFormatoFecha } from '../Funciones';
const cookies = new Cookies()

const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/strategicObjectives/update'
const urlCreate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/strategicObjectives/create'

const ConfirmBtn = ({ tittle, message, action, icon, classProp,
    label, tooltipProp, descripcionProp, idClientProp, objetivosAsociadosProp,
    fechaInicioVigenciaProp, fechaFinVigenciaProp, runRenderProp, setRunRenderProp,
    setDisplayBasicProp, setSelectedClientProp, setValidateChangesProp, setDateStartProp,
    setDateEndProp, setValueStrategicObjectiveProp, fechaCreacionProp, creadoPorProp,
    dataProp, idStrategicObjectiveProp, optionUpdateProp, setSelectedObjectiveProp, createAssociationsProp }) => {


    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabledButton, setDisabledButton] = useState(false);

    const service = () => {
        if (action === "Create") {
            createStrategicObjective()
            setDisabledButton(true)
        } else if (action === "Update") {
            updateStrategicObjective()
            setDisabledButton(true)
        } else if (action === "Delete") {
            setDisabledButton(true)
            deleteObjective()
        }
    }

    const usuario = cookies.get('usuario')
    const createStrategicObjective = async () => {

        const json = JSON.stringify(
            {
                descripcion: descripcionProp,
                cliente: {
                    id: idClientProp
                },
                objetivosasociados: null,
                estado: fechaFinVigenciaProp >= CambiarFormatoFecha(new Date()) ? 1 : 0,
                fechainiciovigencia: fechaInicioVigenciaProp,
                fechafinvigencia: fechaFinVigenciaProp,
                fechacreacion: new Date(),
                fechaactualizado: "",
                creadopor: usuario,
                actualizadopor: ""
            }
        )

        await axios.post(urlCreate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                setRunRenderProp(runRenderProp + 1)
                setDisplayConfirmation(false)
                setDisplayBasicProp(false)
                setSelectedClientProp(null)
                setValidateChangesProp(false)
                setDateStartProp(null)
                setDateEndProp(null)
                setDateEndProp(null)
                setValueStrategicObjectiveProp('')
                //console.log(response.data)

            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });
            })
    }


    const updateStrategicObjective = async () => {

        const json = JSON.stringify(
            {

                id: dataProp.id,
                descripcion: descripcionProp,
                cliente: {
                    id: idClientProp
                },
                objetivosasociados: optionUpdateProp ? null : objetivosAsociadosProp,
                estado: fechaFinVigenciaProp >= CambiarFormatoFecha(new Date()) ? 1 : 0,
                fechainiciovigencia: fechaInicioVigenciaProp,
                fechafinvigencia: fechaFinVigenciaProp,
                fechacreacion: fechaCreacionProp,
                fechaactualizado: new Date(),
                creadopor: creadoPorProp,
                actualizadopor: usuario

            })


        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                setRunRenderProp(runRenderProp + 1)
                setDisplayConfirmation(false)
                setDisplayBasicProp(false)
                setValidateChangesProp(false)
                if (optionUpdateProp === true) {

                    setSelectedClientProp({
                        cliente: dataProp.cliente.razonsocial,
                        id: dataProp.cliente.id
                    })
                    setValueStrategicObjectiveProp(dataProp.descripcion)
                    setDateStartProp(dataProp.fechainiciovigencia)
                    setDateEndProp(dataProp.fechafinvigencia)
                } else {
                    if (createAssociationsProp === true) {
                        setSelectedObjectiveProp([])
                    }
                }


            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response?.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sitema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });
                }
            })
    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/strategicObjectives/delete?id=' + idStrategicObjectiveProp
    const deleteObjective = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                setRunRenderProp(runRenderProp + 1)


            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>

                <>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabledButton} />
                </>

            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp} style={{ margin: '5px 2px 0px 5px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">

                    <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content-modal">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{message}</span>
                        </div>
                    </Dialog>

                </div>
            </div>
        </>
    )
}

export default ConfirmBtn