import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect';
import ConfirmBtn from './ConfirmBtn';

const BtnActualizarAsociacion = ({ servicesObjectivesByClientProp, dataProp,
  runRenderProp, setRunRenderProp, newRowDataProp }) => {
  const [displayBasic, setDisplayBasic] = useState(false)
  const [validateChanges, setValidateChanges] = useState(false)

  const onClick = () => {
    setDisplayBasic(true);
  }

  const onHide = () => {
    setDisplayBasic(false);
    resetData()
  }

  const resetData = () => {
    setValidateChanges(false);
    const newData = newRowDataProp.map(item => {
      return {
        id: item.id,
        descripcion: item.descripcion
      }
    })
    setSelectedObjective(newData);
  }

  //<--------Seleccionar objetivos asociados al cliente asociado al objetivo estratégico-------->

  const [selectedObjective, setSelectedObjective] = useState([])
  const [obj, setObj] = useState({})
  useEffect(() => {
    const updatedObj = selectedObjective.map(item => ({
      id: item.id,
      descripcion: item.descripcion
    }));

    setObj(JSON.stringify(updatedObj));

  }, [selectedObjective])

  useEffect(() => {
    if (displayBasic === true) {
      const newData = newRowDataProp.map(item => {
        return {
          id: item.id,
          descripcion: item.descripcion
        }
      })
      setSelectedObjective(newData);
    }

  }, [newRowDataProp, displayBasic])


  const tableObjectives = {}
  const uniqueObjectives = servicesObjectivesByClientProp.filter(function (object) {
      return tableObjectives.hasOwnProperty(object.id) ? false : (tableObjectives[object.id] = true)
  })
  const onObjectiveChange = (e) => {
    setSelectedObjective(e.value)
    setValidateChanges(true)
  }
  const renderFooter = () => {

    return (
      <div>
        {validateChanges ?
          <ModalRegresar />
          :
          <Button label="Regresar" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
        }
        <ConfirmBtn
          tittle="Confirmar Actualización"
          message={"¿Estás seguro de guardar esta información?"}
          action={"Update"}
          label={"Guardar"}
          icon="pi pi-plus-circle"
          idClientProp={dataProp.cliente.id}
          descripcionProp={dataProp.descripcion}
          fechaInicioVigenciaProp={dataProp.fechainiciovigencia}
          fechaFinVigenciaProp={dataProp.fechafinvigencia}
          creadoPorProp={dataProp.creadopor}
          fechaCreacionProp={dataProp.fechacreacion}
          runRenderProp={runRenderProp}
          setRunRenderProp={setRunRenderProp}
          setDisplayBasicProp={setDisplayBasic}
          setValidateChangesProp={setValidateChanges}
          dataProp={dataProp}
          optionUpdateProp={false}
          objetivosAsociadosProp={obj}
        />



      </div>
    );
  }

  const ModalRegresar = () => {

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
      'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
      dialogFuncMap[`${name}`](true);

      if (position) {
        setPosition(position);
      }
    }

    const onHide = (name) => {
      dialogFuncMap[`${name}`](false);
    }



    const Regresar = (name) => {
      resetData()
      setDisplayBasic(false)
      dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
      return (
        <div>
          <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
          <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
        </div>
      );
    }

    return (
      <>
        <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

        <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

          </div>
        </Dialog>

      </>
    )
  }


  return (
    <>
      <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick()}
        tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
        style={{ marginTop: '5px' }}
      ></Button>
      <Dialog className="modalConfirm" header="Actualizar Objetivos" visible={displayBasic} footer={renderFooter()} onHide={() => onHide()}>
        <div className="p-fluid p-formgrid p-grid">
          <form style={{ display: 'contents' }} >

            <div className="p-field p-col-12 p-md-12">
              <label className="labels" >Objetivo</label>
              <MultiSelect value={selectedObjective} options={uniqueObjectives} onChange={onObjectiveChange} placeholder="Seleccionar Objetivo" optionLabel='descripcion' filter
                emptyFilterMessage="No se encontraron resultados"
              />
            </div>
          </form>
        </div>
      </Dialog>
    </>
  )
}


export default BtnActualizarAsociacion