import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox'
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import '../../assets/css/estilo.css'
import Services from '../../service/Services';
import ConfirmBtn from '../ConsultarUsuarios/ConfirmBtn';
import Cookies from 'universal-cookie';
import ModalContactClient from './BtnContactoCliente';
import { CambiarFormatoFecha } from '../Funciones';
import { Toast } from 'primereact/toast';
const cookies = new Cookies()

const CrearAsociacionFuncionario = ({ runRenderProp, setRunRenderProp, nameTeamWorkProp, activeCoordinatorProp }) => {

    const [customerInformation, setCustomerInformation] = useState([]);
    const [servicesUserClients, setUserClients] = useState([]);
    const [idClienteConsulta, setIdClienteConsulta] = useState(null)
    const [userServices, setUserServices] = useState([])
    const getServices = new Services()
    const toast = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false)
    useEffect(() => {
        if (displayBasic === true) {
            getServices.getProyectosUsuarios().then(data => {
                var nameClients = []

                data.forEach(element => {
                    var clients = {}
                    clients.id = element.cliente.id
                    clients.name = element.cliente.nombre
                    nameClients.push(clients)
                })

                const clientsTable = {}
                const uniqueClients = nameClients.filter(function (object) {

                    return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                })
                setCustomerInformation(uniqueClients)

            })

        }
    }, [displayBasic]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (displayBasic === true) {
            getServices.getUsuarios().then(data => {
                if (idClienteConsulta !== null) {
                    getServices.getUsuariosAsociadosCliente(idClienteConsulta).then(data => setUserClients(data))
                    getServices.getUsuariosAsociadosCliente(idClienteConsulta).then(newData => {
                        var arr = []
                        newData.forEach(element => {
                            const result = data.filter(item => {
                                return item.idUsuario === element.id
                            })
                            const activeUsers = result.filter(item => {
                                return item.estado === 1 || item.estado === 0
                            })
                            activeUsers.forEach(i => {
                                arr.push(i)
                            })
                        })
                        setUserServices(arr)
                    })
                }
            })
        }

    }, [idClienteConsulta]) // eslint-disable-line react-hooks/exhaustive-deps

    const [validateChange, setValidateChange] = useState(false)

    const [date1, setDate1] = useState(null)

    const onDateStartChange = (e) => {
        setDate1(e.value)
        setValidateChange(true)
    }

    const [date2, setDate2] = useState(null)

    const onDateEndChange = (e) => {
        setDate2(e.value)
        setValidateChange(true)
    }

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [selectedCustomer, setSelectedCustomer] = useState({
        customer: '',
        id: '',
        dominio: ''
    })

    const customers = customerInformation.map(item => {
        const av = { customer: item.name, id: item.id, dominio: item.dominio }
        return av
    })

    const onCustomerChange = (e) => {
        var idOfClient = parseInt(e.value.id)
        setIdClienteConsulta(idOfClient)
        setSelectedCustomer(e.value)
        setValidateChange(true)
    }

    const [nameTeam, setNameTeam] = useState('') //Nombre equipo de trabajo

    const onNameTeamChange = (e) => {
        setNameTeam(e.target.value)
        setValidateChange(true)

    }

    var nameTeamRepeatError = false
    var messageNameTeamError = ''

    nameTeamWorkProp.forEach(element => {
        if (nameTeam !== '' && nameTeam !== null) {
            if (nameTeam.toLowerCase() === element.nombreequipotrabajo.toLowerCase()) {
                nameTeamRepeatError = true
                messageNameTeamError = 'El nombre ya se encuentra añadido en la tabla. '
            }
        }
    })

    const [displayOfficial, setDisplayOfficial] = useState(false)

    const [displayCoordinator, setDisplayCoordinator] = useState(false)

    const [disabledCoordinator, setDisabledCoordinator] = useState('') //Input deshabilitado de Coordinador

    const [listOfficial, setListOfficial] = useState([])  //Lista de funcionarios

    const resetDataII = () => {

        setSelectedCustomer({
            customer: '',
            id: '',
            dominio: ''
        })

        setNameTeam('')
        setDate1(null)
        setDate2(null)
        setValidateChange(false)
        setNewArrayGroupOfficial([])
        setIdCoordinator([])
        setDisabledCoordinator('')
        setListOfficial([])
        setNameContactCustomer('')
        setNewConditionDisabled(true)
    }
    const [newConditionDisabled, setNewConditionDisabled] = useState(true)
    const ModalRegresar = ({ displayBasicProp }) => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {

            if (displayBasicProp === true) {
                setDisplayOfficial(false)
                setDisplayCoordinator(false)
            }
            else {
                dialogFuncMap[`${name}`](false)
                setDisplayConfirmation(false)
                setDisplayBasic(displayBasicProp)
                resetDataII()
            }

        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => ('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const [newArrayGroupOfficial, setNewArrayGroupOfficial] = useState([])
    const [newArrayGroupCoordinator, setNewArrayGroupCoordinator] = useState([])

    const [idCoordinator, setIdCoordinator] = useState([])

    //Creando la modal del funcionario
    var conditionDisable = true
    const ModalOfficial = ({ newArrayGroupOfficialProp, setNewArrayGroupOfficialProp, idCoordinatorProp, userServicesProp }) => {

        const [validateChangeOfficial, setValidateChangeOfficial] = useState(false)

        const dialogFuncMap = {
            'displayOfficial': setDisplayOfficial
        }

        const onClick = (name) => {
            dialogFuncMap[`${name}`](true)
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false)
        }
        //Obtener datos del funcionario
        const [selectedOfficial, setSelectedOfficial] = useState({
            name: '',
            id: ''
        })

        var getOfficial = userServicesProp.map(item => {
            const getObject = { name: item.nombres + ' ' + item.apellidos, id: item.idUsuario }
            return getObject
        })

        if (getOfficial !== undefined) {
            getOfficial.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
        }

        const onOfficialChange = (e) => {
            setSelectedOfficial(e.value)
            setValidateChangeOfficial(true)
        }

        //Función para validar que me seleccione un solo funcionario

        function idRepeat(e) {
            var disabled = false
            newArrayGroupOfficialProp.forEach(element => {
                if (element.id === e.id) {
                    disabled = true
                }
            })
            idCoordinatorProp.forEach(x => {

                if (x.id === e.id) {
                    disabled = true
                }
            })
            return disabled
        }

        const [dateStartOfficial, setDateStartOfficial] = useState(null) //Estado de fechas y agrengando les valor con la función onChange

        const onDateStartChange = (e) => {
            setDateStartOfficial(e.value)
            setValidateChangeOfficial(true)
        }

        const [dateEndOfficial, setDateEndOfficial] = useState(null)

        const onDateEndChange = (e) => {
            setDateEndOfficial(e.value)
            setValidateChangeOfficial(true)

        }

        //Validaciones de fechas

        var dateStartErrorOfficial = false
        var messageDateStartOfficial = ''

        function rangeDateOfficial() {
            if (dateStartOfficial !== null && dateEndOfficial !== null) {
                if (dateStartOfficial >= date1 && dateStartOfficial <= date2 &&
                    dateEndOfficial >= date1 && dateEndOfficial <= date2) {
                    dateStartErrorOfficial = false
                }

                else {
                    dateStartErrorOfficial = true
                    messageDateStartOfficial = 'Las fechas de vigencia no se encuentran en el rango de fechas del equipo de trabajo.'
                }
            }
        }

        function rangeDateStartOfficial() {

            if (dateStartOfficial !== null && dateEndOfficial !== null) {
                if (dateStartOfficial >= date1 && dateEndOfficial >= date1) {
                    dateStartErrorOfficial = false
                    messageDateStartOfficial = ''
                }

                else {
                    dateStartErrorOfficial = true
                    messageDateStartOfficial = 'Las fechas de vigencia del funcionario deben ser mayor a la fecha de inicio de vigencia del equipo. '
                }
            }
            if (dateStartOfficial !== null && dateEndOfficial === null) {
                if (date1 !== null && date2 !== null) {

                    dateStartErrorOfficial = true
                    messageDateStartOfficial = 'La fecha fin no puede quedar vacia débido a que el equipo tiene una fecha fin registrada. '
                }
            }

            if (dateStartOfficial !== null) {
                if (date1 !== null && date2 === null) {
                    if (dateStartOfficial >= date1) {
                        dateStartErrorOfficial = false
                        messageDateStartOfficial = ''
                    }
                    else {
                        dateStartErrorOfficial = true
                        messageDateStartOfficial = 'La fecha de inicio del funcionario debe ser mayor a la fecha de inicio del equipo de trabajo. '
                    }
                }
            }
        }

        if (date1 !== null) {
            rangeDateStartOfficial()
        }

        if (date1 !== null && date2 !== null) {
            rangeDateOfficial()
        }

        if (dateStartOfficial !== null && dateEndOfficial !== null) {
            if (dateStartOfficial > dateEndOfficial) {
                dateStartErrorOfficial = true
                messageDateStartOfficial = "La fecha de inicio debe ser menor a la fecha fin. "
            }
        }

        //Validar que no se crucen las fechas del usuario en diferentes equipos de trabajo

        let datedCrusses = false
        const filter = userServicesProp?.filter(obj => {
            return parseInt(obj.idUsuario) === parseInt(selectedOfficial?.id)
        })

        function isDateInRange(date, rangeStart, rangeEnd) {
            const targetDate = new Date(date);
            const startDate = new Date(rangeStart);
            const endDate = rangeEnd ? new Date(rangeEnd) : null;

            if (endDate) {
                return targetDate >= startDate && targetDate <= endDate;
            } else {
                return targetDate >= startDate;
            }
        }

        function rangesOverlap(start1, end1, start2, end2) {
            const range1Start = new Date(start1);
            const range1End = end1 ? new Date(end1) : null;
            const range2Start = new Date(start2);
            const range2End = end2 ? new Date(end2) : null;

            if (range1End && range2End) {
                return range1Start <= range2End && range1End >= range2Start;
            } else if (range1End) {
                return range1Start <= range2Start && range1End >= range2Start;
            } else if (range2End) {
                return range1Start <= range2End && range2Start >= range1Start;
            } else {
                return range1Start <= range2Start;
            }
        }

        function validateRange(startDate, endDate, ranges) {
            for (const range of ranges) {
                const { fechainicioasignacion, fechafinasignacion } = range;
                if (
                    isDateInRange(startDate, fechainicioasignacion, fechafinasignacion) ||
                    (endDate && isDateInRange(endDate, fechainicioasignacion, fechafinasignacion)) ||
                    (endDate && rangesOverlap(startDate, endDate, fechainicioasignacion, fechafinasignacion))
                ) {
                    return false;
                }
            }
            return true;
        }

        // Validar que no se crucen fechas con otros equipos en los que esté asociado el usuario
        if (dateStartOfficial !== null) {
            filter?.forEach(i => {
                let ranges = i.equipoTrabajoLider?.concat(i.equipoTrabajoUsuario);

                const isValid = validateRange(
                    CambiarFormatoFecha(dateStartOfficial),
                    dateEndOfficial ? CambiarFormatoFecha(dateEndOfficial) : null,
                    ranges
                );

                if (!isValid) {
                    datedCrusses = true;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Fechas Cruzadas',
                        detail: `El usuario seleccionado se cruza con fechas ya creadas para un equipo de trabajo`,
                        life: 4000
                    });
                }
            });
        }

        //Validaciones para darle permiso al boton guardar de aparecer
        var createPermissionSave = false

        if (selectedOfficial.name !== '' && dateStartOfficial !== null &&
            dateStartErrorOfficial === false && datedCrusses === false) {

            createPermissionSave = true
        }

        //Funcion para cerrar la modal y enviar datos en un arreglo

        const closeWindow = () => {

            setDisplayOfficial(false)

            var storeOfficialData = selectedOfficial.name
            setListOfficial([...listOfficial, storeOfficialData])
            setValidateChange(true)

            var newArrayOfficialData = //Enviar datos por detrás
            {
                user: selectedOfficial.name,
                id: selectedOfficial.id,
                dateStart: dateStartOfficial,
                dateEnd: dateEndOfficial
            }
            setNewArrayGroupOfficialProp([...newArrayGroupOfficialProp, newArrayOfficialData])
        }

        const renderFooter = (name) => {
            return (
                <div>
                    {
                        validateChangeOfficial ?
                            <ModalRegresar displayBasicProp={true} />
                            :
                            <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    }

                    {
                        createPermissionSave ?
                            <Button label="Guardar" icon="pi pi-save" onClick={() => closeWindow()} />
                            :
                            ''
                    }
                </div>
            )
        }

        if (date1 !== null && selectedCustomer.customer !== '' && nameTeam!=='' && nameTeamError === false) {
            conditionDisable = false
            setNewConditionDisabled(false)
        }

        return (
            <>
                <Button icon="pi pi-plus" disabled={conditionDisable} id="boton-official" onClick={() => onClick('displayOfficial')} className="p-button-success" />
                <Dialog className="modal" header="Lista de funcionarios" visible={displayOfficial} modal style={{}}
                    footer={renderFooter('displayOfficial')} onHide={() => onHide('displayOfficial')}>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className='p-field p-col-6 p-md-6'>
                            <label className="labels">Seleccione un funcionario<span className="require">*</span></label>
                            <Dropdown filter emptyMessage="No hay resultados" value={selectedOfficial} options={getOfficial} optionLabel="name" optionDisabled={idRepeat} onChange={onOfficialChange} placeholder="Selecione un funcionario" />
                        </div>

                        <div className="p-field p-col-6 p-md-6">
                            <label className="labels">Fecha inicio vigencia del funcionario<span className="require">*</span></label>
                            <Calendar className={dateStartErrorOfficial ? "p-invalid" : ''} value={dateStartOfficial} onChange={(e) => onDateStartChange(e)} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha inicio de vigencia" />
                            <p className="mensajeError">{dateStartErrorOfficial ? messageDateStartOfficial : ""}</p>
                        </div>

                        <div className="p-field p-col-6 p-md-6">
                            <label className="labels">Fecha fin vigencia del funcionario</label>
                            <Calendar value={dateEndOfficial} onChange={(e) => onDateEndChange(e)} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha fin de vigencia" />
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }

    //Creando la modal de coordinador
    const ModalCoordinators = ({ userServicesProp, servicesUserClientsProp, newArrayGroupOfficialProp, setNewArrayGroupCoordinatorProp }) => {

        const [validateChangeCoordinator, setValidateChangeCoordinator] = useState(false)

        const dialogFuncMap = {
            'displayCoordinator': setDisplayCoordinator
        }

        const onClick = (name) => {
            dialogFuncMap[`${name}`](true)
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false)
        }

        //Obtener datos del coordinador

        const [selectedCoordinator, setSelectedCoordinator] = useState({
            name: '',
            id: '',
        })

        if (servicesUserClientsProp !== undefined) {
            var newArrayValid = []
            servicesUserClientsProp.forEach(i => {
                var result = userServicesProp.filter(function (obj) {
                    return obj.idUsuario === i.id
                })
                result.forEach(i => {
                    newArrayValid.push(i)
                });
            })

        }

        var getCoordinator
        if (newArrayValid !== undefined) {
            getCoordinator = newArrayValid.map(item => {
                const getObject = { name: item.nombres + ' ' + item.apellidos, id: item.idUsuario }
                return getObject
            })
        }

        getCoordinator?.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

        const onCoordinatorChange = (e) => {
            setSelectedCoordinator(e.value)
            setValidateChangeCoordinator(true)
        }

        //Estado de fechas y agrengando les valor con la función onChange

        const [dateStartCoordinator, setDateStartCoordinator] = useState(null)

        const onDateStartChange = (e) => {
            setDateStartCoordinator(e.value)
            setValidateChangeCoordinator(true)
        }

        const [dateEndCoordinator, setDateEndCoordinator] = useState(null)

        const onDateEndChange = (e) => {
            setDateEndCoordinator(e.value)
            setValidateChangeCoordinator(true)
        }

        //Validar que el coordinador no esté en funcionario

        function idRepeat(e) {
            var disabled = false

            newArrayGroupOfficialProp.forEach(element => {
                if (element.id === e.idUser) {
                    disabled = true
                }
            })
            return disabled
        }

        //Validaciones de fechas

        var dateStartError = false
        var messageDateStart = ''

        if (dateStartCoordinator !== null && dateEndCoordinator !== null) {
            if (dateStartCoordinator > dateEndCoordinator) {
                dateStartError = true
                messageDateStart = "La fecha de inicio debe ser menor a la fecha fin. "
            }
        }

        function rangeDateStartCoordinator() {
            if (dateStartCoordinator !== null && dateEndCoordinator !== null) {
                if (dateStartCoordinator >= date1 && dateEndCoordinator >= date1) {
                    dateStartError = false
                    messageDateStart = ''
                }

                else {
                    dateStartError = true
                    messageDateStart = 'Las fechas de vigencia del coordinador deben ser mayor a la fecha de inicio de vigencia del equipo. '
                }
            }
            if (dateStartCoordinator !== null && dateEndCoordinator === null) {
                if (date1 !== null && date2 !== null) {
                    dateStartError = true
                    messageDateStart = 'La fecha fin no puede quedar vacia débido a que el equipo tiene una fecha fin registrada '
                }
            }


            if (dateStartCoordinator !== null) {
                if (date1 !== null && date2 === null) {
                    if (dateStartCoordinator >= date1) {
                        dateStartError = false
                        messageDateStart = ''
                    }
                    else {
                        dateStartError = true
                        messageDateStart = 'La fecha de inicio del funcionario debe ser mayor a la fecha de inicio del equipo de trabajo'
                    }
                }
            }

        }

        function rangeDateCoordinator() {

            if (dateStartCoordinator !== null && dateEndCoordinator !== null) {
                if (dateStartCoordinator >= date1 && dateStartCoordinator <= date2 &&
                    dateEndCoordinator >= date1 && dateEndCoordinator <= date2) {
                    dateStartError = false
                    messageDateStart = ''
                }
                else {
                    dateStartError = true
                    messageDateStart = 'La fecha de inicio y/o fecha fin del coordinador no se encuentra en el rango de fechas del equipo de trabajo. '
                }
            }
        }


        if (date1 !== null) {
            rangeDateStartCoordinator()
        }

        if (date1 !== null && date2 !== null) {
            rangeDateCoordinator()
        }

        if (dateStartCoordinator !== null && dateEndCoordinator !== null) {
            if (dateStartCoordinator > dateEndCoordinator) {
                dateStartError = true
                messageDateStart = "La fecha de inicio debe ser menor a la fecha fin. "
            }
        }

        //Validar que no se crucen las fechas del usuario en diferentes equipos de trabajo

        let datedCrusses = false
        const filter = newArrayValid?.filter(obj => {
            return parseInt(obj.idUsuario) === parseInt(selectedCoordinator?.id)
        })

        function isDateInRange(date, rangeStart, rangeEnd) {
            const targetDate = new Date(date);
            const startDate = new Date(rangeStart);
            const endDate = rangeEnd ? new Date(rangeEnd) : null;

            if (endDate) {
                return targetDate >= startDate && targetDate <= endDate;
            } else {
                return targetDate >= startDate;
            }
        }

        function rangesOverlap(start1, end1, start2, end2) {
            const range1Start = new Date(start1);
            const range1End = end1 ? new Date(end1) : null;
            const range2Start = new Date(start2);
            const range2End = end2 ? new Date(end2) : null;

            if (range1End && range2End) {
                return range1Start <= range2End && range1End >= range2Start;
            } else if (range1End) {
                return range1Start <= range2Start && range1End >= range2Start;
            } else if (range2End) {
                return range1Start <= range2End && range2Start >= range1Start;
            } else {
                return range1Start <= range2Start;
            }
        }

        function validateRange(startDate, endDate, ranges) {
            for (const range of ranges) {
                const { fechainicioasignacion, fechafinasignacion } = range;
                if (
                    isDateInRange(startDate, fechainicioasignacion, fechafinasignacion) ||
                    (endDate && isDateInRange(endDate, fechainicioasignacion, fechafinasignacion)) ||
                    (endDate && rangesOverlap(startDate, endDate, fechainicioasignacion, fechafinasignacion))
                ) {
                    return false;
                }
            }
            return true;
        }

        // Validar que no se crucen fechas con otros equipos en los que esté asociado el usuario
        if (dateStartCoordinator !== null) {
            filter?.forEach(i => {
                let ranges = i.equipoTrabajoLider?.concat(i.equipoTrabajoUsuario);

                const isValid = validateRange(
                    CambiarFormatoFecha(dateStartCoordinator),
                    dateEndCoordinator ? CambiarFormatoFecha(dateEndCoordinator) : null,
                    ranges
                );

                if (!isValid) {
                    datedCrusses = true;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Fechas Cruzadas',
                        detail: `El usuario seleccionado se cruza con fechas ya creadas para un equipo de trabajo`,
                        life: 4000
                    });
                }
            });
        }

        //Validaciones para darle permiso al boton guardar de aparecer
        var createPermissionSave = false

        if (selectedCoordinator.name !== '' && dateStartCoordinator !== null &&
            dateStartError === false && datedCrusses === false) {

            createPermissionSave = true
        }

        const closeWindowCoordinator = () => {

            setDisplayCoordinator(false)

            setDisabledCoordinator(selectedCoordinator.name)
            setValidateChange(true)
            //Enviar datos por detrás
            var filterCoordinator = []
            const result = userServicesProp.filter(item => {

                return item.idUsuario === selectedCoordinator.id
            })
            result.forEach(e => {
                filterCoordinator.push(e)
            })

            var newArrayCoordinatorData = filterCoordinator.map(item => {
                return {
                    coordinator: item.nombres + ' ' + item.apellidos,
                    id: item.idColaborador,
                    dateStart: dateStartCoordinator,
                    dateEnd: dateEndCoordinator
                }

            })
            setNewArrayGroupCoordinatorProp(newArrayCoordinatorData)
            setIdCoordinator([{
                coordinator: selectedCoordinator.name,
                id: selectedCoordinator.id,
            }])
        }

        const renderFooter = (name) => {
            return (
                <div>
                    {
                        validateChangeCoordinator ?
                            <ModalRegresar displayBasicProp={true} />
                            :
                            <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    }
                    {
                        createPermissionSave ?
                            <Button label="Guardar" icon="pi pi-save" onClick={() => closeWindowCoordinator()} />
                            :
                            ''
                    }

                </div>
            )
        }

        return (
            <>

                <Button disabled={newConditionDisabled} icon="pi pi-plus" id="boton-coordinator" onClick={() => onClick('displayCoordinator')} className="p-button-success" />
                <Dialog className="modal" header="Lista de coordinadores" visible={displayCoordinator} modal style={{}}
                    footer={renderFooter('displayCoordinator')} onHide={() => onHide('displayCoordinator')}>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className='p-field p-col-6 p-md-6'>
                            <label className="labels">Seleccione un coordinador<span className="require">*</span></label>
                            <Dropdown filter emptyMessage="No hay resultados" value={selectedCoordinator} options={getCoordinator} optionLabel="name"
                                onChange={onCoordinatorChange} placeholder="Selecione un coordinador" optionDisabled={idRepeat} />
                        </div>

                        <div className="p-field p-col-6 p-md-6">
                            <label className="labels">Fecha inicio de vigencia del coordinador<span className="require">*</span></label>
                            <Calendar className={dateStartError ? "p-invalid" : ''} value={dateStartCoordinator} onChange={(e) => onDateStartChange(e)} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha inicio de vigencia" />
                            <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                        </div>

                        <div className="p-field p-col-6 p-md-6">
                            <label className="labels">Fecha fin de vigencia del coordinador</label>
                            <Calendar value={dateEndCoordinator} onChange={(e) => onDateEndChange(e)} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha fin de vigencia" />
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }

    //Calendar

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validaciones del calendar

    var dateStartError = false
    var messageDateStart = ''

    if (date1 !== null && date2 !== null) {
        if (date1 > date2) {
            dateStartError = true
            messageDateStart = "La fecha de inicio del equipo de trabajo debe ser menor a la fecha fin. "
        }
    }
    var nameTeamError = false
    var messageNameTeam = ''

    if (nameTeam !== '' && nameTeam !== null) {
        if (nameTeam.length <= 3) {
            nameTeamError = true
            messageNameTeam = 'Debe ingresar más de 3 carácteres. '
        }

    }
    //Nombre del contacto cliente
    const [nameContactCustomer, setNameContactCustomer] = useState('');
    //Validaciones para darle permiso al botón de guardar para que aparezca
    var createPermission = false

    if (selectedCustomer.customer !== '' && nameTeam !== '' &&
        date1 !== null && listOfficial.length > 0 && disabledCoordinator !== '' && dateStartError === false && nameTeamError === false && nameTeamRepeatError === false
        && nameContactCustomer !== '') {
        createPermission = true
    }

    //Almacenamos los valores para mandarlos por props

    const usuarioSesion = cookies.get('usuario')
    const nameTeamWork = nameTeam
    const dateStartTeam = date1
    const dateEndTeam = date2

    const dataCoordinator = newArrayGroupCoordinator.map(item => {
        return {
            colaborador: {
                id: item.id,
            },
            estado: 1,
            fechacreacion: new Date(),
            fechafinasignacion: item.dateEnd,
            fechainicioasignacion: item.dateStart,
        }
    })

    const dataUsers = newArrayGroupOfficial.map(item => {
        return {
            estado: 1,
            fechacreacion: new Date(),
            fechafinasignacion: item.dateEnd,
            fechainicioasignacion: item.dateStart,
            usuario: {
                id: item.id
            }
        }
    })

    function resetData() {
        setNewArrayGroupCoordinator([])
        setNewArrayGroupOfficial([])
    }

    const idclient = selectedCustomer.id

    //Estado que recibe todos los datos del contacto cliente

    const [dataContactCustomer, setDataContactCustomer] = useState([]);

    var newArrayDataContactClient = []
    if (dataContactCustomer !== null && dataContactCustomer.length !== 0) {
        const newDataContactCustomer = {
            estado: 1,
            fechacreacion: new Date(),
            fechafinasignacion: dataContactCustomer.dateEnd,
            fechainicioasignacion: dataContactCustomer.dateStart,
            personainteresada: {
                id: dataContactCustomer.id
            }
        }
        newArrayDataContactClient.push(newDataContactCustomer)
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar displayBasicProp={false} />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    createPermission ?
                        <ConfirmBtn
                            titulo='Equipo de trabajo'
                            mensaje='¿Desea registrar su equipo de trabajo?'
                            accion='CrearEquipoTrabajo'
                            label='Guardar'
                            icono='pi pi-save'
                            updateDateProp={true}
                            usuarioSesionProp={usuarioSesion}
                            nameTeamWorkProp={nameTeamWork}
                            dateStartWorkTeamProp={dateStartTeam}
                            dateEndWorkTeamProp={dateEndTeam}
                            dataCoordinator={dataCoordinator}
                            dataOfficial={dataUsers}
                            idClientTeamWork={idclient}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicOfficialProp={setDisplayBasic}
                            clientProp={setSelectedCustomer}
                            nameTeamProp={setNameTeam}
                            dateStartTeamProp={setDate1}
                            dateEndTeamProp={setDate2}
                            coordinatorProp={setDisabledCoordinator}
                            officialProp={setListOfficial}
                            setValidateChange={setValidateChange}
                            arrayGroupOfficial={setNewArrayGroupOfficial}
                            idCoordinator={setIdCoordinator}
                            dataContactClienProp={newArrayDataContactClient}
                        />
                        :
                        ''
                }
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label="Crear equipo de trabajo" icon="pi pi-plus" className="p-button-success" onClick={() => {
                onClick('displayBasic')
                resetData()
                resetDataII()
            }} />

            <Dialog className="modal" header='Crear equipo de trabajo' visible={displayBasic} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels" htmlFor="tipoDoc">Cliente<span className="require">*</span></label>
                        <Dropdown filter value={selectedCustomer} emptyMessage="No hay resultados" options={customers} onChange={onCustomerChange} optionLabel='customer' placeholder="Seleccione el Cliente"
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels">Nombre de equipo de trabajo<span className="require">*</span></label>
                        <InputText className={nameTeamError || nameTeamRepeatError ? "p-invalid" : ""} value={nameTeam} onChange={onNameTeamChange} keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/} placeholder="Escriba el nombre de su equipo de trabajo" />
                        <p className="mensajeError">{nameTeamError ? messageNameTeam : ""}{nameTeamRepeatError ? messageNameTeamError : ''}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels">Fecha inicio vigencia del equipo<span className="require">*</span></label>
                        <Calendar className={dateStartError ? "p-invalid" : ""} value={date1} onChange={(e) => onDateStartChange(e)} dateFormat="dd-mm-yy" locale='es'
                            placeholder="Seleccione Fecha de inicio"></Calendar>
                        <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels">Fecha fin vigencia del equipo</label>
                        <Calendar value={date2} onChange={(e) => onDateEndChange(e)}
                            dateFormat="dd-mm-yy" locale='es' placeholder="Seleccione Fecha Fin"></Calendar>
                    </div>

                    <div className="p-field p-col-12 p-md-5">
                        <label className="labels">Nombre Coordinador<span className="require">*</span></label>
                        <InputText value={disabledCoordinator} placeholder="Agregar coordinador en el botón" disabled />
                    </div>

                    <div className="p-field p-col-12 p-md-1">
                        <ModalCoordinators
                            userServicesProp={userServices}
                            servicesUserClientsProp={servicesUserClients}
                            newArrayGroupOfficialProp={newArrayGroupOfficial}
                            setNewArrayGroupCoordinatorProp={setNewArrayGroupCoordinator}
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-5">
                        <label className="labels">Funcionarios<span className="require">*</span></label>
                        <ListBox value={listOfficial} options={listOfficial} style={{ padding: '10px' }} listStyle={{ maxHeight: '60px' }} placeholder="Agregar funcionarios en el botón" />
                    </div>

                    <div className="p-field p-col-12 p-md-1">
                        <ModalOfficial
                            newArrayGroupOfficialProp={newArrayGroupOfficial}
                            setNewArrayGroupOfficialProp={setNewArrayGroupOfficial}
                            idCoordinatorProp={idCoordinator}
                            userServicesProp={userServices}
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-5">
                        <label className="labels">Nombre Contacto Cliente<span className="require">*</span></label>
                        <InputText value={nameContactCustomer} placeholder="Agregar contacto cliente en el botón" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <ModalContactClient
                            disabledProp={newConditionDisabled}
                            selectedIdClienteProp={selectedCustomer.id}
                            dateStartTeamProp={date1}
                            dateEndTeamProp={date2}
                            setNameContactCustomerProp={setNameContactCustomer}
                            setDataContactCustomerProp={setDataContactCustomer}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default CrearAsociacionFuncionario;